.faq-tabs-area__nav-link {
    background-color: #f9f9f9;
    padding: calc(20rem/16) calc(30rem/16);
    color: var(--color-headline-default);
    text-transform: uppercase;
    font-weight: var(--font-weight-rounded-medium);
    position: relative;
    transition: color 0.2s ease;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.faq-tabs-area__nav-link:before {
    height:100%;
    content:'';
    position: absolute;
    width: calc(2rem/16);
    background-color: var(--color-primary);
    left:0;
    top:0;
    opacity:0;
    z-index: 2;
    transition: opacity 0.2s ease;
}
.faq-tabs-area__nav-link.active:before {
    opacity:1;
}
.faq-tabs-area__nav-link.active {
    background-color: #f9f9f9;
    color: var(--color-primary);
}
.faq-tabs-area__nav-item {
    margin-bottom: calc(10rem/16);
    padding: 0;
    width: 100%;
    @media screen and (max-width: 1199px) {
        padding:0;
    }
}
.tab-content > .faq-tabs-area__tab-content__body {
    @media screen and (max-width: 767px) {
        display: block;
    }
}
.faq-tabs-area__tab-content__body-inner {
    @media screen and (max-width: 767px) {
        display: none;
        margin-bottom: calc(20rem/16);
    }
}
.faq-tabs-area__tab-content__body.active .faq-tabs-area__tab-content__body-inner {
    @media screen and (max-width: 767px) {
        display: block;
    }
}
.faq-tabs-area__tab-content__body:before {
    @media screen and (max-width: 767px) {
        content: attr(data-title);
        background-color: #f9f9f9;
        padding: calc(20rem/16) calc(20rem/16);
        color: var(--color-headline-default);
        text-transform: uppercase;
        font-weight: var(--font-weight-rounded-medium);
        position: relative;
        transition: color 0.2s ease;
        display: block;
        margin-bottom: calc(10rem/16);
        margin-left: calc(-20rem/16);
        margin-right: calc(-20rem/16);
    }
}
.faq-tabs-area__tab-content__body.active:before {
    @media screen and (max-width: 767px) {
        border-left: calc(3rem / 16) solid var(--color-primary);
        color: var(--color-primary);
        padding: calc(20rem/16) calc(17rem/16);
    }
}
.faq-tabs-area__tab-content__title {
    text-transform:uppercase;
    font-size: calc(16rem/16);
    font-family: var(--font-rounded-bold);
    font-weight: var(--font-weight-rounded-medium);
    margin-bottom: calc(30rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        margin-bottom: calc(15rem/16);
        margin-top: calc(20rem/16);
    }
}
.faq-tabs-area__nav {
    @media screen and (max-width: 767px) {
       display: none;
    }
}
.faq-tabs-area__tab-content .wysiwyg {
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}