.bullet-pagination {
    display: flex;
    align-items: center;
}
.bullet-pagination__page-link {
    background: var(--color-grey-light);
    border: none;
    border-radius: 50%;
    width: .625rem;
    height: .625rem;
    padding: 0;
    cursor: pointer;
    display: block;
    margin-left: calc(10rem/16);
    margin-right: calc(10rem/16);
}
.bullet-pagination__page-item.active .bullet-pagination__page-link {
    background: var(--color-primary);
}
.bullet-pagination__arrow {
    color: var(--color-grey-light);
}
.bullet-pagination__arrow.arrow-prev {
    margin-right: calc(20rem/16);
}
.bullet-pagination__arrow.arrow-next {
    margin-left: calc(20rem/16);
}
.bullet-pagination__arrow-link {
    display: flex;
}