/*___________AB-Test customizations for WF_720896_________*/

/* green form inline-validation */
.ab-test-checkout .form-group.has-success .custom-input, .ab-test-checkout .form-group.has-success .select {
    border: 2px solid var(--color-primary)!important;
    font-style: italic;
    color: var(--color-grey);
    font-style: normal;
    color: var(--color-dark);
    font-size: calc(16rem/16);
}

/* input has error - make border bigger */
.ab-test-checkout .form-group.has-error .custom-input {
    border: 2px solid var(--color-secondary)!important;
}

/* show required inputs only on submit */
.ab-test-checkout.disable-has-error .has-error .custom-input, .ab-test-checkout.disable-has-error .has-error select {
    border: 1px solid var(--bg-lightgrey) !important;
}
.ab-test-checkout.disable-has-error .parsley-errors-list {
    display: none;
}

/* darken label color */
.ab-test-checkout .form-group .control-label {
    color: var(--color-dark);
    font-size: calc(16rem/16);
}

/* change font-size and font-style of input */
.ab-test-checkout .form-group .custom-input, .ab-test-checkout .form-group .select {
    font-style: italic;
    color: var(--color-grey);
    font-size: calc(14rem/16);
}

/* add blue checkmark to ticket-button when ticket is customized */
.ticket__footer button .icon-check {
    display: none;
}
.ab-test-checkout .ticket__footer button .icon-check {
    display: inline-block;
}