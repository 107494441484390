
.teaser__image {
    position: relative;

}
.teaser__image:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
    line-height: 1.1;
}

.teaser__image-text {
    position:absolute;
    bottom:0;
    color:#fff;
    z-index: 1;
    padding:calc(20rem/16);
    font-size: calc(14rem/16);
}

.teaser__image-date {
    position:absolute;
    top:0;
    color:#fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.59);
    z-index: 1;
    padding:calc(20rem/16);
    font-size: calc(14rem/16);
}

.teaser__image-text__headline {
    font-family:var(--font-decorative);
    letter-spacing:var(--font-letterspacing-decorative);
    font-size: calc(40rem/16);
    line-height:1;
    @media screen and (max-width: 1600px) and (min-width: 768px) {
        font-size: calc(30rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(24rem/16);
    }
}

.teaser__icon {
    position:absolute;
    right:calc(calc(15rem/16) * -1);
    top:calc(calc(15rem/16) * -1);
    width:calc(70rem/16);
    height:calc(70rem/16);
    background:var(--color-pastel-green);
    z-index:2;
    border-radius:9999px;
    box-shadow:0 2px 20px 0 rgba(74, 83, 92, 0.2);
}
@media screen and (min-width: 768px) {
    .teaser-animation {
        overflow: hidden;
    }
    .teaser-animation .teaser__image:before, .teaser-animation-portal:before {
        transition: opacity 0.5s ease;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0, 0, 0, 0.6) 100%);
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: calc(100% + 1px);
        opacity:1;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;

    }
    .teaser-animation .teaser__image picture {
        transform: scale(1);
        transition: all 0.5s ease;
    }
    .teaser-animation .teaser__image:hover picture {
        transform: scale(1.05);
        cursor: pointer;
    }
    .teaser-animation:hover .teaser__image:before, .teaser-animation-portal:hover:before {
        transition: all 0.5s ease;
        opacity:0.3;

    }
    .teaser-animation .teaser__image-headline {
        transform: translateY(0px);
        transition: all 0.5s ease;
    }
    .teaser-animation:hover .teaser__image-headline {
        transform: translateY(-20px);
        transition: all 0.2s ease-out;

    }

}
.teaser__additional-info {
    position:absolute;
    top:10px;
    padding: 5px 30px;
    @media screen and (max-width: 767px) {
        padding: 5px;
    }
}
.teaser__image-headline {
    position: absolute;
    bottom: 0;
    font-family: var(--font-decorative);
    letter-spacing:var(--font-letterspacing-decorative);
    font-size: calc(35rem / 16);
    line-height: calc(35/40);
    text-align: center;
    color: #fff;
    padding: calc(30rem / 16) calc(13rem / 16);
    z-index: 2;
    width: 100%;
    @media screen and (max-width: 767px) {
        font-size: calc(20rem / 16);
        line-height: calc(36 / 30);
        padding-bottom: calc(15rem / 16);
        margin-bottom: 0;
    }
}
.teaser__location {
    font-family: var(--font-rounded-bold);
    font-weight: var(--font-weight-rounded-bold);
    line-height: calc(19 / 16);
}
.teaser__title {
    font-size: calc(20rem / 16);
    line-height: calc(24/20);
    font-family: var(--font-rounded-bold);
    font-weight: var(--font-weight-rounded-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem / 16);
        line-height: calc(19/16);
    }
}
.teaser__body {
    padding-top: calc(13rem / 16);
}

.teaser__row.row--same-height .slick-slide, .teaser__row--same-height .slick-slide {
    flex-grow: 1;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    height: auto;
    margin-bottom: calc(15rem/16);
}

.teaser__row--same-height .slick-slide > div {
    display: flex;
    flex: 1 0 auto;
    height: auto;
}

.teaser__row--same-height .offer-teaser {
    display: flex;
    flex-direction: column;
    margin-bottom:0;
    align-items: stretch;
    flex-grow:1;
    height: 100%;
}

.teaser__row--same-height .offer-teaser .teaser__body {
    display: flex;
    flex-direction: column;
    flex: auto;
    height: auto;
}

.teaser__row.row--same-height .offer-teaser {
    height:100%;
}
.teaser__ribbon {
    position:absolute;
    top: calc(10rem/16);
    background-color:var(--color-primary);
    padding: calc(13rem/16) calc(15rem/16) calc(10rem/16);
    color:#fff;
    font-family:var(--font-default-bold);
    font-size: calc(18rem/16);
    line-height:1;
    z-index: 1;
}
.teaser__info-box {
    color: #fff;
    border: 1px solid;
    position: absolute;
    top: calc(10rem/16);
    left: calc(10rem / 16);
    right: calc(10rem / 16);
    z-index: 1;
    padding: .25rem;
    text-align: center;
    font-size: calc(10rem / 16);
    text-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
    font-family: var(--font-default);
    box-shadow: 0 2px 1px 0 rgba(0,0,0,.5), inset 0 2px 1px 0 rgba(0,0,0,.5);

    @media screen and (min-width: 768px) {
        font-size: calc(13rem / 16);
        left: calc(30rem / 16);
        right: calc(30rem / 16);
        padding: .5rem;
        font-family: var(--font-rounded-bold);
        font-weight: var(--font-weight-rounded-medium);
    }
}