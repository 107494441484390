
.facility {
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: auto;
    height: 100%;
    @media screen and (max-width: 1199px) {
        padding-top:calc(20rem/16);
        padding-bottom:calc(20rem/16);
    }
}

.facility__item {
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-light);
    text-align: center;
    font-size: calc(20rem / 16);
    line-height: calc(24 / 20);
}

.facility__item__value {
    font-size: calc(40rem / 16);
    line-height: 1;
    @media screen and (max-width: 1199px) {
        font-size: calc(30rem / 16);
    }
}

.facility__text {
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    font-size: calc(20rem / 16);
    line-height: calc(29 / 20);
    text-align: center;
    margin-bottom: calc(25rem / 16);
    margin-top: calc(5rem / 16);
    text-transform: uppercase;
    @media screen and (max-width: 1199px) {
        font-size: calc(16rem / 16);
        margin-bottom: calc(10rem / 16);
    }
}

.facility__percent-circle {
    position: relative;
}

