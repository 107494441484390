.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}.custom-checkbox:after {
     clear: both;
 }
.custom-checkbox__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    position: relative;
    float: left;
    margin-right: 7px;
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-top: -4px;
    flex:none;
}
.custom-checkbox__box--rounded {
    border-radius: 50%;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(7rem/16);
    color: #fff;
    visibility: hidden;
    font-weight:900;
}
.custom-checkbox__input--bigger {

}
.custom-checkbox__box--bigger {
    width: calc(24rem/16);
    height: calc(24rem/16);
}
.custom-checkbox__box--bigger:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(10rem/16);
    color: #fff;
    visibility: hidden;
    font-weight:900;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    background-color:var(--color-primary);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
    font-weight: var(--font-weight-rounded-light);
    color: var(--color-grey);
}
.custom-checkbox__text a {
    color:var(--color-primary);
}

.custom-checkbox--tabs .custom-checkbox__input:checked ~ .custom-checkbox__box {
    background-color:#fff;
}
.custom-checkbox--tabs .custom-checkbox__input:checked ~ .custom-checkbox__text {
    color:#fff;
}
.custom-checkbox--tabs .custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    color:var(--color-primary);
}