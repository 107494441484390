
.jobfinder-answer.selected .img-teaser:before {
    background-color:rgba(25, 30, 48, 0.49);
    transition: all 0.2s ease;
}

.jobfinder-answer .img-teaser:before {
    background-color:rgba(25, 30, 48, 0.1);
    transition: background-color 0.2s ease;
}
.jobfinder-answer:before {
    content:var(--icon-check);
    position: absolute;
    font-size: 12px;
    background-color: var(--color-primary);
    color: #fff;
    z-index: 3;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
    margin-top: -18px;
    left: 0;
    right: 0;
    border-radius: 50%;
    text-align: center;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity:0;
    transform:scale(0);
    transition:opacity 0.1s ease, transform 0.3s ease-out;

}
.jobfinder-answer.selected:before {
    opacity:1;
    transform:scale(1);

}