.qr-scanner__video {
    border: 1px solid var(--color-text-primary);
    background-color: var(--color-lightgrey);
    width:100%;
    height: calc(330rem/16);
}
.qr-scanner__result {
    font-size: calc(16rem/16);
    text-align:center;
    font-family: var(--font-default-bold);
    position:absolute;
    left:0;
    width:100%;
    height:100%;
    right:0;
    top:0;
    padding: calc(10rem/16);
    z-index: 1;
    opacity:0;
    transition: opacity 0.3s ease;
}
.qr-scanner__result.has-success {
    color: var(--color-text-default);
    opacity:1;
    transition: opacity 0.3s ease;
    background-color: rgba(255,255,255,0.7);
    padding-top: calc(70rem/16);
    display:flex;
    justify-content:center;
    align-items:center;
    height:100%;
}
.qr-scanner__result.has-error {
    color: var(--color-text-default);
    background-color: var(--color-lightgrey);
    opacity:1;
    transition: opacity 0.3s ease;
    padding-top: calc(70rem/16);
    display:flex;
    justify-content:center;
    height:100%;
}
.qr-scanner__result.has-success:before {
    content: var(--icon-check);
    background-color:var(--color-success);
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-family: 'iconfont';
    speak: none;
    font-style: normal;
    color: #fff;
    font-size: calc(12rem/16);
    left:0;
    right:0;
    margin: 0 auto;
    top: 40%;
}
.qr-scanner__result.has-error:before {
    content: var(--icon-close);
    background-color:var(--color-danger);
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-family: 'iconfont';
    speak: none;
    font-style: normal;
    color: #fff;
    font-size: calc(12rem/16);
    left:0;
    right:0;
    margin: 0 auto;
    top: calc(20rem/16);
}
.qr-scanner__video-wrapper {
    position:relative;
    max-width: calc(270rem/16);
    margin-top: calc(10rem/16);
    overflow:hidden;
    height: calc(330rem/16);
}
.qr-scanner__video-wrapper:before {
    content:'';
    position:absolute;
    height:calc(2rem/16);
    display:block;
    width: 100%;
    top: calc(20rem/16);
    left:0;
    z-index: 1;
    background-color: var(--color-primary);
    box-shadow: 0 0 4px var(--color-primary);
    opacity: 0.4;
    animation: scanning 2s infinite;
}
.has-error .qr-scanner__video-wrapper:before {
    animation: none;
}

@keyframes scanning {
    50% {
        transform: translateY(300px);
    }
}