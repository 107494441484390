
.nav-item__dropdown {
    position: absolute;
    display:block;
    left: 0;
    right: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(25, 30, 48, 0.9) 0%, rgba(0, 0, 0, 0.9) 100%);
    opacity: 0;
    visibility: hidden;
    overflow:hidden;
}

.nav-item__dropdown--with-padding {
    padding-top: calc(70rem / 16);
    padding-bottom: calc(80rem / 16);
}

.nav-item__dropdown--search {
    padding-top: calc(90rem / 16);
    padding-bottom: calc(100rem / 16);
    @media screen and (max-width: 767px) {
        padding-top: calc(40rem / 16);
        padding-bottom: calc(30rem / 16);
        position:absolute;
        bottom: calc(64rem/16);
        overflow:hidden !important;
    }
}

.nav-item.is-open .nav-item__dropdown, .nav-item__dropdown.is-open {
    animation: fade-in ease 0.4s forwards;
    visibility: visible;
    z-index:9;

}

.nav-item__dropdown-container {
    width: 100%;
    max-width: calc(990rem/16);
    margin: 0 auto;
}

.nav-item__dropdown--search__input {
    background-color: transparent;
    text-align: center;
    transition: all 0.3s linear;
    font-size: calc(22rem / 16);
    color: #fff;
    display:flex;


}

.nav-item__dropdown--search__input:focus {
    background-color: transparent;
    box-shadow: none;
    border-bottom: calc(1rem/16) solid var(--color-primary);
    color: var(--color-primary);
}

.nav-item__dropdown--search__input::placeholder {
    color: #fff;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}