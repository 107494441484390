.img-text-slider {
    padding-top:calc(70rem/16);
    padding-bottom:calc(70rem/16);
    @media screen and (max-width: 767px) {
        padding-top:calc(20rem/16);
        padding-bottom:calc(30rem/16);
    }
}
.img-text-slider__headlines {
    padding-top:calc(70rem/16);
    @media screen and (max-width: 767px) {
        padding-top:calc(30rem/16);
    }
}
.img-text-slider__headlines h2 {
    font-size:calc(40rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(20rem/16);
    }
}
.img-text-slide__item, .img-text-slider .slick-slide {
    @media screen and (min-width: 1241px) {
        width:1195px;
    }
    @media screen and (max-width: 1240px) and (min-width: 768px) {
        width:850px;
    }
    @media screen and (max-width: 767px) {
        width:100%;
    }

}
.img-text-slide__item {
    padding: 0 calc(35rem/16);
    @media screen and (max-width: 767px) {
        padding:0;
    }
}
.img-text-slider__arrow {
    position: absolute;
    display: block;
    z-index:2;
    cursor:pointer;
    font-size:calc(50rem/16);
    width:50px;
    color:#fff;
    @media screen and (max-width: 767px) {
        position:relative;
        display:block;
        font-size:calc(25rem/16);
        transform:none;
        width:calc(50rem/16);
        text-align:center;
    }
    @media screen and (min-width: 768px) {
        top: 50%;
        transform: translateY(-50%);
    }
}
.img-text-slider__arrow.slick-disabled {
    color:#fff;
}
.img-text-slider__arrow.slick-disabled:hover {
    color:var(--color-primary);
}
.img-text-slider__arrow--dark {
    color:var(--color-headline-default);
}
.img-text-slider__arrow.arrow-next:not(.slick-hidden) {
    right:calc( (100% - 1195px ) / 4 );
    @media screen and (max-width: 1240px)  and (min-width: 768px) {
        right:calc( (100% - 850px ) / 4 );
    }
    @media screen and (max-width: 767px) {
        right:0;
        display:inline-block;

    }
}
.img-text-slider__arrow.arrow-prev:not(.slick-hidden) {
    left:calc( (100% - 1195px ) / 4 );
    @media screen and (max-width: 1240px) and (min-width: 768px) {
        left:calc( (100% - 850px ) / 4 );
    }
    @media screen and (max-width: 767px) {
        left:0;
        display:inline-block;
    }
}
.img-text-slide__item__text-overlay {
    @media screen and (min-width: 768px) {
        opacity:0;
        transform: translateX(-100px);
        transition: all 0.3s ease-out;
    }
    @media screen and (max-width: 767px) {
        padding: calc(22rem/16);
    }

}
.slick-center .img-text-slide__item__text-overlay,
.slick-current .img-text-slide__item__text-overlay {
    @media screen and (min-width: 768px) {
        opacity: 1;
        transform: translateX(0px);
    }
}
.img-text-slider .slick-slide .slide__item__img {
    @media screen and (min-width: 768px) {
        transition: transform 0.5s ease;
        position: relative;
    }
}
.img-text-slider .slide__item__img {
    @media screen and (min-width: 768px) {
        transform: translateX(55.33333%);
    }
}
.img-text-slider .slick-center .slide__item__img,
.img-text-slider .slick-center ~ .slick-slide .slide__item__img,
.img-text-slider .slick-current .slide__item__img,
.img-text-slider .slick-current ~ .slick-slide .slide__item__img {
    @media screen and (min-width: 768px) {
        transform: translateX(0);
    }
}
.img-text-slider .slide__item__img:after {
    @media screen and (min-width: 768px) {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        transition: opacity 0.3s ease;
    }
}
.img-text-slider .slick-center .slide__item__img:after,
.img-text-slider .slick-current .slide__item__img:after {
    opacity:0;
}
.img-text-slider--has-no-bg .slide__item__img:after {
    @media screen and (min-width: 768px) {
        background: rgba(255, 255, 255, 0.6);
    }
}
