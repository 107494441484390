
.nav-pills .nav-link:not(.active) {
    border: 1px solid var(--color-text-primary);
    color:var(--color-text-primary);
}

.nav-pills .nav-link {
    font-family: var(--font-default-bold);
    letter-spacing: 2px;
    font-size: calc(14rem/16);
    padding: 0.6rem 2rem;
}

.nav-pills .nav-item {
    padding: calc(5rem/16);

    @media screen and (max-width: 767px) {
        display:block;
        width:100%;
        text-align:center;
    }
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    border: 1px solid var(--color-primary);
}