.ticket-detail-text__item + .ticket-detail-text__item {
    margin-top: calc(40rem/16);
}
.ticket-detail-text__item__title {
    font-size: calc(16rem/16);
}
.ticket-detail-text__item .wysiwyg {
    line-height: calc(30/15);
    font-size: calc(15rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        line-height: calc(20/12);
    }
}