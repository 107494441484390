

.trophy {
    max-width:calc(125rem/16);
    padding-left:calc(10rem/16);
    @media screen and (max-width: 767px) {
        max-width:calc(50rem/16);
        margin:0 auto;
        padding-left:calc(3rem/16);
    }
    @media screen and (max-width: 1199px) {
        max-width:calc(70rem/16);
        padding-left:calc(5rem/16);
    }
}