.default-teaser .teaser__image-headline {
    text-shadow: 2px 2px 8px rgba(0,0,0,0.3);
    @media screen and (max-width: 767px) {
        font-size: calc(30rem / 16);
        padding-bottom: calc(10rem / 16);
        line-height:1;
    }
}
.default-teaser.row--same-height__item .teaser__body {
    flex:1;
    display:flex;
    flex-direction:column;
    height:auto;
}
.default-teaser.row--same-height__item .teaser__date {
    margin-top:auto !important;
    padding-top:calc(10rem/16);
}
.default-teaser__body {
    position: absolute;
    bottom: 0;
    padding: calc(30rem / 16) calc(13rem / 16);
    z-index: 2;
    width: 100%;
}
.default-teaser__body .teaser__image-headline {
    position: relative;
    padding:0;
}
.default-teaser__description {
    color:#fff;
    text-align:center;
    margin-top: calc(10rem/16);
    text-shadow: 2px 2px 8px rgba(0,0,0,.3);
    min-height: calc(80rem/16);
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.default-teaser__image:after {
    content:'';
    position: absolute;
    height: 40%;
    width: 100%;
    bottom:0;
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 100%);
    left:0;
}