.webcam__overview {
    margin-top:-120px;
    z-index:2;
    position:relative;
    text-shadow: 2px 2px 8px rgba(0,0,0,0.3);
    padding-bottom:5rem;
    @media screen and (max-width: 1199px) and (min-width: 767px) {
        margin-top:-20px;
    }
    @media screen and (max-width: 767px) {
        margin-top:20px;
    }
}