.step-legend__counter {
    height: calc(32rem/16);
    width: calc(32rem/16);
    border: calc(1.2rem/16) solid var(--color-lightgrey);
    color: var(--color-lightgrey);
    display: flex;
    justify-content: center;
    align-items:center;
    font-size: calc(14rem/16);
    margin: 0 auto;
    text-align:center;
    background-color: #fff;
    font-weight: var(--font-weight-rounded-medium);
    letter-spacing: 0;
    border-radius: 50%;
    transition: transform 0.3s ease;
}
.step-legend__item.active .step-legend__counter {
    border-color: var(--color-primary);
    color: var(--color-primary);
}
.step-legend.has-error .step-legend__item.active .step-legend__counter {
    border-color: var(--color-danger);
    color: var(--color-danger);
}
.step-legend__item.is-previous .step-legend__counter {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
    color:#fff;
    font-size: calc(8rem/16);
}
.step-legend.has-error .step-legend__item.is-previous .step-legend__counter  {
    border-color: var(--color-danger);
    background-color: var(--color-danger);
}
.step-legend__item:not(:last-of-type):after {
    content:'';
    height: 1px;
    background-color: var(--color-lightgrey);
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
}
.step-legend__item.is-previous:after {
    background-color: var(--color-primary);
}
.step-legend.has-error .step-legend__item.is-previous:after  {
    background-color: var(--color-danger);
}
.step-legend__text {
    font-size: calc(14rem/16);
    position: absolute;
    transform: translateX(-50%);
    text-align: center;
    left: 50%;
    color: var(--color-default);
    margin-top: calc(20rem/16);
    line-height: 1;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.step-legend__item {
    position: relative;
}
.step-legend__legend {
    align-self:center;
    border-top: 1px solid var(--color-dark-grey);
}
.step-legend {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: calc(40rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }
}
.step-legend__item:not(:last-of-type) {
    display: grid;
    grid-template-columns: calc(32rem/16) calc(100rem/16);
    @media screen and (max-width: 767px) {
        grid-template-columns: calc(32rem/16) calc(50rem/16);
    }
}
.step-legend__item:not(:last-of-type) > div:nth-child(1) { -ms-grid-column: 1; }
.step-legend__item:not(:last-of-type) > div:nth-child(2) { -ms-grid-column: 2; }

.step-legend__item.active .step-legend__text {
    color: var(--color-primary);
}
.step-legend.has-error .step-legend__item.active .step-legend__text  {
    color: var(--color-danger);
}