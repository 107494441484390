.bg-img {
    position:relative;
    background-repeat: no-repeat;
    background-size:cover;
}
.bg-img--has-dark-overlay:before {
    content:'';
    display:block;
    position:absolute;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.8);
    top:0;
}

.bg-img--has-dark-overlay {
    position:relative;
    background-repeat: no-repeat;
    background-size:cover;
}

.bg-img--has-dark-overlay > .container {
    position:relative;
}