.checkout-card {
    padding: calc(20rem/16);
    min-height: calc(116rem/16);
    border: 1px solid #E7E7E7;
    box-shadow: 5px 1px 11px 0 rgba(0,0,0,0.06);
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
        min-height: auto;
        padding: calc(10rem/16);
    }
}
.checkout-card--highlight {
    border: 2px solid var(--color-primary);
}