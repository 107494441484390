.slide {
    padding-top: calc(30rem/16);
    padding-bottom: calc(30rem/16);
}
@media screen and (min-width: 768px) {
    .slide {
        padding-top: calc(50rem/16);
        padding-bottom: calc(50rem/16);
    }
}

/* ---- hero slide ---- */
.hero-slide {
    overflow:hidden;
    position: relative;
}

.hero-slide__scroll-btn{
    position: absolute;
    transform: translateX(-50%);
    bottom: 5%;
    left: 50%;
    color: var(--color-primary) !important;
    font-size: 1.25rem;
    z-index: 2;
    cursor: pointer;
}

.hero-slide__scroll-btn:hover,
.hero-slide__scroll-btn:active,
.hero-slide__scroll-btn:focus,
.hero-slide__scroll-btn:visited {
    color: var(--color-dark);
}

.hero-slide__polygon {
    position: absolute;
    width: 60vw;
    height: auto;
    bottom: 0;
    opacity:1;
    pointer-events: none;
    transform: translateX(-43%) translateY(13%);

    @media screen and (max-width: 767px) {
        width: 150vw;
    }
}

.hero-slide__body{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index:2;
    justify-content: center;
}
.hero-slide__icon {
    color:var(--color-primary);
    opacity: .75;
    font-size:7rem;
    line-height:1px;
    position: relative;
    top:1.25rem;
    margin:5rem 0 0 0;
    text-shadow: 0 0 20px rgba(0,0,0,0.69);
    z-index:-1;

    @media screen and (min-width: 768px) {
        font-size:10rem;
        top:3.5rem;
        margin:8rem 0 0 -3rem;
    }
}
.hero-slide__headlines {
    text-align:center;
    color:#fff;
    opacity:1;
    @media screen and (min-width: 768px) {
        margin-top:calc(-70rem/16);
    }
    @media screen and (min-width: 1500px) {
        max-width: 1500px;
    }
}

.hero-slide__headlines .h1 {
    font-family: var(--font-decorative);
    letter-spacing:var(--font-letterspacing-decorative);
    color:#fff;
    font-size:calc(80rem/16);
    line-height: calc(80/100);
    text-shadow: 0 0 50px #000000;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        line-height: 1;
        font-size:calc(28rem/16);
    }
}

.hero-slide__wysiwyg.wysiwyg {
    max-width: calc(1000rem/16);
    font-size: calc(18rem/16);
    text-align:left;
    margin-top: calc(20rem/16);
    text-shadow: 0 0 10px rgba(0,0,0,0.8);
    @media screen and (max-width: 767px) {
        font-size: calc(13rem/16);
        line-height: 1.4;
        margin-top: calc(10rem/16);
    }
}

.hero-slide--portal .hero-slide__bg {
    height:calc(100vh - 200px);
    min-height:27rem;
    @media screen and (max-width: 767px) {
        margin-top: calc(36rem/16);
        min-height:34.7rem;
    }
    @media screen and (min-width: 768px) {
        height:100vh;
    }
}
.hero-slide--portal .hero-slide__body {
    height:calc(100vh - 200px);
    min-height:27rem;
    padding-bottom:1.5rem;
    @media screen and (min-width: 768px) {
        height:100vh;
        padding-bottom:3.5rem;
    }
}
.hero-slide--portal .hero-slide__headlines .h1 {
    font-size:2.8rem;
    @media screen and (min-width: 768px) {
        font-size:6.2rem;
    }
}
.hero-slide__headlines .h4 {
    text-shadow: 0 0 20px #000000;
}

.hero-slide__headlines .h3 {
    color:#fff;
    font-family: var(--font-rounded-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    margin-bottom:0px;
    text-transform: none;
    text-shadow: 0 0 20px rgba(0,0,0,0.69);
    @media screen and (max-width: 767px) {
        font-size:calc(14rem/16);
    }
}

.hero-slide--portal .hero-slide__headlines {
    text-align:left;
    max-width:800px;
}

.hero-slide__cloud {
    opacity:0;
    position: absolute;
    bottom: 0;
    height: calc(526rem/16);
    transition: opacity 0.3s ease;
    @media screen and (max-width: 767px) {
        margin-top:calc(36rem/16);
        height: calc(280rem/16);
    }
}

.hero-slide__cloud-wrapper {
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    height: 67%;
    pointer-events: none;
    width: 100%;
    overflow:hidden;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 64%,rgba(255,255,255,0.79) 83%,rgba(255,255,255,1) 100%);
    @media screen and (max-width: 767px) {
        height: 40%;
    }
}

.hero-slide__cloud-wrapper .hero-slide__cloud {
    bottom:-150px;
    @media screen and (max-width: 767px) {
        bottom: -80px;
    }
}
.hero-slide__cloud-wrapper--smaller .hero-slide__cloud {
    bottom:-250px;
    @media screen and (max-width: 767px) {
        bottom: -120px;
    }
}
.start-animation .hero-slide__cloud {
    opacity:1;
}

.hero-slide__cloud--left  {
    left:0%;
}

.start-animation .hero-slide__cloud--left {
    -webkit-animation-name: cloudLeftAnimation;
    -webkit-animation-duration: 7s;
    -webkit-animation-fill-mode: forwards;
    animation-name: cloudLeftAnimation;
    animation-duration:7s;
    animation-fill-mode: forwards;
}

.hero-slide__cloud--right {
    right:0%;
}

.start-animation .hero-slide__cloud--right {
    -webkit-animation-name: cloudRightAnimation;
    -webkit-animation-duration: 7s;
    -webkit-animation-fill-mode: forwards;
    animation-name: cloudRightAnimation;
    animation-duration:7s;
    animation-fill-mode: forwards;
}

.hero-slide__cloud--center {
    left:0;
    right:0;
    width:100%;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.start-animation .hero-slide__cloud--center {
    -webkit-animation-name: cloudCenterAnimation;
    -webkit-animation-duration: 10s;
    -webkit-animation-fill-mode: forwards;
    animation-name: cloudCenterAnimation;
    animation-duration: 10s;
    animation-fill-mode: forwards;
    @media screen and (max-width: 767px) {
        -webkit-animation-name: cloudCenterAnimationMobile;
        animation-name: cloudCenterAnimationMobile;
    }
}

.hero-slide__bg {
    position:relative;
    @media screen and (max-width: 767px) {
        margin-top:calc(28rem/16);
    }
}
.hero-slide__bg-gradient {
    position:absolute;
    left:0;
    top:45%;
    bottom:0;
    right:0;
    z-index:1;
    background:linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 53%);
    opacity:.85;
    @media screen and (min-width: 768px){
        display:none;
    }
}
.hero-slide__bg-stretch {
    position:absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
}
.hero-slide__bg-stretch picture {
    position:absolute;
    width:100%;
    height:100%;
}
.hero-slide__bg-stretch img {
    object-fit:cover;
    width:100%;
    height:100%;
}

.js-animationStart .hero-slide__bg {
    @media screen and (min-width: 768px) {
        transform: scale(1.2);
    }
}

.hero-slide__media-wrapper {
    overflow:visible;
}
.hero-slide__media-wrapper:after {
    @media screen and (min-width: 768px){
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: -1px;
        right: 0;
        background: linear-gradient(to right, rgba(17,17,17,1) 0%,rgba(17,17,17,0) 32%,rgba(18,18,18,0) 100%);
    }
}

.hero-slide__media-fullscreen {
    position: absolute;
    z-index: 1;
    right: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    border: .2rem solid #fff;
    background: rgba(25,30,48,.9);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    color: #fff;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow:none;
}
.hero-slide__overlay {
    position:absolute;
    top:0;
    width:100%;
    height: 100%;
    z-index:1;
    @media screen and (max-width: 767px) {
        position:relative;
        padding-bottom:calc(20rem/16);
    }
}

.hero-slide__overlay--webcams {
    height: calc(100% - 280px);
}

.hero-slide__overlay--profile {
    @media screen and (max-width: 767px) {
        position:absolute;
    }
}

.start-animation .hero-slide__bg {
    @media screen and (min-width: 768px) {
        -webkit-animation-name: heroBgAnimation;
        -webkit-animation-duration: 4s;
        -webkit-animation-fill-mode: forwards;
        animation-name: heroBgAnimation;
        animation-duration: 4s;
        animation-fill-mode: forwards;
    }
}

.hero-slide--shop .hero-slide__headlines {
    margin-top:0;
}
.hero-slide__additional-infos {
    color: var(--color-headline-default);
    font-size: calc(14rem/16);
    font-weight: var(--font-weight-rounded-medium);
    text-transform: uppercase;
    margin-top: calc(65rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(25rem/16);
        font-size: calc(12rem/16);
    }
}
.hero-slide__additional-infos__icon {
    font-size: calc(34rem/16);
    margin-right: calc(10rem/16);
    color: var(--color-primary);
    @media screen and (max-width: 767px) {
        font-size: calc(26rem/16);
    }
}
.hero-slide__cta {
    margin-top:1.25rem;
    display:flex;
    flex-direction:column;
    @media screen and (min-width: 768px) {
        margin-top:2rem;
        flex-direction:column-reverse;
    }
}
.hero-slide__cta-usp, .hero-slide__cta-button-wrapper {
    @media screen and (max-width: 767px) {
        margin-top:1.25rem;
    }
    @media screen and (min-width: 768px) {
        margin-bottom:2rem;
    }
    &:first-child {
        @media screen and (max-width: 767px) {
            margin-top:0;
        }
        @media screen and (min-width: 768px) {
            margin-bottom:0;
        }
    }
}
.hero-slide__cta-usp ul {
    padding:0;
    margin:0;
}
.hero-slide__cta-usp ul li {
    list-style-type:none;
    color:var(--color-headline-default);
    font-family:var(--font-rounded-bold);
    font-weight:var(--font-weight-rounded-medium);
    font-size:calc(9rem/16);
    float:left;
    margin:.25rem calc(22rem/16) .25rem 0;
    &:last-child {
        margin-right:0;
    }
    @media screen and (min-width: 768px) {
        font-size:calc(14rem/16);
        margin-right:calc(50rem/16);
    }
}
.hero-slide__cta-usp-icon {
    margin-right:.5rem;
    width: calc(10rem/16);
    display: inline-block;
    @media screen and (min-width: 768px) {
        width: calc(15rem/16);
    }
}

@keyframes heroBgAnimation {
    from{
        transform: scale(1.25);
    }
    to {
        transform: scale(1.0);
    }
}

@keyframes cloudLeftAnimation {
    from {
        opacity:0.7;
        transform: translateX(0%);
    }
    to {
        opacity:0.8;
        transform: translateX(-10%);
    }
}

@keyframes cloudCenterAnimation {
    from {
        opacity:1;
        transform: translateY(0%);
    }
    to {
        opacity:1;
        transform: translateY(15%);
    }
}
@keyframes cloudCenterAnimationMobile {
    from {
        opacity:1;
        transform: translateY(0%);
    }
    to {
        opacity:0.8;
        transform: translateY(5%);
    }
}

@keyframes cloudRightAnimation {
    from {
        opacity:1;
        transform: translateX(0%);
    }
    to {
        opacity:1;
        transform: translateX(10%);
    }
}

@keyframes headlineAnimation {
    from {
        opacity:0;
        transform: translateY(10%);
    }
    to {
        opacity:1;
        transform: translateY(0%);
    }
}

@keyframes polyAnimation {
    from {
        opacity:0;
        transform: translateX(-48%) translateY(14%);
    }
    to {
        opacity:1;
        transform: translateX(-43%) translateY(13%);
    }
}

.hero-slide--align-content-left .hero-slide__body {
    justify-content: start;
}
.hero-slide--align-content-left .hero-slide__headlines {
    text-align: left;
}

.hero-slide__alpine-crossing {
    max-width: calc(260rem/16);
    margin: calc(-100rem/16) auto 0;
    position: relative;
    @media screen and (min-width: 768px) {
        max-width: calc(400rem/16);
    }
    @media screen and (min-width: 1400px) {
        max-width: calc(550rem/16);
    }
    @media screen and (min-width: 1680px) {
        max-width: calc(700rem/16);
    }
}
.hero-slide__alpine-crossing-headline {
    margin-bottom: calc(10rem/16);
    font-family: var(--font-default);
    text-transform: uppercase;
    font-size: calc(16rem/16);
    line-height: 1;
    bottom: 100%;
    @media screen and (min-width: 768px) {
        font-size: calc(24rem/16);
        margin-bottom: calc(16rem/16);
    }
    @media screen and (min-width: 1400px) {
        font-size: calc(30rem/16);
        margin-bottom: calc(20rem/16);
    }
    @media screen and (min-width: 1680px) {
        font-size: calc(40rem/16);
        margin-bottom: calc(20rem/16);
    }
}


