.hotspot-img {
    position: relative;
}
.hotspot-img__hotspot {
    position: absolute;
    transform: translate(-50%,-100%);
    top: 50%;
    left: 50%;

    @media screen and (max-width: 767px) {
        max-width: 30px;
    }
}
.hotspot-img__hotspot > img {
    @media screen and (max-width: 767px) {
        max-width: 100%;
        height: auto;
    }
}
.hotspot-img__background {
    display: block;
    width: 100%;
    height: auto;
}
@media screen and (max-width: 767px) {
    .hotspot-img {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
    }
    .hotspot-img__scroll-area {
        position: relative;
        min-width: 700px;
    }
}