.slick:not(.slick-initialized) > div + div {
    display: none;
}

.slick-dots {
  display:none !important;
}
.slick-slider:not(.slick-initialized)   >  div  +  div  {
    display: none;
}
.slick-arrow {
    transform: opacity 120ms;
}
.slick-arrow.slick-disabled {
    opacity: 0;
}

.slick--no-arrows .slick-arrow {
    display: none !important; /*override inline style*/
}

.slick-thumbs .slick-slide {
    opacity: .5;
    transition: opacity 120ms ease-out;
}
.slick-thumbs .slick-slide:hover,
.slick-thumbs .slick-slide:focus,
.slick-thumbs .slick-current  {
    opacity: 1;

}