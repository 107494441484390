.review-slider {
    position: relative;
}
.review-slide__item:after {
    content:'';
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    right:0;
    z-index: 0;
    background: linear-gradient(180deg, rgba(25,30,47,0) 0%, #1E1E1E 100%);
}
.review-slide__item-logo {
    max-width: calc(300rem/16);
    margin:0 auto;
    @media screen and (max-width: 767px) {
        max-width: calc(200rem/16);
    }
}
.review-slider .slide__item__description-wrapper {
    position:absolute;
    top:0;
    left:0;
    height:100%;
    color:#fff;
    width:100%;
    margin:0 auto;
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index: 3;
}
.review-slider .slide__item__description {
    width: calc(644rem/16);
    position:relative;
    z-index: 3;
}
.review-slider__arrow {
    position: absolute;
    display: block;
    z-index:2;
    cursor:pointer;
    font-size:calc(50rem/16);
    width:50px;
    color:#fff;
    @media screen and (max-width: 767px) {
        display:none;
        visibility:hidden;
    }
    @media screen and (min-width: 768px) {
        top: 50%;
        transform: translateY(-50%);
    }
}
.review-slider__arrow.slick-disabled {
    color:#fff;
}
.review-slider__arrow.slick-disabled:hover {
    color:var(--color-primary);
}
.review-slider__arrow.arrow-next:not(.slick-hidden) {
    right:calc( (100% - 1195px ) / 4 );
    @media screen and (max-width: 1240px)  and (min-width: 768px) {
        right:calc( (100% - 850px ) / 4 );
    }
    @media screen and (max-width: 767px) {
        right:0;
        display:inline-block;

    }
}
.review-slider__arrow.arrow-prev:not(.slick-hidden) {
    left:calc( (100% - 1195px ) / 4 );
    @media screen and (max-width: 1240px) and (min-width: 768px) {
        left:calc( (100% - 850px ) / 4 );
    }
    @media screen and (max-width: 767px) {
        left:0;
        display:inline-block;
    }
}
.review-slider .slick-dots {
    display:block !important;
    position: absolute;
    bottom: calc(28rem/16);
    left: 0;
    text-align:center;
    right: 0;
    padding-left:0;
}
.review-slider .slick-dots li {
    display:inline-block;
    padding-left:calc(5rem/16);
    padding-right:calc(5rem/16);
}
.review-slider .slick-dots button {
    display: none;
}
.review-slider .slick-dots li:before {
    content:'';
    width:calc(10rem/16);
    height:calc(10rem/16);
    background-color: rgba(255,255,255,0.25);
    border-radius: 50%;
    display:block;
}
.review-slider .slick-dots .slick-active:before {
    background-color:#fff;
}