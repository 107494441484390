html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body:not(.body--widget) {
        width: 982px;
    }
}
@media screen and (max-width: 767px) {
    body:not(.body--widget) {
        min-width: 320px;
        max-width: 100%;
        overflow-x: hidden;
    }
}

.page-wrapper {
    padding-top:60px;
    @media screen and (max-width: 767px) {
        padding-top:100px;
    }
    overflow-x:hidden;
}
.page-wrapper--shop {
    padding-top:60px;
    @media screen and (max-width: 767px) {
        padding-top:40px;
    }
    overflow-x:hidden;
}
.main-content--has-spacing {
    padding-top: calc(69rem/16);
    padding-bottom: calc(100rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(40rem/16);
        padding-bottom: calc(40rem/16);
    }
}