.lg-backdrop {
    background: linear-gradient(180deg,rgba(25,30,48,.9),rgba(0,0,0,.9));
}

.lg-sub-html, .lg-toolbar, .lg-actions .lg-prev, .lg-actions .lg-next {
    background-color:transparent;
    color:#fff;
}

.lg-actions .lg-prev, .lg-actions .lg-next {
    font-size:calc(40rem/16);
}

.lg-toolbar .lg-close, .lg-actions .lg-next, .lg-actions .lg-prev {
    color:#fff;
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
}
.lg-toolbar .lg-close:hover {
    color:var(--color-primary);
}
.lg-toolbar .lg-close:after {
    content: var(--icon-close);
}

.lg-actions .lg-next:before {
    content: var(--icon-arrow);
}

.lg-actions .lg-prev {
    transform: scale(-1, 1);
}



.lg-actions .lg-prev:after {
    content:var(--icon-arrow);
}

.lightgallery-play__btn {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 60px;
    height: 60px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.73);
    opacity: 1;
    z-index: 1;
    border: none;
    color: var(--color-text-default);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(24rem/16);
}

.custom-lightbox .lg-object {
    max-height: 60vh;
}

.webcam-lightbox .lg-object {
    max-height: 80vh;
}
.webcam-lightbox .lg-has-iframe .lg-video {
    padding-bottom:57.25%
}