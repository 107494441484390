
.progress-bar {
    position:relative;
}
.progress-bar__circle {
    width: 1.875rem;
    height: 1.875rem;
    box-shadow: 0 2px 9px 0 rgba(0,0,0,.2);
    background-color: #fff;
    position: absolute;
    border-radius: 50%;
    z-index: 2;
    margin-top: -10px;
    transition: all 0.5s ease-out;
    left: -1%;
}