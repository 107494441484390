.webcam-item {
    position: relative;
}
.webcam-item__embed {
    padding-top: calc(408 / 779 * 100%);
}
.webcam-item__content {
    position: absolute;
    width: 100%;
    height:100%;
    padding: calc(30rem/16) calc(30rem/16) calc(24rem/16);
    top:0;
    color:#fff;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    background-color: rgba(0,0,0,0.3);
    transition: background-color 0.3s ease;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}
.webcam-item:hover .webcam-item__content {
    background-color: rgba(0,0,0,0.1);
}
.webcam-item__title {
    font-size: calc(60rem/16);
    line-height:1;
    text-shadow: 2px 2px 8px rgba(0,0,0,0.3);
    font-family: var(--font-decorative);
    color:#fff;
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.webcam-item__subtitle {
    font-size: calc(14rem/16);
    text-shadow: 2px 2px 8px rgba(0,0,0,0.3);
    font-family: var(--font-default);
}
.webcam-item__icon {
    font-size: calc(40rem/16);
    position: absolute;
    right:calc(10rem/16);
    top: calc(10rem/16);
}