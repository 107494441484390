.notification-header {
    padding: calc(25rem/16) 0;
    box-shadow: inset 5px 3px 12px 0 rgba(0,0,0,0.06), 5px 1px 11px 0 rgba(0,0,0,0.06);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) 0;
        position: relative;
        z-index: 7;
    }
}
.notification-header__text {
    font-size:calc(15rem/16);
    line-height: calc(20/16);
    text-align:center;
    margin-bottom: calc(20rem/16);
    font-weight: var(--font-weight-rounded-light);
    @media screen and (max-width: 767px) {
        font-size: calc(11rem/16);
        margin-bottom: calc(8rem/16);
    }
}
.notification-header__text > p:last-of-type {
    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }
}
.notification-header__link {
    font-size: calc(16rem/16);
    line-height: calc(19/16);
    font-family:var(--font-rounded-bold);
    font-weight: var(--font-weight-rounded-medium);
    text-align:center;
    margin: 0 auto;
    display:block;
    transition: color 0.2s ease;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.notification-header--light .notification-header__link {
    color:var(--bg-grey);
}
.notification-header__link:hover {
    color:var(--color-primary);
}
.notification-header--primary .notification-header__link:hover {
    color:#fff;
}
.notification-header--primary .notification-header__close:hover {
    color:#fff;
}
.notification-header__close {
    position:absolute;
    font-size:calc(20rem/16);
    padding: calc(20rem/16);
    right:0;
    cursor:pointer;
    top:0;
    transition: color 0.2s ease;
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16);
        font-size: calc(12rem/16);
    }
}
.notification-header--light {
    color:var(--color-text-default);
}
.notification-header--dark {
    background-color:var(--color-text-default);
    color:#fff;
}
.notification-header--primary {
    background-color: var(--color-primary);
    color:#fff;
}
.notification-header--dark .notification-header__close {
    color:#fff;
}
.notification-header__close:hover {
    color:var(--color-primary);
}
.notification-header__body {
    @media screen and (max-width: 767px) {
        padding-top: calc(15rem/16);
    }
}
.notification-header__percentage {
    text-align: center;
    font-size: calc(42rem/16);
}

.has-open-overlay .notification-header {
    display: none;
}