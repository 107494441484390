
.square-link-box {
    margin-top: -3%;
    position:relative;
    z-index: 1;
    @media screen and (max-width: 767px) {
        margin-top: calc(15rem/16);
        margin-left:calc(15rem/16);
        margin-right:calc(15rem/16);
    }
}

.square-link-box__item__link {
    @media screen and (max-width: 767px) {
        font-size:calc(16rem/16);
        line-height:1;
    }
}

.square-link-box__item {
    background-color: #fff;
    color: var(--color-headline-default);
    font-size:calc(20rem/16);
    text-align:center;
    font-family:var(--font-default-bold);
    text-transform: uppercase;
    box-shadow: 0 2px 60px 0 rgba(0,0,0,0.2);
    display:flex;
    align-items:center;
    justify-content:center;
    height:auto;
    margin-bottom:calc(12rem/16);
    @media screen and (max-width: 767px) {
        min-height:calc(120rem/16);
    }

    @media screen and (min-width: 768px) {
        height:calc(150rem/16);
        width:calc(150rem/16);
    }
    @media screen and (min-width: 1400px) {
        height:calc(232rem/16);
        width:calc(232rem/16);
        margin-right: calc(40rem/16);
        box-shadow: 0 2px 60px 0 rgba(0,0,0,0.5);
        margin-bottom:calc(20rem/16);

    }
}

.square-link-box__item:last-of-type {
    margin-right:0px;
}

.square-link-box__item .icon {
    color:var(--color-primary);
    font-size:calc(80rem/16);
    margin-bottom:calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(20rem/16);
        margin-top:calc(10rem/16);
        margin-bottom:calc(10rem/16);
    }
}
.square-link-box__item.active {
    background-color: var(--color-primary);
    color: #fff;
}
.square-link-box__item.active .icon {
    color:#fff;
}