.cart-bottom-bar {
    background-color:#fff;
    color: var(--color-headline-default);
    padding: calc(30rem/16) 0;
    box-shadow: 0 -15px 30px 0 rgba(0,0,0,0.1);
    @media screen and (max-width: 1400px) {
        padding: calc(25rem/16) 0;
    }
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(20rem/16) 0;
    }
}
.cart-bottom-bar-wrapper {
    margin-top: calc(80rem/16);
}
.cart-bottom-bar__container {
    max-width: 102.5rem;
    width: 100%;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}
.cart-bottom-bar__title {
    text-transform: uppercase;
    font-weight: var(--font-weight-rounded-medium);
    margin-bottom: calc(5rem/16);
    line-height: calc(19/16);
    letter-spacing: calc(1rem/16);
    @media screen and (max-width: 1400px) {
        font-size: calc(14rem/16);
    }
}
.cart-bottom-bar__subtitle {
    font-weight: var(--font-weight-rounded-medium);
    letter-spacing: calc(1rem/16);
    @media screen and (max-width: 1400px) {
        font-size: calc(12rem/16);
    }
    @media screen and (max-width: 767px) {
        color: var(--color-grey);
        font-weight: var(--font-weight-rounded-light);
    }
}
.cart-bottom-bar__list {
    color: var(--color-grey);
    font-size: calc(14rem/16);
    @media screen and (max-width: 1400px) {
        font-size: calc(12rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-top: calc(10rem/16);
        border-top: calc(1rem/16) solid var(--color-light);
    }
}
.cart-bottom-bar__total-title {
    text-transform: uppercase;
    font-weight: var(--font-weight-rounded-medium);
    margin-bottom: calc(5rem/16);
    line-height: calc(19/16);
    font-size: calc(14rem/16);
    letter-spacing: calc(1rem/16);
    @media screen and (max-width: 1400px) {
        font-size: calc(14rem/16);
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.cart-bottom-bar__total {
    font-size: calc(20rem/16);
    font-weight: var(--font-weight-rounded-medium);
    @media screen and (max-width: 1400px) {
        font-size: calc(16rem/16);
    }
    @media screen and (max-width: 767px) {
        color: var(--color-grey);
        font-size: calc(14rem/16);
        font-weight: var(--font-weight-rounded-light);
    }
}
.cart-bottom-bar__btn {
    display: block;
    margin-left: auto;
    max-width: calc(300rem/16);
    @media screen and (max-width: 767px) {
        max-width: initial;
        width: 100%;
        font-size: calc(16rem/16);
        padding: calc(14rem/16);
    }
}
.cart-bottom-bar__btn-wrapper {
    @media screen and (max-width: 767px) {
        margin-top: calc(15rem/16);
        padding: 0;
    }
}
.cart-bottom-bar__edit-link {
    color: var(--color-primary);
    font-weight: var(--font-weight-rounded-medium);
}