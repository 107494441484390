.webcam-teaser {
    margin-bottom:calc(40rem/16);
}
.webcam-teaser .teaser__image:before {
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    transition: background 0.3s ease;
    left:0;
    right:0;
    top:0;
}
.webcam-teaser:hover .teaser__image:before {
    background:transparent;
    transition:all 0.3s ease;
}
.webcam-teaser .teaser__title {
    text-shadow: 2px 2px 8px rgba(0,0,0,0.3);
}
.webcam-teaser__icon {
    font-size:calc(28rem/16);
    text-align:right;
    @media screen and (max-width: 767px) {
        font-size:calc(22rem/16);
    }
}
.webcam-teaser--no-shadow .teaser__title {
    text-shadow: none;
}