.ticket-detail-benefits__icon {
    font-size: calc(30rem/16);
    color: var(--color-primary);
    margin-bottom: calc(5rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(24rem/16);
    }
}
.ticket-detail-benefits__icon--green {
    color: var(--color-pastel-green);
}
.ticket-detail-benefits__text {
    font-size: calc(10rem/16);
    font-weight: var(--font-weight-rounded-medium);
    color: var(--color-headline-default);
    @media screen and (max-width: 767px) {
        font-size: calc(8rem/16);
    }
}
.ticket-detail-benefits__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
}
.ticket-detail-benefits__right {
    display: flex;
    align-items: center;
    width: 80%;
}
.ticket-detail-benefits__item {
    display: flex;
    column-gap: calc(10rem/16);
    justify-content: space-between;
} 
.ticket-detail-benefits__description {
    font-size: calc(15rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}