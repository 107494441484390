.map-label {
    border: none;
    position: absolute;
    transform: translate(-50%, -50%);
    padding: .2rem .4rem;
    padding: calc(.1vw + .1rem) calc(.15vw + .2rem);
    background: var(--color-primary);
    color: var(--color-primary-contrast);
    font-size: calc(.5vw + .5rem);
    box-shadow: 0 1px 20px rgba(0,0,0,.3);
}
.map-label:hover,
.map-label:active,
.map-label:focus {
    background: var(--color-primary-dark);
    color: var(--color-primary-contrast);
}
.map-label--white,
.map-label--white:hover,
.map-label--white:active,
.map-label--white:focus {
    background: #ffffff;
    color: var(--color-text-default);
}

.map-label--circle {
    width: calc(65rem / 16);
    height: calc(65rem / 16);
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    line-height: calc(60rem / 16);
    @media screen and (max-width: 1640px) {
        line-height: calc(42rem/16);
        font-size: calc(18rem/16);
        width:calc(45rem/16);
        height:calc(45rem/16);
    }
}
.map-label__webcam-icon {
    font-size: 1.2rem;
    vertical-align: text-bottom;
}