.slide__item {
    position: relative;
}

.slide__item__img.img__gradient:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 102%;
    z-index: 1;
    background: linear-gradient(to right, rgba(17, 17, 17, 1) 0%, rgba(17, 17, 17, 1) 13%, rgba(17, 17, 17, 0) 65%, rgba(17, 17, 17, 0) 100%);
    @media screen and (max-width: 1400px) and (min-width: 768px) {
        background: linear-gradient(90deg,#111 0,#11111194 63%,rgba(17,17,17,0) 95%,rgba(17,17,17,0));
    }
}
.slide__item__img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding-top: calc(60rem / 16);
    padding-bottom: calc(160rem / 16);
    @media screen and (max-width: 1199px) {
        padding-top: calc(20rem / 16);
        padding-bottom: calc(30rem / 16);
    }
}
.slide__item__arrows {
    margin-left: calc(60rem / 16);
}
.slide__item__arrows .icon {
    font-size: calc(33rem / 16);
}
.slide__item__arrows .arrow-prev {
    margin-right: calc(60rem / 16);
    cursor: pointer;
}
.slide__item__arrows .arrow-next {
    cursor: pointer;
}
.arrow-next:hover, .slide__item__arrows .arrow-prev:hover {
    color: var(--color-primary);
}
.slick-disabled.slick-arrow, .slick-disabled.slick-arrow:hover {
    color: var(--color-text-primary);
}

.slide__item__img-overlay-content {
    height: 100%;
    max-width: calc(700rem/16);
    width: 100%;
    @media screen and (max-width: 1400px) {
        max-width: 100%;
    }
}
.slide__item__headline {
    font-size: calc(40rem / 16);
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    line-height: 1.2;
    @media screen and (max-width: 767px) {
        font-size: calc(20rem / 16);
    }
}
.slide__item__description {
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-light);
    line-height: calc(20/16);

    padding-bottom: calc(30rem / 16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem / 16);
    }
}
.slide__item__headline--translate-left {
    text-shadow: 2px 2px 8px rgba(0,0,0,0.3);
    @media screen and (min-width: 768px) {
        transform: translateX(calc(-140rem/16));
        width: calc(100% + calc(140rem/16));
    }
}
.slide__item__description--blue-border-top {
    position: relative;
    padding-top: calc(18rem / 16);
    margin-top: calc(26rem / 16);
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem / 16);
        padding-top: calc(10rem / 16);
        padding-bottom:calc(10rem/16);

    }
}
.slide__item__description--blue-border-top:before {
    width: calc(67rem / 16);
    height:calc(1rem/16);
    background-color: var(--color-primary);
    content: '';
    display: block;
    position: absolute;
    top: 0;
}
.nav-slide__item {
    padding-left: calc(10rem/16);
    padding-right:  calc(10rem/16);
    @media screen and (max-width: 767px) {
        padding-left:  calc(12rem/16);
        padding-right:  calc(12rem/16);
    }
}

/* adventure slider */
.adventure-slide__item .slide__item__title {
    font-size: calc(40rem / 16);
    line-height: 1;
    letter-spacing: var(--font-letterspacing-default-bold);
    font-family: var(--font-default-bold);
    margin-top: 0;
    margin-bottom: calc(20rem / 16);
    color:#fff;
}
.adventure-slider .slide__item__img-overlay {
    padding-bottom: calc(130rem / 16);
}
.adventure-slide__item .slide__item__subtitle {
    font-size: calc(20rem / 16);
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    line-height: calc(29 / 20);
    margin-bottom: calc(15rem / 16);
}
.adventure-slide__item .wysiwyg {
    font-size: 1rem;
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-light);
}
.adventure-slide__item .slide__item__rating {
    color: var(--color-primary);
    margin-top: calc(25rem / 16);
    margin-bottom: calc(20rem / 16);
    @media screen and (max-width: 1199px) {
        margin-top: calc(10rem / 16);
        margin-bottom: calc(10rem / 16);
    }
}
.adventure-slide__item .slide__item__like, .adventure-slide__item .slide__item__shoppingcart {
    font-size: calc(25rem / 16);
    margin-left: calc(17rem / 16);
    padding-top: calc(6rem/16);
    display: inline-flex;
}
.adventure-slide__item .slide__item__like:hover, .adventure-slide__item .slide__item__shoppingcart:hover {
    color: var(--color-primary);
}
.adventure-nav-slider {
    padding-bottom: calc(80rem / 16);
    margin-top: calc(-100rem / 16);
    @media screen and (max-width: 1640px) and (min-width: 1200px) {
        margin-top: calc(-20rem / 16);
    }
    @media screen and (max-width: 1199px) {
        margin-top: calc(20rem/16);
        padding-bottom: calc(30rem / 16);
    }
}
.adventure-nav-slide__item .teaser__image:before {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: all 0.2s ease;
}
.adventure-nav-slide__item--transformed {
    @media screen and (min-width: 768px) {
        transform:translateX(100%);
        backface-visibility: hidden;
        transition: all 0.2s ease;
    }
}

.adventure-nav-slide__item .teaser__image:hover:before,
.slick-slide.slick-center .teaser__image:before,
.slick-slide.slick-current .teaser__image:before {
    background-color: transparent;
}

.adventure-slider--mobile .slick-list, .adventure-slider--mobile .slick-track {
    padding-left: calc(6rem/16);
    padding-right: calc(12rem/16);
}

.teaser-row-center-slider .teaser {
    margin:0 1.125rem;
    @media screen and (max-width: 767px) {
        margin:0 .625rem;
    }
}

.teaser-row-slider__arrow-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.teaser-row-slider__arrow.arrow-prev {
    margin-left:-60px;
}
.teaser-row-slider__arrow.arrow-prev--full {
    margin-left:85px;
}

.teaser-row-slider__arrow.arrow-next {
    margin-right: -60px;
    right: 0;
    position: absolute;
}
.teaser-row-slider__arrow.arrow-next--full {
    margin-right: 85px;
}
.teaser-row-slider__arrow {
    z-index: 1;
    font-size: 40px;
    color: var(--color-primary);
    cursor:pointer;
}

/* company teaser slider mobile */

@media screen and (min-width: 786px) {
    .company-teaser-slider-mobile {
        display: none !important;
    }
}

.company-teaser-slider-mobile .slick-arrow {
    position: absolute;
    bottom: -3.5rem;
    width:2.5rem;
    height:2.5rem;
    background: none;
    color: transparent;
    border-radius:0;
    line-height: 0;
    cursor: pointer;
    border:0;
    outline:0 !important;
    font-size: 0;
}

.company-teaser-slider-mobile .slick-prev {
    left: calc(20rem/16);
}

.company-teaser-slider-mobile .slick-next {
    right: calc(20rem/16);

}
.company-teaser-slider-mobile .slick-prev:before {
    font-family: iconfont;
    content:var(--icon-arrow);
    font-size:1.75rem;
    display: inline-block;
    position: absolute;
    left:50%;
    top:50%;
    color: var(--color-primary);
    transform: translateX(-50%) translateY(-50%) rotate(180deg);
}

.company-teaser-slider-mobile .slick-next:after {
    font-family: iconfont;
    content:var(--icon-arrow);
    font-size:1.75rem;
    display: inline-block;
    position: absolute;
    left:50%;
    top:50%;
    color: var(--color-primary);
    transform: translateX(-50%) translateY(-50%);
}


/* offer-teaser-slider */
.offer-teaser-slider__arrow-wrapper .slick-arrow.slick-disabled {
    opacity: 0.7;
    color: var(--color-grey-light);
}
.offer-teaser-slider__arrow {
    color:var(--color-grey-light);
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.offer-teaser-slider__arrow.arrow-prev {
    padding-right: calc(20rem/16);
}
.offer-teaser-slider__arrow.arrow-next {
    padding-left: calc(20rem/16);
}
.offer-teaser-slider__arrow-wrapper {
    display: flex;
    @media screen and (max-width: 767px) {
        display:none;
    }
}
.offer-teaser-slider__dots .slick-dots {
    padding-left:0;
    list-style: none;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.offer-teaser-slider__dots .slick-dots > li {
    display: inline-flex;
    margin-left: calc(10rem/16);
    margin-right: calc(10rem/16);
}
.offer-teaser-slider__dots .slick-dots > li > button {
    background: var(--color-grey-light);
    color: var(--color-grey-light);
    border: none;
    border-radius: 50%;
    width: calc(10rem/16);
    height: calc(10rem/16);
    padding: 0;
    text-align: center;
    cursor: pointer;
    font-size: calc(1rem/16);
    content:'';
}
.offer-teaser-slider__dots .slick-dots > li > button:hover,
.offer-teaser-slider__dots .slick-dots > li > button:focus,
.offer-teaser-slider__dots .slick-dots > .slick-active > button {
    background: var(--color-primary);
    color: var(--color-primary);
}

.slider--hide-dots .slick-dots {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

