.user-thumb {
    margin: 0 auto;
    border-radius: calc(50rem/16);
    overflow: hidden;
    border: calc(4rem/16) solid #FFFFFF;
    box-shadow: 2px 1px 17px rgba(0, 0, 0, .58);
}

.user-thumb-sm {
    width: calc(89rem / 16);
    height: calc(89rem / 16);
}

.user-thumb--xxl{
    width: calc(190rem/16);
    height: calc(190rem/16);
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    border: calc(4rem/16) solid #FFFFFF;
    box-shadow: 2px 1px 17px rgba(0, 0, 0, .58);
    background-color: #FFF;

    @media screen and (max-width: 767px) {
        width: calc(70rem/16);
        height: calc(70rem/16);
        border: calc(2rem/16) solid #FFFFFF;

    }
}

