.ticket-overview-table {
    color: var(--color-headline-default);
}
.ticket-overview-table__header {
    font-size: calc(14rem/16);
    font-weight: var(--font-weight-rounded-medium);
    padding-bottom: calc(10rem/16);
    border-bottom: 1px solid var(--color-grey-light);
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.ticket-overview-table__row {
    padding: calc(10rem/16) 0;
}
.ticket-overview-table__footer {
    border-top: 1px solid var(--color-grey-light);
    padding-top: calc(10rem/16);
    font-size: calc(20rem/16);
    text-transform: uppercase;
    font-weight: var(--font-weight-rounded-medium);
}
.ticket-overview-table__row + .ticket-overview-table__row {
    @media screen and (max-width: 767px) {
        border-top: 1px solid var(--color-grey-light);
    }
}
.ticket-overview-table__row-title {
    @media screen and (max-width: 767px) {
        font-weight: var(--font-weight-rounded-medium);
        font-size: calc(16rem/16);
    }
}