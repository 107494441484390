.cart-item {
    border: calc(1rem/16) solid var(--color-grey-light);
}
.cart-item__body {
    padding: calc(40rem/16);
    @media screen and (max-width: 1199px) {
        padding: calc(10rem/16);
    }
}
.cart-item--preview .cart-item__body {
    padding: calc(10rem/16);
}
.cart-item__date {
    color: var(--color-grey);
    font-weight: var(--font-weight-rounded-medium);
    margin-bottom: calc(22rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
        margin-bottom: calc(10rem/16);
    }
}
.cart-item--preview .cart-item__date {
    font-size: calc(12rem/16);
    margin-bottom: calc(15rem/16);
}
.cart-item__title {
    text-transform: uppercase;
    margin-bottom: calc(4rem/16);
    font-size: calc(24rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem / 16);
    }
}
.cart-item--preview .cart-item__title {
    font-size: calc(16rem / 16);
}
.cart-item__list {
    font-size: calc(14rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.cart-item--preview .cart-item__list {
    font-size: calc(12rem / 16);
    margin-bottom: calc(10rem/16);
}
.cart-item__list-item {
    line-height: 1.1;
    font-weight: var(--font-weight-rounded-light);
    @media screen and (max-width: 1199px) {
        font-size: calc(10rem/16);
    }
}
.cart-item__list-item + .cart-item__list-item {
    margin-top: calc(15rem/16);
}
.cart-item--preview .cart-item__list-item + .cart-item__list-item {
    margin-top: calc(5rem/16);
}
.cart-item__action-btn {
    font-size: calc(25rem/16);
    padding: calc(10rem/16);
    color: var(--color-grey);
    @media screen and (max-width: 767px) {
        font-size: calc(22rem/16);
        padding: 0 calc(8rem/16);
    }
}
.cart-item__price {
    color: var(--color-headline-default);
    font-size: calc(20rem/16);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(14rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
.cart-item--preview .cart-item__price {
    font-size: calc(16rem/16);
}
.cart-item__price-before {
    font-size: calc(14rem/16);
    color: var(--color-grey);
    text-decoration: line-through;
    @media screen and (max-width: 1199px) {
        font-size: calc(12rem/16);
    }
}
.cart-item--preview .cart-item__price-before {
    font-size: calc(12rem/16);
}
.cart-item {
    margin-top: calc(20rem/16);
}
.cart-item:nth-of-type(0) {
    margin-top: 0;
}
.cart-item__list-item__action-btn {
    padding: 0 calc(5rem / 16);
    @media screen and (max-width: 767px) {
        padding: 0 calc(6rem / 16);
        font-size: calc(13rem/16);
    }
}