.waypoints {
    position: fixed;
    left: 0;
    bottom: 10%;
    padding-left: calc(21rem/16);
    z-index: 3;
}
.waypoints__item {
    position:relative;
    line-height:calc(15rem/16);
}
.waypoint__item--light .waypoints__item-text{
    color:#fff;
}
.waypoints__item:before {
    content:'';
    display:block;
    height:1px;
    width:calc(10rem/16);
    background-color:var(--bg-grey);
    left:0;
    position:absolute;
    top:50%;
    transition: all 0.2s ease;
}
.waypoints__item-text {
    opacity:0;
    font-size:calc(16rem/16);
    color:var(--color-headline-default);
    font-family:var(--font-default-bold);
    transition: all 0.2s ease;
}
.waypoints__item--active .waypoints__item-text, .waypoints__item:hover .waypoints__item-text {
    opacity:1;
    padding-left:calc(40rem/16);
}
.waypoints__item--active:before, .waypoints__item:hover:before {
    width:calc(25rem/16);
    background-color:var(--color-primary);
}