body {
    color: var(--color-text-default);
    font-family: var(--font-rounded-light);
    font-weight:var(--font-weight-rounded-light);
    font-size: var(--font-size-default-mobile);

    @media screen and (min-width: 768px) {
        font-size: var(--font-size-default);
    }
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    font-weight: bold;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: var(--color-headline-default);
}

h1, .h1 {
    font-size: calc(24rem/16);
    @media screen and (min-width: 360px) {
        font-size: calc(30rem/16);
    }
    @media screen and (min-width: 768px) {
        font-size: calc(45rem/16);
    }
}
h2, .h2 {
    font-size: calc(20rem/16);
    @media screen and (min-width: 360px) {
        font-size: calc(25rem/16);
    }
    @media screen and (min-width: 768px) {
        font-size: calc(30rem/16);
    }
}
h3, .h3 {
    font-size: calc(18rem/16);
    @media screen and (min-width: 360px) {
        font-size: calc(22rem/16);
    }
    @media screen and (min-width: 768px) {
        font-size: calc(25rem/16);
    }
}
h4, .h4 {
    font-size: calc(16rem/16);
    @media screen and (min-width: 360px) {
        font-size: calc(18rem/16);
    }
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
h5, .h5 {
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem/16);
    }
}
h6, .h6 {
    font-size: 1rem;
}

.link {
    color: var(--color-primary);
    text-decoration: underline;
}
