.bubble-overlay {
    background-color: var(--color-primary);
    position: fixed;
    right:0;
    bottom: 20%;
    display: block;
    box-shadow: 0 2px 20px 0 rgba(74,83,92,0.3);
    max-width: calc(370rem/16);
    color:#fff;
    z-index: 99;
    transform: translateX(280px);
    transition: transform 0.2s ease;
    @media screen and (max-width: 767px) {
        transform: translateX(85%);
        max-width: 90%;
        bottom: 15%;
        top: initial;
    }
}
.bubble-overlay.is-open {
    transform: translateX(0);
}
.bubble-overlay__icon {
    background-color:#fff;
    color: var(--color-primary);
    width: calc(100rem/16);
    height: calc(100rem/16);
    font-size: calc(50rem/16);
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: calc(-20rem/16);
    margin-top: calc(-20rem/16);
    cursor:pointer;
    border: 2px solid #fff;
    transition: border-color 0.2s ease;
    box-shadow: 5px 1px 30px 0 rgba(0,0,0,0.2);
    @media screen and (max-width: 767px) {
        width: calc(60rem/16);
        height: calc(60rem/16);
        font-size: calc(30rem/16);
        position: absolute;
        bottom:0;
        z-index: 9;
        margin-top:0;
        margin-left: calc(-20rem/16);
        margin-bottom: calc(-10rem/16);
    }
}
@media screen and (min-width: 768px) {
    .bubble-overlay__icon:hover {
        border-color: var(--color-primary);
        box-shadow: 5px 1px 30px 0 rgba(0,0,0,0.3);
    }
}
.bubble-overlay__title {
    font-family: var(--font-decorative);
    font-size: calc(24rem/16);
    letter-spacing: calc(1rem/16);
    color:#fff;
    line-height: 1.1;
    padding: calc(15rem/16) 0;
    @media screen and (max-width: 767px) {
        padding: 0;
        opacity:0;
        font-size: calc(16rem/16);
    }
}
.bubble-overlay__header {
    margin-bottom:calc(-15rem/16);
    display:flex;
}
.bubble-overlay__close {
    background: transparent;
    color:#fff;
    box-shadow: none;
    border: none;
    display: flex;
    padding: calc(15rem/16) 0;
    cursor:pointer;
    @media screen and (max-width: 767px) {
        padding: calc(15rem/16);
    }
}
.bubble-overlay__body {
    opacity:0;
    height:0;
    transition: opacity 0.2s ease;
}
.bubble-overlay.is-open .bubble-overlay__body {
    opacity:1;
    height: auto;
    padding: calc(20rem/16) calc(30rem/16) calc(30rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(15rem/16) calc(20rem/16) calc(15rem/16);
    }
}
.bubble-overlay.is-open .bubble-overlay__title{
    @media screen and (max-width: 767px) {
        opacity:1;
        padding: calc(15rem/16) calc(20rem/16);
    }
}
.bubble-overlay__wysiwyg {
    line-height: calc(20 / 16);
    font-size: calc(14rem / 16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem / 16);
    }
}
.bubble-overlay__btn, .bubble-overlay__btn:focus, .bubble-overlay__btn:active {
    border: 1px solid #fff;
    text-align: center;
    font-size: calc(15rem/16);
    padding: calc(12rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(7rem/16);
        font-size: calc(13rem/16);
        width: 80%;
        margin: 0 auto;
    }
}
@media screen and (min-width: 768px) {
    .bubble-overlay__btn:hover {
        background-color: #fff;
        color: var(--color-primary);
    }
}
