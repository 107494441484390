.benefits-slide__icon {
    font-size: calc(40rem/16);
    color: var(--color-primary);
    margin-bottom: calc(10rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(28rem/16);
    }
}
.benefits-slide__text {
    font-size: calc(14rem/16);
    font-weight: var(--font-weight-rounded-medium);
    color: var(--color-headline-default);
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
        text-align:center;
        word-break: break-word;
    }
}
.benefits-slide__img-wrapper img {
    width: calc(50rem/16);
    margin: 0 auto;
}
.benefits-slide__item + .benefits-slide__item {
    margin-top: calc(80rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(30rem/16);
    }
}
.benefits-slide {
    padding-top: calc(80rem/16);
    padding-bottom: calc(80rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(30rem/16);
        padding-bottom: calc(30rem/16);
    }
}
.benefits-slide__title {
    font-size: calc(16rem/16);
    margin-bottom: calc(40rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
        margin-bottom: calc(20rem/16);
    }
}
.benefits-slide__payment-item {
    @media screen and (max-width: 767px) {
        padding: 0 calc(12rem/16);
    }
}