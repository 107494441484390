
.payment-img {
    height:calc(20rem/16);
}

.payment-img--Visa {
    height:calc(12rem/16);
}

.payment-img--Postfinance {
    height:calc(22rem/16);
}

.payment-img--Paypal {
    height:calc(16rem/16);
}

.payment-img--Mastercard {
    height:calc(16rem/16);
}

.payment-checkout-img {
    height:calc(30rem/16);
}