.offer-teaser .teaser__pricing {
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-light);
    line-height: calc(26 / 16);
    color: var(--color-text-default);
}
.offer-teaser .teaser__icon-action {
    font-size: calc(20rem / 16);
    color: var(--color-text-default);
}

.offer-teaser .teaser__image-headline {
    text-shadow: 2px 2px 8px rgba(0,0,0,0.3);
    @media screen and (max-width: 1199px) {
        font-size:calc(25rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
    }
}
.offer-teaser__body {
    padding: calc(20rem/16);
    display: flex;
    flex-direction: column;
    height: auto;
    flex: 1 auto;
    background-color:#fff;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}
.offer-teaser__subtitle {
    font-size: calc(14rem/16);
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0;
    color: var(--color-primary);
}
.offer-teaser__title {
    color: var(--color-headline-default);
    font-size: calc(20rem/16);
    font-weight: var(--font-weight-rounded-medium);
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 1199px) {
        font-size: calc(18rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
        margin-bottom: calc(10rem/16);
    }
}
.offer-teaser__image-headline {
    font-size: calc(24rem/16);
}
.offer-teaser {
    position: relative;
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }
}
.offer-teaser:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    box-shadow: 0 2px 20px 0 rgba(74,83,92,0.2);
    z-index: -1;
    opacity:0;
    transition: opacity 0.3s ease;
}
@media screen and (min-width: 768px) {
    .offer-teaser:hover:before {
        opacity:1;
    }
}
.offer-teaser-slider .slick-list{
    margin-left: calc(-10rem/16);
    margin-right: calc(-20rem/16);
}
.offer-teaser-slider .slick-slide {
    padding-left: calc(10rem/16);
    padding-right: calc(10rem/16);
}