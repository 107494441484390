.price-tendency__arrow {
    position: absolute;
    font-size: calc(14rem/16);
    color: var(--color-grey);
    top: calc(8rem/16);
}
.price-tendency__arrow--prev {
    left:0;
}
.price-tendency__arrow--next {
    right:0;
}
.price-tendency__arrow__input {
    display: none;
}
.price-tendency__canvas{
    padding: 0 calc(10rem/16);
}