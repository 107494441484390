.weather__item {
    text-align: center;
    padding-top: calc(30rem / 16);
    padding-bottom: calc(30rem / 16);
}

.weather__icon {
    height:calc(70rem/16);
}

.weather__title {
    font-size: calc(20rem / 16);
    line-height: calc(29 / 20);
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    margin-top: calc(10rem / 16);
    margin-bottom: calc(10rem / 16);
}

.temperature__item {
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-light);
    font-size: calc(20rem / 16);
    line-height: calc(24 / 20);
    text-transform: initial;
    text-align: center;
    display: inline-block;
}

.temperature__item + .temperature__item {
    margin-left: calc(22rem / 16);
}

.temperature__item__value {
    font-size: calc(40rem / 16);
    line-height: calc(48 / 40);
}

.weather-forecast__item {
    border-bottom: 1px solid #D8D8D8;
}

.weather-forecast__col {
    position: relative;
}
.weather-forecast__col-overlay {
    position: absolute;
    top: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    padding: calc(42rem / 16);
    text-shadow: 1px 1px 5px #aeaeae;
    @media screen and (max-width: 767px) {
        padding: calc(20rem / 16);
    }
}

.weather-forecast__col__text {
    padding: calc(42rem / 16);
    text-align: right;
    @media screen and (max-width: 767px) {
        padding: calc(10rem / 16);
        text-align: left;
    }
}

.weather-forecast__col__date {
    color: #fff;
    text-transform: uppercase;
    font-size: calc(14rem / 16);
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-light);
    padding-right: calc(10rem / 16);
    @media screen and (max-width: 767px) {
        display: block;
        margin-top: 10px;
    }
}

.weather-forecast__col__day {
    font-size: calc(40rem / 16);
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    color: var(--color-headline-default);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem / 16);
        display: block;
    }
}

.weather-forecast__temp--lowest, .weather-forecast__temp--highest {
    display: inline-block;
    font-size: calc(50rem / 16);
    line-height: calc(45 / 50);
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-light);
    @media screen and (max-width: 767px) {
        font-size: calc(25rem / 16);
        line-height: calc(30 / 25);
        margin-top: 20px;
    }
}

.weather-forecast__temp--lowest {
    margin-right: calc(35rem / 16);
    opacity: 0.5;
}

.weather-forecast__temp--highest__icon, .weather-forecast__temp--lowest__icon {
    margin-right: calc(10rem / 16);
    font-size: calc(40rem / 16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem / 16);

    }
}

.weather-forecast__daytime-img {
    width: 100%;
    height: 150px;
    padding-bottom: 20px;
    @media screen and (max-width: 767px) {
        height: 80px;
        padding-bottom: 0px;
    }
}

.weather-forecast__daytime-img img {
    height: auto;
    width: 100%;
    max-width: 180px;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        max-width: 90px;
    }
}

.weather-forecast__daytime-text {
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-light);
    font-size: calc(14rem / 16);
    line-height: calc(17 / 14);
}

.weather-forecast__icon {
    font-size:calc(135rem/16);
}