@media screen and (max-width: 991px) and (min-width: 768px) {
    .navbar-main {
        width: 982px;
    }
}
.navbar.navbar-main {
    padding:0rem;
    color:#fff;
    position:absolute;
    z-index: 4;
    width:100%;
    @media screen and (max-width: 767px) {
        height: 100px;
        align-items: stretch;
        flex-direction: column;
        z-index: 11;
    }
    @media screen and (min-width: 768px) {
        align-items: start;
    }
}
.navbar.navbar-shadow:before {
    height: 180px;
    position: absolute;
    content:'';
    width:100%;
    display: block;
    left:0;
    right:0;
    top:0;
    background: linear-gradient(to bottom, rgba(0,0,0,0.79) 0%,rgba(0,0,0,0.01) 84%,rgba(0,0,0,0) 100%);
}
.navbar.navbar-main:before {
    @media screen and (max-width: 767px) {
        position: absolute;
        content: '';
        opacity: 0.83;
        width: 100%;
        height: 129px;
        display: block;
        left: 0;
        right: 0;
        top: 36px;
        pointer-events: none;
    }
}
.navbar.navbar-shadow:before {
    @media screen and (max-width: 767px) {
        background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.01) 16.62%, rgba(0,0,0,0.79) 100%);
    }
}
.navbar.navbar-shadow.is-affix:before {
    @media screen and (max-width: 767px) {
        background: none;
        display: none;
    }
}
.navbar.navbar-white {
    background:#fff;
    color: #6D6E71;
    box-shadow: -3px 8px 22px 0 rgba(0,0,0,0.08);
    position:absolute;
}
.navbar-white.is-affix {
    top:0;
} 
.navbar-white.is-affix:before {
    display: none;
}
.navbar .navbar-nav {
    height: 64px;
    z-index: 200;
}
.navbar-brand {
    width: 204px;
    height: 63px;
    margin:0;
    z-index: 1;
    padding: 0;

    @media screen and (min-width: 1599px) {
        position: absolute;
        left:0;
        right:0;
        top:0;
    }
    @media screen and (max-width: 1400px) {
        width: 180px;
        height: 48px;
    }
    @media screen and (max-width: 1199px) {
        width: 150px;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        position: relative;
        display: block;
        text-align:center;
        height: 36px;
        z-index: 199;
        background-color: var(--color-primary);
    }
}
.navbar-brand--lp {
    height:100%;
    left:0;
    right:0;
    position: absolute;
    @media screen and (max-width: 1400px) {
        width: 180px;
        height: 63px;
    }
}
.navbar-brand__img-wrapper {
    display:flex;
    justify-content:center;
    align-items:center;
}
.navbar-brand--lp .navbar-brand__img {
    height:auto;
    max-height: calc(60rem/16);
    width: auto;
    @media screen and (max-width: 767px) {
        width: auto;
        height: 30px;
    }
}

.navbar.is-affix .navbar-brand--lp .navbar-brand__img {
    max-height: calc(60rem/16);
}
.navbar-brand--lp-skiclub .navbar-brand__img-wrapper {
    align-items: self-start;
}
.navbar-brand--lp-skiclub .navbar-brand__img {
    max-height: calc(110rem/16);
    margin-top:calc(2rem/16);
}
.navbar-brand__img {
    width: 204px;
    height: 63px;

    @media screen and (max-width: 767px) {
        height: 20px;
    }
}
.navbar-brand__img--color {
    display: block;
}
.navbar-brand__img--white {
    display: none;
}
.navbar-shadow:not(.is-affix) .navbar-brand__img--color {
    display: none;
}
.navbar-shadow:not(.is-affix) .navbar-brand__img--white {
    display: block;
}
.nav-item {
    padding: 0 calc(25rem/16);
    display: inline-block;
    height: 100%;
    position: relative;
    transition: all 0.2s ease;
    background-color:transparent;
    @media screen and (max-width: 1199px) {
        padding: 0 calc(10rem/16);
    }
    @media screen and (max-width: 1400px) {
        padding: 0 calc(16rem/16);
    }
}
.nav-item--underlined-active-state {
    transition: all 0.2s ease;
    border-bottom: 3px solid transparent;
}
.nav-item--underlined-active-state.active {
    border-bottom: 3px solid var(--color-primary);
}
.nav-item.is-open {
    transition: all 0.2s ease;
    background-color: rgba(25,30,47,0.9);
    color:#fff;
}
.nav-item__menu {
    position: relative;
    padding-right: calc(30rem/16);
    @media screen and (max-width: 767px) {
        padding-right:0;
        width:calc(70rem/16);
    }
}
.nav-item__icon {
    position:static;
    padding: 0 calc(16rem/16);
}
.nav-item__user {
    padding-right: 1rem;
}
.nav-item__user .nav-link {
    @media screen and (max-width: 767px) {
        position:relative;
    }
}
.nav-item a,
.nav-item button {
    height: 100%;
}
.navbar__item-nav {
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}
.navbar__item-nav.is-nav-white, .navbar__item-nav.is-cart-overlay-open {
    @media screen and (max-width: 767px) {
        background: #ffffff;
    }
}
#main-nav .nav-link {
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    font-size: calc(14rem/16);
    line-height: calc(20/14);
    padding: 0rem;
    text-transform: uppercase;

}
.nav-item__menu:after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    background: rgba(255,255,255,0.4);
    height: auto;
    right: 0;
    top: calc(15rem/16);
    bottom: calc(11rem/16);
}
.navbar-white .nav-item__menu:after {
    background: rgba(109, 110, 113, 0.33);
}
@media screen and (max-width: 767px) {
    #main-nav .navbar-left {
        z-index: 99;
    }
}
@media screen and (min-width: 768px) {
    #main-nav .navbar-right {
        z-index: 99;
    }
}

#main-nav .navbar-left .nav-item {
    padding:0rem;
    position:static;
}
#main-nav .navbar-left .nav-item .nav-link {
    position:relative;
    padding: 0 1.2rem;

    @media screen and (max-width: 1240px) {
        padding: 0 0.7rem;
    }
}
.nav-item__piste, .nav-item__lifts {
    @media screen and (max-width: 1300px) and (min-width: 768px) {
        display:none;
    }
}
.nav-item__panomap.nav-item .icon {
    font-size:calc(21rem/16);
    position: relative;
    top: .125rem;
    min-width: calc(21rem/16);
    @media screen and (min-width: 768px) {
        font-size:calc(30rem/16);
        position: static;
        min-width: calc(30rem/16);
    }
}

.nav-item__lifts .nav-item__icon-desc, .nav-item__piste .nav-item__icon-desc {
    @media screen and (max-width: 767px) {
        padding-left:calc(5rem/16);
    }
}
.navbar-left .nav-item + .nav-item .nav-link:before,
.navbar-console-bottom .nav-item + .nav-item .nav-link:before {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    background: rgba(255,255,255,0.4);
    height: auto;
    left: 0;
    top: calc(15rem/16);
    bottom: calc(11rem/16);
}
@media screen and (max-width: 767px) {
    .navbar-console-bottom {
        transition: transform .2s;
    }
    .sticky-add-to-cart .navbar-console-bottom {
        transform: translateY(100%);
    }
}

.navbar-white .navbar-left .nav-item + .nav-item .nav-link:before {
    background: rgba(109, 110, 113, 0.33);
}
.navbar-console-bottom .nav-item.nav-item__tickets .nav-link:before {
    display: none;
}

.navbar-left .nav-item.is-open .nav-link:before, .navbar-left .nav-item.is-open + .nav-item .nav-link:before {
    display:none;
}

.nav-item .icon {
    font-size: calc(22rem / 16);
    min-width: calc(22rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(18rem / 16);
        min-width: calc(18rem/16);
        padding: 0;
    }
    @media screen and (max-width: 767px) {
        font-size: calc(21rem / 16);
        min-width: calc(21rem/16);
        min-height: calc(21rem/16);
        padding: 0;
    }
}
.nav-item .icon-shopping-cart, .nav-item .icon-search {
    font-size: calc(25rem/16);
    min-width: calc(25rem/16);
}
.nav-item .icon-panomap {
    font-size:calc(28rem/16);
}
.nav-item.nav-item__menu .icon {
    padding-right: calc(14rem/16);;
    font-size: calc(20rem/16);
    width: calc(44rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
        padding:0;
    }
}
@media screen and (min-width: 768px) {
    .nav-item__menu .nav-item__icon-desc {
        padding-left: calc(14rem/16);
    }
}


.nav-item__tickets {
    background-color: var(--color-secondary);
    padding-right:calc(24rem/16);
    color:#fff;
    @media screen and (max-width: 767px) {
        padding-left:10px;
    }
}
.nav-item__tickets .icon {
    font-size: calc(40rem/16);
}

.nav-item .icon + .nav-item__icon-desc {
    @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: .25rem;
    }

    @media screen and (min-width: 768px) {
        padding-left: calc(10rem/16);
    }
}

.nav-link__icon--skipass {
    min-width: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-right:calc(8rem/16);
        width:calc(15rem/16);
    }
}

.navbar-right .nav-link__icon--skipass + .nav-item__icon-desc  {
    padding-left: calc(10rem/16);
}
.navbar-right .nav-item__icon-desc + .icon {
    padding-left:calc(20rem/16);
}
.nav-item__icon-desc {
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    font-size: calc(14rem/16);
    @media screen and (max-width: 1199px) {
        font-size: calc(12rem/16);
        padding:0 10px;
    }
    @media screen and (max-width: 992px) {
        font-size: calc(10rem/16);
        display:none;
        padding:0;
    }
}

.nav-item__tickets .nav-item__icon-desc {
    display:block;
}
.nav-item__menu .nav-item__icon-desc {
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
}
.navbar-right .nav-item {
    padding: 0 1.2rem;
    @media screen and (max-width: 1400px) {
        padding: 0 calc(13rem/16);
    }
    @media screen and (max-width: 1199px) {
        padding: 0 calc(8rem/16);
    }
}
.nav-item__user-thumb {
    border-radius: 50%;
    width: calc(36rem/16);
    height: calc(36rem/16);
    overflow:hidden;
    border: 2px solid #fff;
    box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.75);
}

.nav-item__login-wrapper .default-user-icon--menubar{
    font-size: calc(28rem/16);
    min-width: calc(28rem/16);
}

.nav-item__login-wrapper .icon-user__text{
    padding-left: .625rem;
}

.notification-wrapper{
    position: relative;
}

.navbar-right .nav-item__language .icon {
    padding-left: 0px;
    margin-left:10px;
    font-size:calc(12rem/16);
}
.navbar-right .nav-item__language .nav-item__icon-desc, .navbar-right .nav-item__tickets .nav-item__icon-desc {
    @media screen and (max-width: 992px) {
        display:block;
    }
}
.nav-item__user-notification, .nav-item__notification {
    height: calc(17rem/16);
    width: calc(17rem/16);
    background-color: var(--color-secondary);
    position: absolute;
    border-radius: 15px;
    bottom: 0.6rem;
    text-align:center;
    right: -0.4rem;
    font-size: calc(10rem/16);
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    display:flex;
    justify-content:center;
    align-items:center;
    color:#fff;
}
.nav-item__notification {
    right:-13px;
    bottom: 11px;
}
.notification-bounce {
    animation: bounce 0.9s;
}
@keyframes bounce {
    0% { transform: scale(1.1); opacity: 1 }
    30% { transform: scale(1.6); opacity: .7; }
    40% { transform: scale(0.6); opacity: 1 }
    60% { transform: scale(1.4); opacity: .8 }
    80% { transform: scale(0.8); opacity: 1 }
    100% { transform: scale(1) }
}
.nav-link .icon-skipass + .nav-item__icon-desc {
    @media screen and (max-width: 1500px) {
        display:none;
    }
}
.navbar-nav.is-nav-white .nav-link:not(.nav-item__tickets .nav-link) {
    color: var(--bg-darkgrey);
}

.navbar-nav.is-nav-white .nav-item__menu:after, .navbar-nav.is-cart-overlay-open .nav-item__menu:after {
    background: rgba(0, 0, 0, .4);
}
.navbar-nav .nav-item__user-notification {
    color:#fff;
    @media screen and (max-width: 767px) {
        right: -6px;
        bottom: -.3rem;
    }
}


/* navbar console */
.navbar-console-bottom.nav {
    position:fixed;
    bottom:0px;
    width:100%;
    z-index:1;
    background-color:var(--bg-darkgrey);
    transition: all 0.3s ease;
}

.navbar-console-bottom .nav-item__icon-desc {
    display:block;
}
.navbar-console-bottom .nav-item {
    padding:0;
}
.nav-item-console__language .nav-item__icon-desc {
    @media screen and (max-width: 767px) {
        font-size:calc(14rem/16);
    }
}
.nav-item-console__language .icon {
    @media screen and (max-width: 767px) {
        font-size:calc(11rem/16);
        margin-left:calc(12rem/16);
        transform: scaleY(-1) rotate(90deg);
    }
}
.navbar-nav .nav-item-console__language .dropdown-menu.show {
    @media screen and (max-width: 767px) {
        position:absolute;
        bottom: 62px;
        top:auto;
        width:100vw;
        margin:0;
        border-radius:0;
        padding-top:30px;
        padding-bottom:30px;
        background: linear-gradient(180deg,rgba(25,30,48,.9),rgba(0,0,0,.9));
        color:#fff;
    }
}
.nav-item-console__language .dropdown-menu .dropdown-item {
    @media screen and (max-width: 767px) {
        color:#fff;
        padding:calc(10rem/16) calc(30rem/16);
    }
}
.nav-item-console__language.show .icon{
    @media screen and (max-width: 767px) {
        transform: scaleY(1) rotate(90deg);
    }
}
.nav-link__icon {
    @media screen and (max-width: 767px) {
        font-size: calc(28rem/16);
    }
}
.nav-link__icon.icon-weather + .nav-item__icon-desc {
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
        margin-left:calc(3rem/16);
    }
}



#main-nav.navbar-btob.navbar-white .nav-item .nav-link{
    text-transform: none;
}

.is-main-nav-open {
    background-color:#fff;
}

.navbar-white + .btn-back-to-zermatt {
    background: rgba(255,255,255,0.8);
}
.navbar-white + .btn-back-to-zermatt:hover {
    background:var(--color-primary);
}
.navbar.is-affix + .btn-back-to-zermatt {
    z-index: 2;
}

.navbar.navbar-main--hidden-nav  {
    @media screen and (max-width: 767px) {
        height: auto;
    }
}
.navbar__back {
    color: var(--color-headline-default);
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    padding: calc(10rem/16) calc(30rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        position: absolute;
        padding: calc(10rem/16);
    }
}
.navbar__back .icon {
    margin-right: calc(5rem/16);
    font-size: calc(10rem/16);
}
.navbar__claim {
    color: var(--color-primary);
    text-transform: uppercase;
    font-size: calc(14rem/16);
    font-weight: var(--font-weight-rounded-medium);
    padding: calc(10rem/16) calc(30rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar--shop.navbar.navbar-main {
    @media screen and (max-width: 767px) {
        height: calc(50rem/16);
    }
}
.navbar--shop .navbar-brand {
    position: absolute;
    left:0;
    right:0;
    @media screen and (max-width: 767px) {
        position: absolute;
        background-color: transparent;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
    }
}
.navbar.navbar--shop:before {
    @media screen and (max-width: 767px) {
        height: calc(50rem/16);
        top:0;
    }
}
.navbar--shop.navbar .navbar-nav {
    @media screen and (max-width: 767px) {
        height: calc(50rem/16);
        display: flex;
        justify-content: center;
    }
}