.ticket-shop__footer-wrapper:not(.is-affix) {
    @media screen and (min-width: 767px) {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .ticket-shop__footer {
        height: calc(63rem / 16);
    }

    .ticket-shop__footer .btn {
        height: calc(63rem / 16);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .is-affix .ticket-shop__footer {
        box-shadow: 0 -3px 44px 0 rgba(0, 0, 0, .2);
        border: none !important;
    }

    .ticket-booking__affix {
        transition: transform 0.1s ease;
    }
    .ticket-booking__affix.is-affix {
        bottom: 64px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom:10px;
    }
}

