.ticket-configuration__box {
    border: 1px solid var(--color-grey-light);
    box-shadow: 5px 1px 11px 0 rgba(0,0,0,0.06);
    padding: 1rem 1.25rem;
    position: relative;
    line-height: 1.1;
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}
.ticket-configuration__box-header {
    font-size: calc(16rem/16);
    letter-spacing: calc(1rem/16);
    color: var(--color-headline-default);
    font-weight: var(--font-weight-rounded-medium);
    text-transform: uppercase;
    padding: calc(20rem/16) 0;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        letter-spacing:0;
    }
}
.ticket-configuration__box-footer {
    font-size: calc(16rem/16);
    letter-spacing: calc(1rem/16);
    color: var(--color-headline-default);
    font-weight: var(--font-weight-rounded-medium);
    text-transform: uppercase;
    padding: calc(20rem/16) 0;
    border-top: calc(1rem/16) solid var(--color-headline-default);
    margin-top: calc(20rem/16);
}
.ticket-configuration__table {
    padding-top: calc(24rem/16);
    margin-top: calc(20rem/16);
    border-top: calc(1rem/16) solid var(--color-headline-default);
    font-size: calc(14rem/16);
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
        padding-top: calc(10rem/16);
        font-size: calc(11rem/16);
        line-height:1.2;
    }
}
.ticket-configuration__table-item {
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16);
        background-color: #f5f5f5;
    }
}
.ticket-configuration__table-item + .ticket-configuration__table-item {
    @media screen and (max-width: 767px) {
        margin-top: calc(10rem/16);
    }
}
.ticket-configuration__table-header {
    font-weight: var(--font-weight-rounded-bold);
    color: var(--color-headline-default);
    margin-bottom: calc(20rem/16);
}
.ticket-configuration__box--edit {
    box-shadow: 0 0 13px 0 rgba(0, 172, 225, 0.32);
    border: 1px solid var(--color-primary);
    @media screen and (max-width: 767px) {
        padding-top: calc(25rem/16);
    }
}
.ticket-configuration__box--edit .ticket-configuration__edit-btn {
    color: var(--color-primary);
}
.ticket-configuration__box__headline {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
    background: var(--color-primary);
    display: inline-flex;
    color:#fff;
    text-transform: uppercase;
    font-weight: var(--font-weight-rounded-medium);
    padding: calc(5rem/16) calc(10rem/16);
}
.ticket-configuration__box + .ticket-configuration__box {
    margin-top: calc(10rem/16);
}
.ticket-configuration__title {
    font-size: calc(16rem/16);
    text-transform: uppercase;
    font-weight: var(--font-weight-rounded-medium);
    color: var(--color-headline-default);
    margin-bottom: calc(5rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.ticket-configuration__title--big {
    font-size: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(18rem/16);
    }
}
.ticket-configuration__subtitle {
    font-size: calc(16rem/16);
    color: var(--color-grey);
    font-weight: var(--font-weight-rounded-medium);
}
.ticket-configuration__small-title {
    font-size: calc(14rem/16);
    color: var(--color-grey);
    font-weight: var(--font-weight-rounded-medium);
}
.ticket-configuration__header {
    text-align:center;
    min-height: calc(60rem/16);
    margin-bottom: calc(30rem/16);
    @media screen and (max-width: 767px) {
        min-height: auto;
    }
}
.ticket-configuration__checkbox-wrapper {
    display: flex;
    align-items:center;
    box-shadow: 5px 3px 12px 0 rgba(0,0,0,.01), 5px 3px 20px 0 rgba(0,0,0,.03);
    border: 1px solid #e7e7e7;
    padding: calc(10rem/16);
}
.ticket-configuration__checkbox-wrapper .custom-checkbox__box {
    width: calc(23rem/16);
    height: calc(23rem/16);
    margin-top:0;
}
.ticket-configuration__list {
    font-weight: var(--font-weight-rounded-medium);
    color: var(--color-headline-default);
    @media screen and (min-width: 768px) {
        max-height: calc(233rem/16);
        overflow-y: scroll;
        padding-right: calc(5rem/16);
    }
}
@media screen and (min-width: 768px) {
    .ticket-configuration__list::-webkit-scrollbar {
        width: calc(4rem/16);
    }
    .ticket-configuration__list::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    }
    .ticket-configuration__list::-webkit-scrollbar-thumb {
        background-color: #E5E5E5;
        outline: 1px solid #8E8E8E;
    }
}
.ticket-configuration__list--edit .ticket-configuration__list-item:not(.active) {
    opacity:0.6;
}
.ticket-configuration__edit-btn, .ticket-configuration__delete-btn {
    font-size: calc(18rem/16);
    color: var(--color-grey);
    @media screen and (max-width: 767px) {
        padding: 0 calc(10rem/16);
    }
}
.ticket-configuration__currency-switch {
    position: absolute;
    max-width: calc(110rem/16);
}
.ticket-configuration__currency-switch__select-wrapper {
    @media screen and (max-width: 767px) {
        position: absolute;
        width: 100%;
        top:0;
        opacity:0;
    }
}
.ticket-configuration__currency-switch__label {
    @media screen and (max-width: 767px) {
        text-decoration: underline;
    }
}
.ticket-configuration__legend {
    clear:both;
    padding-top: calc(10rem/16);
    padding-bottom: calc(20rem/16);
    font-size: calc(10rem/16);
    color: var(--color-grey);
    text-transform: uppercase;
    font-weight: var(--font-weight-rounded-medium);
    @media screen and (max-width: 767px) {
        font-size: calc(8rem/16);
    }
}
.ticket-configuration__legend-status {
    display: inline-flex;
    width: calc(11rem/16);
    height: calc(11rem/16);
    border-radius: 50%;
}
.ticket-configuration__legend-status--lightblue { background-color: var(--color-season-light-blue);}
.ticket-configuration__legend-status--middleblue { background-color: var(--color-season-middle-blue);}
.ticket-configuration__legend-status--blue { background-color: var(--color-season-blue);}
.ticket-configuration__legend-status--darkblue { background-color: var(--color-season-dark-blue);}

.ticket-configuration__datepicker-text {
   margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
.ticket-configuration__datepicker-text__day {
    color: var(--color-primary);
    font-size: calc(60rem/16);
    line-height:1;
    font-weight: var(--font-weight-rounded-medium);
    @media screen and (max-width: 767px) {
        font-size: calc(30rem/16);
    }
}
.ticket-configuration__datepicker-text__label {
    text-transform:uppercase;
    color: var(--color-headline-default);
    font-size: calc(16rem/16);
    font-weight: var(--font-weight-rounded-medium);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.ticket-configuration__datepicker-text__day-name  {
    color: var(--color-grey);
}
.ticket-configuration__completed {
    padding: calc(45rem/16) calc(70rem/16);
    text-align: center;
    @media screen and (max-width: 767px) {
        padding: calc(45rem/16) 0;
    }
}
.ticket-configuration__completed__icon {
    color: var(--color-primary);
    font-size: calc(70rem/16);
    margin-bottom: calc(30rem/16);
}
.ticket-configuration__bottom-btn {
    @media screen and (max-width: 767px) {
        position: fixed;
        bottom:0;
        left:0;
        right: 0;
        width: 100%;
    }
}
.ticket-configuration__availability {
    color: var(--color-headline-default);
    font-family: var(--font-rounded-bold);
    text-transform: uppercase;
    text-align: right;
    margin-bottom: calc(10rem/16);
    font-weight: var(--font-weight-rounded-bold);
}