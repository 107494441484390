.highlight-box {
    background:#f9f9f9;
    border-left:calc(2rem/16) solid var(--color-pastel-green);
    padding:calc(20rem/16);
    position:relative;
}
.highlight-box__badge {
    position:absolute;
    right:calc(25rem/16);
    top:calc(calc(35rem/16) * -1);
    width:calc(90rem/16);
    height:calc(90rem/16);
    padding:calc(8rem/16);
}
@media (min-width: 768px) {
    .highlight-box {
        padding:calc(40rem/16);
    }
}