
.vjs-custom-skin.vjs-playing:before {
    content: '';
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.48) 100%);
    position: absolute;
    width: 100%;
    height: 20%;
    bottom: 0;
    display: block;
    left: 0;
    right: 0;
    z-index: 1;
}

.vjs-custom-skin .vjs-control-bar {
    background-color: transparent;
    position: absolute;
    bottom: calc(35rem / 16);
    z-index: 2;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    left: 0;
    @media screen and (max-width: 767px) {
        margin-left: calc(15rem / 16);
        width: 90%;
        bottom: calc(10rem / 16);
        position: absolute;
        top: 100%;
        margin-top: calc(40rem / 16);

    }
}

.vjs-fullscreen .vjs-control-bar {
    z-index: -1;
}

.vjs-custom-skin.video-js .vjs-progress-holder {
    height: calc(2rem / 16);
}

.vjs-custom-skin.video-js .vjs-slider {
    background-color: var(--color-primary);
}

.vjs-custom-skin.video-js .vjs-play-progress:before {
    font-size: .9em;
    position: absolute;
    right: -.5em;
    top: calc(-5rem / 16);
    z-index: 1;
    background-color: #272727;
    width: calc(13rem / 16);
    height: calc(13rem / 16);
    content: '';
    border-radius: 50%;
    border: calc(1rem / 16) solid #fff;
}

.vjs-custom-skin .vjs-volume-panel {
    display: none;
}

.vjs-custom-skin .vjs-play-control {
    position: absolute;
    top: calc(30rem / 16);
    width: calc(25rem / 16);
    height: calc(25rem / 16);
    border: calc(1rem / 16) solid #fff;
    border-radius: 50%;
    font-size: calc(13rem / 16);
    margin-left: calc(6rem / 16);
}

.vjs-custom-skin .vjs-fullscreen-control {
    position: absolute;
    right: calc(6rem / 16);
    top: calc(30rem / 16);
    width: calc(25rem / 16);
    height: calc(25rem / 16);
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: calc(8rem / 16);
}

.vjs-custom-skin .vjs-time-control {
    display: block;
    position: absolute;
    right: 0;
    bottom: calc(30rem / 16);
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    font-size: calc(14rem / 16);
}

.vjs-custom-skin .vjs-remaining-time {
    display: none;
}

.vjs-custom-skin .vjs-current-time {
    right: calc(50rem / 16);
}

.vjs-custom-skin .vjs-time-divider {
    right: calc(37rem / 16);
    height: calc(30rem / 16);
}

.vjs-custom-skin .vjs-time-divider:before {
    content: '|';
    display: block;
    position: relative;
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-light);
}

.vjs-custom-skin .vjs-time-divider span {
    display: none;
}

.vjs-custom-skin.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    opacity: 1;
}

.vjs-custom-skin .vjs-play-control .vjs-icon-placeholder, .vjs-custom-skin .vjs-icon-pause, .vjs-custom-skin .vjs-play-control.vjs-playing .vjs-icon-placeholder,
.vjs-custom-skin .vjs-fullscreen-control .vjs-icon-placeholder, .vjs-custom-skin .vjs-icon-fullscreen-enter, .vjs-custom-skin .vjs-icon-placeholder, .vjs-custom-skin .vjs-icon-placeholder:before {
    font-family: iconfont;
}

.vjs-custom-skin .vjs-big-play-button .vjs-icon-placeholder:before, .vjs-custom-skin .vjs-play-control .vjs-icon-placeholder:before, .vjs-custom-skin .vjs-icon-play:before {
    content: var(--icon-play);
    font-size: calc(14rem / 16);
    line-height: 1.5rem;
    font-family: iconfont;
}

.vjs-custom-skin .vjs-play-control.vjs-playing .vjs-icon-placeholder:before, .vjs-custom-skin .vjs-icon-pause:before {
    content: var(--icon-pause);
    font-size: calc(12rem / 16);
    line-height: 1.5rem;
}

.vjs-custom-skin .vjs-fullscreen-control .vjs-icon-placeholder:before, .vjs-custom-skin .vjs-icon-fullscreen-enter:before {
    content: var(--icon-fullscreen);
}

.vjs-custom-skin .vjs-big-play-button {
    font-size: calc(20rem / 16);
    line-height: 100px;
    height: 100px;
    width: 100px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    right: 0;
    left: 0;
    border: 0.2rem solid #fff;
    background: rgba(25,30,48,.9);
    border-radius: 50%;
}
.vjs-custom-skin.video-js:hover .vjs-big-play-button {
    background: rgba(25,30,48,.7);
}

.vjs-custom-skin .vjs-big-play-button .vjs-icon-placeholder:before {
    font-size: 2.5rem;
    line-height: 1;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-left:3px;
}

.vjs-webcam-skin .vjs-control-bar {
    width: calc(800rem / 16);
    bottom: calc(200rem / 16);
    margin-left: calc(100vw * (-0.3333) + ((100vw - 102.5rem) / 2));

    @media screen and (max-width: 1640px) and (min-width: 1200px) {
        margin-left: -50%;
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        margin-left: calc(100vw * (-0.17) + calc(15rem / 16));
        bottom: calc(80rem / 16);
        width: 80%;
    }
    @media screen and (max-width: 767px) {
        margin-left: calc(15rem / 16);
        width: 90%;
        bottom: calc(10rem / 16);
        position: absolute;
        top: 100%;
        margin-top: calc(40rem / 16);

    }
}

.vjs-webcam-skin.vjs-playing:before, .vjs-webcam-skin.vjs-paused:before {
    background: transparent;
}

.vjs-webcam-skin .vjs-live-control .vjs-live-display {
    position: relative;
    height: calc(20rem / 16);
    line-height: 20px;
    width: auto;
}

.vjs-webcam-skin .vjs-live-control {
    display: block !important;
    position: absolute;
    bottom: calc(20rem / 16);
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    font-size: calc(14rem / 16);
    text-align: left;
    margin-left: calc(9rem / 16);
}

.vjs-webcam-skin .vjs-live-control .vjs-live-display:after {
    height: calc(6rem / 16);
    width: calc(6rem / 16);
    background-color: #d0021b;
    display: block;
    content: "";
    position: absolute;
    border-radius: 50%;
    right: calc(17rem / 16);
    top: 0;
    line-height: 20px;
    margin-top: calc(3rem / 16);
}

.vjs-webcam-skin .vjs-play-control.vjs-playing .vjs-icon-placeholder:before, .vjs-webcam-skin .vjs-icon-pause:before {
    line-height: calc(40 / 12);

}

.vjs-webcam-skin .vjs-big-play-button .vjs-icon-placeholder:before, .vjs-webcam-skin .vjs-play-control .vjs-icon-placeholder:before, .vjs-webcam-skin .vjs-icon-play:before {
    font-size: calc(15rem / 16);
    line-height: calc(40 / 15);
}

.vjs-webcam-skin .vjs-play-control {
    top: calc(37rem / 16);
    width: calc(40rem / 16);
    height: calc(40rem / 16);
}

.vjs-webcam-skin .vjs-fullscreen-control {
    top: calc(37rem / 16);
    width: calc(40rem / 16);
    height: calc(40rem / 16);
    font-size: calc(13rem / 16);
}

.video-js .vjs-play-control.vjs-ended .vjs-icon-placeholder:before, .vjs-icon-replay:before {
    content: var(--icon-play);

}

.vjs-show-mute-button .vjs-volume-panel {
    display: flex;
    //display: none;
    position: absolute;
    top: calc(20rem/16);
    right: calc(40rem/16);
}
.vjs-show-mute-button .vjs-volume-panel:hover {
    width: auto !important;
}
.vjs-show-mute-button .vjs-volume-control {
    display: none;
}
.vjs-show-mute-button .vjs-mute-control .vjs-icon-placeholder {
    font-size: calc(15rem / 16);
}
.vjs-show-mute-button .vjs-mute-control.vjs-vol-0 .vjs-icon-placeholder:before {
    content: var(--icon-mute) !important;
}
.vjs-show-mute-button .vjs-mute-control.vjs-vol-3 .vjs-icon-placeholder:before {
    content: var(--icon-unmute) !important;
}
