.ticket {
    cursor:pointer;
    background-color: #fff;
    position: relative;
    overflow:hidden;
    color: var(--color-headline-default);
    transform:translateZ(0);
}
.ticket__header {
    position:relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--font-rounded-bold);
    font-weight: var(--font-weight-rounded-bold);
    text-align: center;
    font-size: 1rem;
    height: calc(110rem/16);
    border: 1px solid #E7e7e7;
    border-bottom: 0;
    @media screen and (max-width: 767px) {
        height:auto;
        padding-top:calc(10rem/16);
        padding-bottom:calc(10rem/16);
    }
}
.ticket__line {
    height: 1px;
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.ticket__line:before {
    position: absolute;
    top: 0;
    content:'';
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, transparent 50%, #8E8E8E 50%);
    background-size: calc(10rem/16) 100%;
}
.ticket__price-wrapper {
    padding: calc(10rem/16);
    @media screen and (max-width: 767px) {
        padding:0;
    }
}
.ticket__additional-options {
    padding: 0 calc(10rem/16) calc(8rem/16);
    font-size: calc(11rem/16);
    @media screen and (max-width: 767px) {
        padding: 0 0 calc(12rem/16);
    }
}
.ticket__additional-options-item {
    display: grid;
    grid-template-columns: 1fr calc(80rem/16);
    column-gap: calc(5rem/16);
}
.ticket__additional-options-price {
    text-align: right;
}
.ticket__additional-options-name {
    font-family: var(--font-default-bold);
}
.ticket__person {
    width:100%;
    margin-bottom: calc(10rem/16);
    padding: 0 .9375rem;
}
.ticket:not(.ticket--unstyled) .ticket__header:before, .ticket:not(.ticket--unstyled) .ticket__header:after {
    content:'';
    display:block;
    position:absolute;
    width:calc(15rem/16);
    height:calc(15rem/16);
    border-radius: 50%;
    background: #fff;
    z-index: 1;
    border: 1px solid #E7e7e7;
}


.ticket__header:before {
    left: calc(-7.5rem/16);
    bottom: calc(-7.5rem/16);
}
.ticket__header:after {
    right:  calc(-7.5rem/16);
    bottom:  calc(-7.5rem/16);
}

.ticket__body {
    border: 1px solid #e7e7e7;
    border-top:none;
    border-bottom:none;
    padding: 0 calc(15rem / 16);
    min-height: calc(180rem/16);
    font-size: calc(12rem/16);
    @media screen and (max-width: 767px) {
        height:auto;
        min-height: calc(150rem/16);
    }
}
.ticket__body--small {
    height: calc(120rem/16)
}
.ticket.has-error .ticket__header, .ticket.has-error .ticket__body,.ticket.has-error .ticket__header:before,.ticket.has-error .ticket__header:after  {
    border-color:red;
}
.ticket__footer {
    border-top:none;
}
.ticket__btn {
    font-size: calc(15rem / 16);
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-headline-default);
}
.ticket__btn.active {
    background-color: var(--color-primary);
}
.has-error .ticket__btn {
    background-color:var(--color-secondary-light);
}
.ticket__time-range {
    font-size: calc(12rem / 16);
    text-align: center;
    padding: calc(15rem / 16) 0 calc(8rem/16);
}
.ticket__price {
    font-size:calc(20rem/16);
    font-weight:var(--font-weight-rounded-medium);
    line-height:1;
}
.ticket__discount__text {
    color: var(--color-primary);
}
.ticket__previous-price {
    color:var(--color-primary);
    line-height:1;
    font-family: var(--font-default-bold);
}
.has-error .ticket__previous-price {
    color:var(--color-secondary-light);
}
.ticket__price-info {
    font-size: calc(16rem/16);

}
.ticket__category {
    font-size: calc(14rem/16);
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-medium);
    color:var(--color-primary);
    text-align:center;
    text-transform:uppercase;
}
.has-error .ticket__category {
    color:var(--color-secondary-light);
}
.ticket__overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% - calc(52rem/16));
    background: linear-gradient(180deg,rgba(25,30,48,.9),rgba(0,0,0,.9));
    font-size: calc(18rem/16);
    color:#fff;
}
.ticket__close {
    position: absolute;
    right: 0;
    top: 0;
    padding: calc(10rem/16);
}
.ticket--has-overlay {
    border:2px solid transparent;
}
.ticket--has-overlay:after {
    content:'';
    display:block;
    position: absolute;
    left:0;
    right:0;
    top:0;
    background-color:rgba(255,255,255,0.8);
    width:100%;
    height:100%;
    opacity:0;
    z-index: -99;
    transition: opacity 0.5s ease;
}
.ticket-overlay--edit, .ticket-overlay--disabled {
    position:relative;
}
.ticket-overlay--disabled:after {
    opacity: 1;
    z-index: 1;
}
.ticket-overlay--edit:after {
    display: none;
    transition: all 0.2s ease;
}
.ticket-overlay--edit {
    transition: all 0.2s ease;
    border:2px solid var(--color-primary);
}
.ticket-ordered .ticket__header, .ticket-ordered .ticket__body{
    height:auto;
}
.ticket-ordered .ticket__header {
    padding: calc(10rem/16) 0;
}
.ticket-ordered .ticket__footer {
    border-color:var(--bg-lightgrey);
}
.ticket-accordion__overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,rgba(25,30,48,.9),rgba(0,0,0,.8));
    font-size: 1.125rem;
    color: #fff;
}
.ticket-accordion__overlay-text {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: var(--font-default-bold);
    font-size:calc(30rem/16);
    pointer-events:none;
    @media screen and (max-width: 767px) {
        font-size:calc(18rem/16);
    }
}
.ticket-accordion__overlay-text .icon {
    color:var(--color-primary);
}
.ticket__title {
    width: 100%;
    text-transform: uppercase;
}