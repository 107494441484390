.gewinnspiel-frage {
    position: relative;
    padding:.75rem 1.5rem;
    background-color: #F7F9FA;
    box-shadow: 0px 0px 13px 0 rgba(0,0,0,.06);
    margin: 1rem -1.125rem;

    @media screen and (min-width: 768px) {
        padding:2rem 3rem 3rem 3rem;
        margin:0;
    }
}

.gewinnspiel-frage.has-error {
    background:rgba(238,46,34,.125);
}
.gewinnspiel-frage.has-error .parsley-errors-list.filled {
    display: none;
}








/* sponsors */
.gewinnspiel-sponsor {
    position: relative;
    padding:1.5rem;

    @media screen and (min-width: 768px) {
        padding:2rem 1rem;
    }
}