.social-teaser {
    position: relative;
    box-shadow: 6px 5px 38px -5px rgba(0, 0, 0, 1);
}
.social-teaser:hover img {
    transform: scale(1.1);
}
.social-teaser img {
    transform: scale(1);
    -webkit-backface-visibility: hidden;
    transition: all 0.4s ease-in-out;
}
.social-teaser:after {
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    right:0;
    width:100%;
    height:100%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
}
.social-teaser .teaser__overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: calc(10rem / 16);
    padding-left: calc(10rem / 16);
    padding-right: calc(10rem / 16);
    z-index: 2;
    @media screen and (min-width: 1200px) {
        padding-bottom: calc(19rem / 16);
        padding-left: calc(19rem / 16);
        padding-right: calc(19rem / 16);
    }
}
.social-teaser__icon {
    font-size: calc(14rem/16);
    margin-right: calc(10rem/16);
    @media screen and (min-width: 1200px) {
        font-size:calc(18rem/16);
    }
    @media screen and (min-width: 1500px) {
        font-size:calc(24rem/16);
    }
}
.social-teaser__user-name {
    font-size: calc(10rem/16);
    line-height: calc(16/20);
    padding-left: calc(10rem/16);
    font-family:var(--font-rounded-bold);
    font-weight:var(--font-weight-rounded-medium);
    position: relative;
    display: inline-block;
    @media screen and (min-width: 1200px) {
        font-size:calc(13rem/16);
        line-height:calc(19/20);
        padding-left: calc(12rem/16);
    }
    @media screen and (min-width: 1500px) {
        font-size:calc(18rem/16);
        line-height:calc(24/20);
        padding-left: calc(17rem/16);
    }
}
.social-teaser__user-name:before {
    content: "|";
    display: inline-block;
    margin-right: .3125rem;
    margin-left:calc(5rem/16);
    position: absolute;
    top: 0;
    left: 0;
    font-weight:var(--font-weight-rounded-light);
}
.social-teaser__date {
    font-family:var(--font-rounded-light);
    font-size: calc(10rem/16);
    line-height: calc(16/20);
    font-weight:var(--font-weight-rounded-light);
    @media screen and (min-width: 1200px) {
        font-size:calc(13rem/16);
        line-height:calc(19/20);
    }
    @media screen and (min-width: 1500px) {
        font-size:calc(18rem/16);
        line-height:calc(24/20);
    }
}
