.wysiwyg {
    line-height: calc(26 / 16);
    hyphens: auto;

    /*table responsive*/
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 1.5rem;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}
.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    color: var(--color-headline-default);
    margin-top: 1.5rem;
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    font-weight: normal;
}

.wysiwyg h1:first-child,
.wysiwyg h2:first-child,
.wysiwyg h3:first-child,
.wysiwyg h4:first-child,
.wysiwyg h5:first-child,
.wysiwyg h6:first-child {
    margin-top: 0;
}

.wysiwyg ul,
.list-default {
    text-indent: -1.75rem;
    padding-left: 1.75rem;
    list-style-type: none;
}
.wysiwyg ul > li + li,
.list-default > li + li {
    margin-top: 3px;
}
.wysiwyg ul > li:before,
.list-default > li:before {
    content: '';
    display: inline-block;
    width: .5rem;
    height: .5rem;
    background: var(--color-primary);
    border-radius: 50%;
    padding-left: 0;
    margin-right: 1rem;
    list-style-type: none;
}

.wysiwyg ul strong {
    font-size:calc(18rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(16rem/16);
    }
}
.wysiwyg-area--centered {
    text-align: center;
}