.img-slide {
    position: relative;
}
.img-slide__content {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
}

.img-slide__title {
    font-size: 60px;
    text-shadow: 0 0 12px rgba(0, 0, 0, 0.81);
    font-family: var(--font-decorative);
    letter-spacing:var(--font-letterspacing-decorative);
    font-weight: normal;
    line-height: 1.2;
}
@media screen and (min-width: 768px) {
    .img-slide__title {
        font-size: 100px;
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    }
}