
.slope-icon--red.icon {
    color: #c8281e;
    font-size:calc(20rem/16);
}

.slope-icon--blue.icon {
    color: #215f9d;
    font-size:calc(20rem/16);
}

.slope-icon--black.icon {
    color:#000;
    font-size:calc(20rem/16);
}

.slope-icon--yellow.icon {
    color: #eed448;
    font-size:calc(20rem/16);
}

.slope-icon--green.icon {
    color: #b4f072;
    font-size:calc(20rem/16);
}

.slope-icon--violet.icon {
    color: #972fee;
    font-size:calc(20rem/16);
}