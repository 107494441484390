.animated-list__item {
    @media screen and (min-width: 768px) {
        opacity:0;
        transition: all 0.5s ease;
        transform:translateY(-50px);
    }
}
.animated-list__item.inview-reveal {
    opacity:1;
    transform:translateY(0px);
}