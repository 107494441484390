
.teaser-badge {
    position: absolute;
    top: calc(10rem/16);
    left: 0;
    background: var(--color-primary);
    color: #ffffff;
    font-size: calc(12em / 16);
    z-index: 2;
}

.teaser-badge--attention {
    background: transparent;
    margin-left: calc(-2rem/16);
    margin-top: calc(-2rem/16);
    @media screen and (max-width: 767px) {
        margin-left: calc(0rem/16);
        margin-top: calc(0rem/16);
    }
}

.teaser-badge--attention .teaser-badge__icon {
    background: var(--color-primary);
    width: calc(80rem / 16);
    height: calc(34rem / 16);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding:0 calc(10rem/16);
    font-family:var(--font-default);

    @media screen and (max-width: 767px) {
        width: calc(60rem / 16);
        height: calc(30rem / 16);
    }
}

.teaser-badge__icon {
    font-size: calc(27em / 16);
}

.teaser-badge__text {
    font-size: calc(14rem/16);
    text-shadow: 2px 2px 8px rgba(0,0,0,0.3);
    padding-left:calc(5rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        padding-top:calc(5rem/16);
    }
}
