
.menu-icon__wrapper {
    position:relative;
    overflow:hidden;
    width:calc(50rem/16);
    height:calc(32rem/16);

    @media screen and (min-width: 768px) {
        height:calc(50rem/16);
    }
}

.menu-icon {
    position: absolute;
    width: calc(25rem/16);
    height: calc(17rem/16);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (min-width: 768px) {
        width: calc(30rem/16);
        height: calc(20rem/16);
    }
}

.menu-icon__line {
    position: absolute;
    left: 0;
    background-color: #fff;
    height: calc(1.5rem/16);
    width: calc(22rem/16);
    border-radius: calc(3rem/16);
    pointer-events: none;
}
.navbar-white .menu-icon__line {
    background-color: var(--color-text-default);
}
.menu-icon__line-1, .menu-icon__line-3 {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.menu-icon__line-1 {
    top: 0;

    @media screen and (max-width: 767px) {
        width: calc(17 / 25 * 100%);
    }
}

.menu-icon__line-2 {
    top: 0;
    bottom: 0;
    margin: auto;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.menu-icon__line-3 {
    bottom: 0;

    @media screen and (max-width: 767px) {
        width: calc(21 / 25 * 100%);
    }

}

.is-main-nav-open .menu-icon__line-1 {
    opacity: 0;
    transform: translate3d(-17px, 0, 0);
    transition: all .2s ease-in-out;
}

.is-main-nav-open .menu-icon__line-2 {
    transform: rotate(45deg);
    transition: transform .2s ease-in-out;
}

.is-main-nav-open .menu-icon__line-3 {
    opacity: 0;
    transform: translate3d(17px, 0, 0);
    transition: all .2s ease-in-out;
}

.is-main-nav-open .menu-icon__line-2:before {
    transform: rotate(-90deg);
    transition: transform .2s ease-in-out;
}

.is-main-nav-open .menu-icon__line {
    width:calc(30rem/16);
}

.menu-icon__line-2 {
    top: 0;
    bottom: 0;
    margin: auto;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
}
.menu-icon__line-2:before {
    content: '';
    position: absolute;
    width: 100%;
    height: calc(1.5rem/16);
    background-color: #fff;
    border-radius: calc(3rem/16);
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;

    @media screen and (min-width: 768px) {
        width: calc(30rem/16);
    }
}
.navbar-white .menu-icon__line-2:before {
    background-color: var(--color-text-default);
}
.is-main-nav-open .menu-icon__line {
    background-color:var(--color-text-default);
}
.is-main-nav-open .menu-icon__line-2:before {
    background-color:var(--color-text-default);
}