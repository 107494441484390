input[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}
select option:disabled {
    color: rgba(0,0,0,0.4);
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
@media screen and (max-width: 767px) {
    font-size:calc(14rem/16);
}
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
@media screen and (max-width: 767px) {
    font-size:calc(14rem/16);
}
}
::-ms-input-placeholder { /* Microsoft Edge */
@media screen and (max-width: 767px) {
    font-size:calc(14rem/16);
}
}
select {
@media screen and (max-width: 767px) {
    font-size:calc(14rem/16);
}
}
.form-item {
    position: relative;
}

/* custom-select */
.custom-select {
    cursor: pointer;
}
.custom-select::-ms-expand, .custom-select--no-font::-ms-expand, .custom-select--simple::-ms-expand {
    display: none;
}
.custom-select:focus, .form-control:focus {
    border-color: var(--color-primary);
}
.custom-select option {
    padding: 10px;
    line-height:1.2;
    height:30px;
}
.custom-select option[disabled] {
    background-color:var(--bg-lightgrey);
}
.btn:focus, button:focus {
    box-shadow: none !important;
    outline:none !important;
}
input.box-shadow:focus {
    outline:none !important;
    box-shadow: 5px 3px 12px 0 rgba(0,0,0,.06), 5px 3px 20px 0 rgba(0,0,0,.08);
}
.form-control,
.form-control--react-select .form-control--react-select__control{
    font-size: calc(16rem/16);
    padding: calc(11rem/16) .75rem;
    box-shadow: 5px 3px 12px 0 rgba(0,0,0,.01), 5px 3px 20px 0 rgba(0,0,0,.03);
    border: 1px solid #e7e7e7;
@media screen and (max-width: 767px) {
    font-size:calc(14rem/16);
}
}
select.form-control.custom-select:not([size]):not([multiple]), .custom-select {
    box-shadow: 3px 0px 12px rgba(0, 0, 0, 0.06);
    padding: calc(14rem / 16) calc(37rem / 16);
    font-size: 1rem;
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    letter-spacing: 2px;
    width: auto;
    border-radius: 0px;
    height: auto;
    position: relative;
    background: none;
@media screen and (max-width: 767px) {
    font-size:calc(14rem/16);
}
}
select.form-control:not([size]):not([multiple]) {
    height: calc(2.95rem + 4px);
}

.custom-select-icon {
    position: absolute;
    right: calc(17rem / 16);
    top: calc(18rem / 16);
    font-size: calc(12rem / 16);
}

.custom-select--no-font {
    cursor: pointer;
    font-family: var(--font-rounded-light);
    box-shadow: 3px 0px 12px rgba(0, 0, 0, 0.06);
    border: 1px solid #e7e7e7;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-select--no-font + .custom-select-icon {
    top: calc(18rem / 16);
}

.custom-input {
    box-shadow: 5px 3px 12px 0 rgba(0,0,0,.01), 5px 3px 20px 0 rgba(0,0,0,.03);
    border: 1px solid #e7e7e7;
}
.custom-file-upload {
    position:relative;
    cursor:pointer;
}
.custom-file-upload input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: 0;
    cursor:pointer;
}

.custom-file-upload label {
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    pointer-events:none;
}

.custom-select--simple, select.form-control.custom-select.custom-select--simple:not([size]):not([multiple]) {
    font-family:var(--font-default);
    padding: calc(14rem/16) .75rem;
    text-transform:none;
    letter-spacing: 0;
    border:none;
    box-shadow: 5px 3px 12px 0 rgba(0,0,0,.03), 5px 3px 20px 0 rgba(0,0,0,.05);
}

/* topic-checkbox */

.list-inline-item:not(:last-child) {
    margin-right: calc(12rem/16);
}

.topic-checkbox .icon {
    position: absolute;
    right: 0;
    top: 0;
    padding:calc(8rem/16);
    font-size: calc(8rem / 16);
}

.topic-checkbox__wrapper {
    text-align: center;
}

.topic-checkbox__wrapper .list-inline-item {
    margin-bottom: calc(12rem/16);
}

.topic-checkbox {
    position: relative;
    cursor: pointer;
}

.topic-checkbox__input {
    position: absolute;
    left: calc(-19999rem/16);
    top: 0;
    transition: all .3s ease;
}

.topic-checkbox__text {
    border-radius: 0;
    padding: calc(11rem / 16) calc(36rem / 16);
    line-height: 1;
    display: inline-block;
    border: 1px solid #979797;
    background-color: #fff;
    color: var(--color-headline-default);
    font-size: 1rem;
    font-family: var(--font-default-bold);
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
}

.topic-checkbox__input:checked + .topic-checkbox__text {
    color: #fff;
    background-color: var(--color-primary);
    transition: all .3s ease;
}

/* personal topics*/
.personal-topics--add-more {
    text-align: center;
    margin-top: calc(38rem/16);
    margin-bottom: calc(25rem/16);
}

.personal-topics--add-more .form-item {
    margin-left: calc(10rem/16);
}


.form-group input {
    padding: calc(12rem/16) calc(20rem/16);
    font-size: calc(16rem/16);
}
.form-group label {
    font-size: calc(12rem/16);
    text-transform: uppercase;
    color: var(--color-grey);
}

.select__wrapper {
    position: relative;
}
.select {
    appearance: none;
}
.select-icon {
    pointer-events: none;
    position: absolute;
    right: calc(20rem/16);
    top: 50%;
    font-size: calc(10rem/16);
    transform: translateY(-50%);
    color: var(--color-primary);
@media screen and (max-width: 767px) {
    font-size: calc(7rem/16);
}
}
.form-control--react-select .form-control--react-select__control {
    border-radius: 0;
    padding: .5rem .25rem;
}
.form-control--react-select .form-control--react-select__dropdown-indicator {
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    pointer-events: none;
    font-size: .625rem;
    color: var(--color-primary);
    padding-left: .75rem;
    padding-right: .75rem;
}
.form-control--react-select .form-control--react-select__dropdown-indicator:before {
    content: var(--icon-arrow-bold);
}
.form-control--react-select .form-control--react-select__dropdown-indicator svg {
    display: none;
}
.form-control--react-select .form-control--react-select__indicator-separator {
    background-color: #e7e7e7;
}
.form-control--react-select .form-control--react-select__menu {
    border-radius: 0;
    margin-top: 0;
}
.form-control--react-select .form-control--react-select__option--is-focused {
    background-color: var(--color-primary);
    color: var(--color-primary-contrast);
}
.form-control--react-select .form-control--react-select__multi-value__remove:hover {
    background: var(--color-light-dark);
    color: var(--color-text-default);
}