.ticket-detail__accordion-item + .ticket-detail__accordion-item {
    margin-top: 0;
}
.ticket-detail__accordion-title {
    background-color: #f9f9f9;
    padding: calc(20rem/16) calc(30rem/16);
    color: var(--color-primary);
    text-transform: uppercase;
    font-weight: var(--font-weight-rounded-medium);
    position: relative;
    transition: color 0.2s ease;
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.ticket-detail__accordion-title:focus {
    color: var(--color-primary);
}
.ticket-detail__accordion-title.collapsed {
    color: var(--color-headline-default);
}
.ticket-detail__accordion-title:before {
    height:100%;
    content:'';
    position: absolute;
    width: calc(2rem/16);
    background-color: var(--color-primary);
    left:0;
    top:0;
    opacity:1;
    z-index: 2;
    transition: opacity 0.2s ease;
}
.ticket-detail__accordion-title.collapsed:before {
    opacity:0;
}
.ticket-detail__accordion-content {
    padding: 0 calc(20rem/16);
    @media screen and (min-width: 768px) {
        padding: 0 calc(30rem/16);
    }
}