.icon-in-text {
    vertical-align: -.1em;
}
.icon-in-text.icon-phone {
    font-size: 1.2em;
    vertical-align: -.13em;
}
.icon-in-text.icon-email {
    font-size: .8em;
}
.icon-in-text.icon-heart {
    vertical-align: -.2em;
}
.icon-in-text.icon-download {
    font-size: 1.2em;
    vertical-align: -.15em;
}

.icon-centered {
    min-width: calc(22rem / 16);
    text-align: center;
}

.icon-in-text--xl {
    font-size: 1.7em;
    vertical-align: -.3em;
}