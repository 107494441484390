.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    color: var(--color-headline-default);
    padding: calc(50rem/16) calc(50rem/16);
    z-index: 4;
    @media screen and (max-width: 767px) {
        padding: calc(30rem/16) calc(20rem/16) calc(15rem/16);
        z-index: 999;
        text-align: center;
    }
}
.cookie-bar__img {
    height: calc(100rem/16);
    width: calc(100rem/16);
    margin-right: calc(20rem/16);
    display: flex;
    margin-top: auto;
    @media screen and (max-width: 767px) {
        height: calc(50rem/16);
        margin: 0 auto calc(10rem/16) auto;
        width: calc(50rem/16);
    }
}
@media screen and (min-width: 768px) {
    .cookie-bar {
        display: flex;
        align-items: baseline;
    }
}
.cookie-bar__text {
    display: inline;
    @media screen and (max-width: 767px) {
        display: block;
        margin-bottom: calc(10rem/16);
        font-size: calc(10rem/16);
        margin-top: calc(10rem/16);
    }
}
@media screen and (min-width: 768px) {
    .cookie-bar__text {
        display: block;
        flex: 0 1 auto;
    }
}

.cookie-bar__detail,
a.cookie-bar__detail {
    display: inline;
}
@media screen and (min-width: 768px) {
    .cookie-bar__detail {
        flex: auto;
        margin-left: 1rem;
    }
}
.cookie-bar__detail-btn {
    @media screen and (max-width: 767px) {
        font-size:calc(14rem/16);
    }
}
.cookie-bar__detail .cookie-bar__detail-btn {
    display: inline;
    vertical-align: inherit;
    cursor: pointer;
    margin-right: .25em;
    text-decoration: none;
    color:var(--color-primary);
    @media screen and (max-width: 767px) {
        font-size:calc(12rem/16);
    }
}
.cookie-bar__buttons {
    margin-top: .75rem;
    text-align: center;
    @media screen and (max-width: 767px) {
        margin-top: calc(20rem / 16);
    }
}
@media screen and (min-width: 768px) {
    .cookie-bar__buttons {
        margin-left: 2rem;
        display: flex;
        flex: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.cookie-bar .cookie-bar__accept-required {
    display: flex;
    font-size: calc(12rem/16);
    font-weight: var(--font-weight-rounded-bold);
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: calc(10rem/16);
    @media screen and (max-width: 767px) {
        margin: calc(10rem/16) auto;
    }
}
/* modal */

.cookie-modal .cookie-modal__title{
    font-family: var(--font-decorative);
    letter-spacing: 0.125rem;
    font-size: calc(25rem/16);
    text-align: center;
    line-height: 1.25;
    text-transform: uppercase;
    color: var(--color-headline-default);
}
.cookie-modal .modal-content{
    border: none;
    background: #fff;
    color:#272727;
    padding: calc(15rem/16) calc(30rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(15rem/16);
    }
}
.cookie-modal__group {
    background-color:#F7F9FA;
}
.cookie-modal .modal-header {
    text-align: center;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.75);
}
.cookie-modal .modal-footer {
    border-color: transparent;
    display: block;
    padding: 0 calc(10rem/16) calc(20rem/16);
}
.cookie-modal .modal-header .close {
    font-size: 1.5rem;
    color: var(--color-headline-default);
    position: absolute;
    right: calc(15rem/16);
    top: calc(15rem/16);
    opacity: 0.95;
}
.cookie-modal .modal-header .close:hover {
    color: var(--color-headline-default);
    opacity: 0.8;
}
.cookie-modal .cookie-modal__label {
    font-size: calc(10rem/16);
    color: #333;
    @media screen and (max-width: 767px) {
        display: flex;
        align-items:center;
    }
}
.cookie-modal .modal-body .cookie-modal__item .cookie-modal__label .cookie-modal__switch {
    margin-right: 0.25rem;
    transform: scale(0.7);
    transform-origin: top left;
}

.cookie-modal .modal-dialog {
    max-width: 500px;
    @media screen and (min-width: 768px) {
        margin: 7rem auto;
    }
}

.cookie-modal .cookie-modal__item--all{
    margin-bottom: 0.75rem;
}

.cookie-modal .cookie-modal__item:last-child{
    margin-bottom: 0;
}
.cookie-modal__item {
    margin-bottom: .75rem;
    color: #333;
}
.cookie-modal__item--all {
    margin-bottom: 1rem;
}
.cookie-modal__label {
    font-size: calc(12rem/16);
}
.cookie-modal__link {
    display: inline-block;
    margin-left: .5rem;
    text-decoration: underline;
}
.cookie-modal__group-header {
    padding: calc(13rem/16) calc(14rem/16) calc(13rem/16) calc(10rem/16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16);
    }
}
.cookie-modal__group-header__title .cookie-modal__label {
    font-size: calc(14rem/16);
}
.cookie-modal .modal-body__text {
    font-size: calc(12rem/16);
    margin-bottom: calc(10rem/16);
}
.cookie-modal .modal-body__text a {
    color: var(--color-primary);
}
.cookie-modal .modal-body {
    padding-top: 0;
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}
.cookie-modal__group-header__arrow {
    color: var(--color-primary);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}
/* switch */
.cookie-modal__switch {
    position: relative;
    display: inline-block;
    width: calc(45rem/16);
    height: calc(25rem/16);
    vertical-align: middle;
    margin-right: .5rem;
    @media screen and (max-width: 767px) {
        transform: scale(0.8);
    }
}
.cookie-modal__btn {
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
    }
}
.cookie-modal__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}
.cookie-modal__switch-toggle:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 34px;
}
input:checked + .cookie-modal__switch-toggle {
    background-color: var(--color-primary);
}
input:focus + .cookie-modal__switch-toggle {
    box-shadow: 0 0 2px var(--color-primary);
}
input:checked + .cookie-modal__switch-toggle:before {
    transform: translateX(17px);
}
.cookie-modal__group {
    margin-bottom: calc(20rem/16);
}
.cookie-modal__group-body {
    overflow: hidden;
    padding: .75rem .75rem calc(14rem/16);
}
.cookie-modal__group-toggle-icon {
    transform: rotate(180deg);
    transition: transform 120ms ease-out;
    font-size: .75rem;
    @media screen and (max-width: 767px) {
        font-size: calc(10rem/16);
    }
}
.cookie-modal__group-toggle.collapsed .cookie-modal__group-toggle-icon {
    transform: rotate(0);
}
.cookie-modal__group-toggle {
    padding: calc(10rem/16);
}
.cookie-modal__group-text {
    font-size: calc(11rem/16);
    padding: calc(10rem/16);
}
.cookie-modal__switch-toggle--is-disabled {
    opacity: 0.7;
}