
.darkpage-overlay {
    position: fixed;
    overflow-y: scroll;
    width:100%;
    height:100%;
    background:linear-gradient(180deg,rgba(25,30,48,.9),rgba(0,0,0,.9));
    z-index: 99;
    color:#fff;
    padding-top:calc(160rem/16);
    padding-bottom:calc(40rem/16);
    text-align: center;
    @media screen and (max-width: 767px) {
        padding-top:calc(30rem/16);
    }
}


.darkpage-overlay__close {
    font-size: calc(35rem/16);
    color: #fff;
    position: absolute;
    top: calc(20rem/16);
    right: calc(20rem/16);
    opacity: 1;
    text-shadow:none;
    z-index: 9;
}
.darkpage-overlay__close:hover {
    color:var(--color-primary);
    box-shadow:none;
}