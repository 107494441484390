.embed-responsive--heroslide {
    padding-bottom: 41.05208%;
    min-height: calc(550rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom: 125%;
        min-height: auto;
    }
}

.embed-responsive--heroslide-small {
    padding-bottom: calc(621 / 1920  * 100%);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(440 / 375 * 100%);
    }
}

.embed-responsive--heroslide-shop {
    padding-bottom: calc(540 / 1920  * 100%);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(440 / 375 * 100%);
    }
}

.embed-responsive--offer-teaser {
    padding-bottom: 100%;
}

.embed-responsive-15by5 {
    padding-bottom: calc(5 / 15 * 100%);
}

.embed-responsive-12by5 {
    padding-bottom: calc(5 / 12 * 100%);
}

.embed-responsive-5by3 {
    padding-bottom: calc(3 / 5 * 100%);
}
.embed-responsive-5by8 {
    padding-bottom: calc(8 / 5 * 100%);
}
.embed-responsive-4by1 {
    padding-bottom: calc(1 / 4 * 100%);
}

.embed-responsive-7by11 {
    padding-bottom: calc(11 / 7 * 100%);
}

.embed-responsive-2by1 {
    padding-bottom: calc(1 / 2 * 100%);
}

.embed-responsive-4by5 {
    padding-bottom: calc(5 / 4* 100%);
}

.embed-responsive-17by6 {
    padding-bottom: calc(6 / 17 * 100%);
}

.embed-responsive-2by3 {
    padding-bottom: calc(3 / 2 * 100%);
}

.embed-responsive-5by7 {
    padding-bottom: calc(7 / 5 * 100%);
}

.embed-responsive-6by5 {
    padding-bottom: calc(5 / 6 * 100%);
}

.embed-responsive-3by2 {
    padding-bottom: calc(2 / 3 * 100%);
}

.embed-responsive-13by6 {
    padding-bottom: calc(6 / 13 * 100%);
}

.embed-responsive-14by11 {
    padding-bottom: 78%;
}


.embed-responsive-5by4 {
    padding-bottom: calc(4 / 5 * 100%);
}

.embed-responsive-5by6 {
    padding-bottom: calc(6 / 5 * 100%);
}

.embed-responsive-8by5 {
    padding-bottom: calc(8 / 5 * 100%);
}
.embed-responsive-26by15 {
    padding-bottom: calc(15 / 26 * 100%);
}
.embed-responsive-37by35{
    padding-bottom: calc(35 / 37 * 100%);
}
.embed-responsive-cover{
    height: 100%;
}
.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    min-width: 100%;
    left: 50%;
    transform: translateX(-50%);
}