.decorative-ordered-list__counter {
    font-size:calc(130rem/16);
    font-family:var(--font-decorative);
    color:var(--color-primary);
    opacity:0.5;
    position:absolute;
    top:0;
    left:0;
    right:0;
    line-height:1;
}
.decorative-ordered-list__text {
    margin: 0 auto;
    padding-top: calc(60rem/16);
    font-size:calc(24rem/16);
    font-family:var(--font-decorative);
    color:var(--color-headline-default);
    line-height:calc(35/24);
    text-align:center;
    max-width:calc(210rem/16);
    z-index: 1;
    position:relative;
    @media screen and (max-width: 767px) {
        font-size:calc(20rem/16);
    }
}