.ticket-detail-labels__icon {
    font-size: calc(24rem/16);
    color: var(--color-primary);
}
.ticket-detail-labels__text {
    font-size: calc(14rem/16);
    font-weight: var(--font-weight-rounded-medium);
    color: var(--color-headline-default);
    text-transform: uppercase;
    padding-left: calc(10rem/16);
}
.ticket-detail-labels__item {
    display: flex;
    align-items: center;
}
.ticket-detail-labels__item + .ticket-detail-labels__item {
    margin-top: calc(20rem/16);
}