
@media screen and (min-width: 768px) {
    .teaser-inview:after {
        position: absolute;
        width:100%;
        height:100%;
        background-color:#111;
        content:'';
        display:block;
        left:0;
        right:0;
        bottom:0;
    }

    .teaser-inview.inview-reveal:after {
        height: 0px;
        transition:all 1s ease-in-out;
    }

    .teaser-inview .teaser__image:before {
        opacity:0;
        transition:all 0.2s ease;
    }

    .teaser-inview.inview-reveal .teaser__image:before {
        opacity:0;
    }

    .teaser-inview.inview-reveal .teaser__description {
        opacity:0;
    }

    .teaser-inview .teaser__image-headline {
        opacity:0;
    }

    .inview-reveal .teaser__image-headline.inview, .inview-reveal .teaser__description.inview{
        animation-delay: 0.6s;
        animation-name: inviewTeaserHeadline;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
}

@keyframes inviewTeaserHeadline {
    from {
        opacity:0;
        transform: translateY(-100px);
    }
    to {
        opacity:1;
        transform: translateY(0px);
    }
}