
.teaser-grid__row {
  margin-bottom:calc(12rem/16);

  @media screen and (max-width: 767px) {
    margin-bottom:0;
  }
}
.offer-teaser-grid__row {
    margin-bottom:calc(18rem/16);

    @media screen and (max-width: 767px) {
        margin-bottom:0;
    }
}
.offer-teaser-grid__headlines h2 {
    font-size: 2.5rem;
}
.teaser-grid__bg-img:before {
    @media screen and (min-width: 768px) {
        content:'';
        display:block;
        position:absolute;
        height:100%;
        width:100%;
        top:0;
        left:0;
        background-color:rgba(255,255,255,0.3);
    }
}