.percent-badge {
    position: fixed;
    top: 14%;
    right: calc(10rem/16);
    max-width: 6.6rem;
    z-index: 7;
    @media screen and (max-width: 1400px) and (min-width:768px) {
        max-width: 6rem;
        right: calc(5rem/16);
    }
    @media screen and (max-width: 767px) {
        top: calc(120rem/16);
        right: calc(5rem/16);
        max-width: 4rem;
    }
}
.notification-header--is-visible .percent-badge {
    top: 25%;
    @media screen and (max-width: 767px) {
        top: calc(222rem/16);
    }
}