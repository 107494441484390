.wysiwyg table {
    text-align: left;
}
.table thead th,
.wysiwyg table thead th {
    font-family:var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    color:var(--color-headline-default);
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        font-size:calc(20rem/16);
        line-height: calc(25/20);
    }
}
.table th,
.table td,
.wysiwyg table th,
.wysiwyg table td {
    @media screen and (max-width: 767px) {
        padding:calc(10rem/16);
    }
}

.table__headline {
    font-size: calc(40rem/16);
    font-family:var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    line-height:1;
    color: var(--color-headline-default);
    text-transform: uppercase;
}

.table__update {
    font-size:1rem;
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-light);
}

.table__update .icon {
    padding-right:calc(12rem/16);
}

.table__legend__item {
    display:inline-block;
    padding-right: calc(45rem/16);
    padding-bottom:calc(20rem/16);
    font-size:calc(14rem/16);

    @media screen and (max-width: 767px) {
        padding-right: calc(20rem/16);
        padding-bottom:calc(20rem/16);
    }
}

.table__legend__item:last-child {
    padding-right: 0;
}
.table__legend__item .icon {
    padding-right:calc(13rem/16);
    font-size: calc(26rem/16);
}


.table-striped .icon {
    font-size:calc(32rem/16);
    width: calc(50rem/16);
    padding-right:calc(15rem/16);
    
    @media screen and (max-width: 767px) {
        padding-right:calc(8rem/16);
        font-size:calc(25rem/16);
    }
}
.table-striped .icon.icon-facility-palace {
    font-size: 1.3rem;

    @media screen and (max-width: 767px) {
        font-size: 1.1rem;
    }
}
.table-striped .icon.accordion-icon--flip {
    font-size: calc(12rem/16);
}

.table-striped td {
    vertical-align: middle;
}

.table-striped thead th {
    vertical-align: middle;
}

.table-highlight-primary{
    background-color: var(--table-highlight-primary);
    color:#000 !important;
}

.table-highlight-secondary{
    background-color: var(--table-highlight-secondary);
    color:#000 !important;
}
.tr-detail__text {
    padding: calc(30rem/16) calc(65rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(10rem/16);
    }
}
.table-striped--with-info .bg-dark-accent {
    background-color:var(--bg-dark-accent);
}
.table-bottom-info {
    font-size: calc(14rem / 16);
    line-height: calc(26/18);

    @media screen and (min-width: 768px) {
        font-size: calc(18rem / 16);
    }
}
.table-bottom-info__row {
    padding: calc(5rem / 16) calc(12rem / 16);

    @media screen and (min-width: 768px) {
        padding: calc(12rem / 16) calc(22rem / 16);
    }
}
.table-bottom-info__row--bordered {
    border-top: calc(2rem / 16) solid #979797;
    margin-top: calc(5rem / 16);
    padding-top: calc(15rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(10rem / 16);
        padding-top: calc(25rem / 16);
    }
}