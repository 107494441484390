.is-affix {
    position: fixed !important;
}

.is-affix.bottom {
    bottom:0;
    z-index: 2;
    left: 0;
    right:0;
    @media screen and (max-width: 767px) {
        bottom: calc(0rem/16);
        z-index: 3;
    }
}