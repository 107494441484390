.like-button[disabled] {
    opacity: .7;
}

/* liked state */
.like-button.is-liked {
    color: var(--color-secondary);

}

/* pending state */
.like-button.is-pending {
    opacity: .8;
}

.like-button__icon:before {
    content: var(--icon-heart);
}
.is-liked .like-button__icon:before {
    content: var(--icon-heart-filled);
}

.like-button:focus {
    outline: none;
}
.like-button {
    @media screen and (max-width: 767px) {
        padding: 0;
        width: calc(48rem/16);
        height: calc(48rem/16);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}