.quote-slide__content {
    color: #ffffff;
    margin: 0;
    z-index: 2;
}
.quote-slide__text {
    font-family: var(--font-decorative);
    font-weight: normal;
    letter-spacing:var(--font-letterspacing-decorative);
    font-size: calc(30em / 16);
    line-height: 1.2;
    text-shadow: 0 0 15px rgba(0,0,0,.5);

    @media screen and (min-width: 768px) {
        font-size: calc(45em / 16);
        line-height: 1;
        text-shadow: 0 0 15px rgba(0,0,0,.5);
    }

    @media screen and (min-width: 1200px) {
        font-size: calc(60em / 16);
    }
}

.quote-slide__text--has-marks {
    position:relative;
}
.quote-slide__text--has-marks:before,
.quote-slide__text--has-marks:after {
    font-size: calc(40rem / 16);
    color: var(--color-primary);
    font-family: var(--font-decorative);
    letter-spacing:var(--font-letterspacing-decorative);
    line-height: 0;

    @media screen and (min-width: 768px) {
        font-size: calc(70rem / 16);
    }
}
.quote-slide__text--has-marks:before {
    content: '„';
    display: inline;

    @media screen and (min-width: 768px) {
        display: block;
        position: absolute;
        top: calc(22rem/16);
        right: calc(100% + .25rem);
    }
}
.quote-slide__text--has-marks:after {
    display: inline;
    content: '„';
}
.quote-slide__cite {
    text-align: right;
    line-height: 1.1;

    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
}
.quote-slide:before {
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.2);
    z-index: 1;
}