.news-teaser:hover img {
    transform: scale(1.1);
}
.news-teaser:hover .teaser__title {
    color:var(--color-primary);
}
.news-teaser img {
    transform: scale(1);
    -webkit-backface-visibility: hidden;
    transition: all 0.4s ease-in-out;
}