.title-block--centered {
    text-align: center;
}
.title-block__title {
    font-size: calc(20rem/16);
    text-transform: uppercase;
}
.title-block {
    margin-bottom: calc(20rem/16);
}
.title-block__sub-title {
    font-size: calc(16rem/16);
    text-transform: uppercase;
}
.title-block--main .title-block__title {
    font-size: calc(50rem/16);
    font-family: var(--font-decorative);
    line-height: calc(49/40);
    @media screen and (max-width: 767px) {
        font-size: calc(24rem/16);
    }
}
.title-block--main .title-block__sub-title {
    color: var(--color-primary);
    font-size: calc(16rem/16);
    line-height: calc(19/16);
}
.title-block--main {
    margin-bottom: calc(100rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(33rem/16);
    }
}
.title-block--has-spacing {
    margin-bottom: calc(50rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }
}