
.headline-with-line {
    line-height:1.2;
    position:relative;
    overflow:hidden;
}

.headline-with-line .headline-with-line__hr {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    position: relative;
    padding-right: calc(10rem/16);
}
.headline-with-line .icon + .headline-with-line__hr {
    padding-left:calc(5rem/16);

}
.headline-with-line .headline-with-line__hr:after {
    left: 100%;

}
.headline-with-line .headline-with-line__hr:after {
    content: '';
    display: block;
    width: calc(20000rem/16);
    position: absolute;
    bottom: 0;
    border-top: 1px solid var(--color-text-default);
}

.headline-with-vertical-line-bottom {
    text-align: center;
    position: relative;
    z-index: 1;
    @media screen and (min-width: 768px) {
        margin-bottom:calc(-40rem/16);
        padding-top:calc(80rem/16);
    }
    @media screen and (max-width: 767px) {
        padding-top:calc(30rem/16);
        padding-bottom:calc(20rem/16);
    }
}

.headline-with-vertical-line-bottom.inview {
    @media screen and (min-width: 768px) {
        opacity:0;
    }

}

.headline-with-vertical-line-bottom.is-inview {
    @media screen and (min-width: 768px) {
        animation-name: inview;
        animation-duration: 2s;
        animation-fill-mode: forwards;
    }
}

@keyframes inview {
    from {
        opacity:0;
        transform: translateY(calc(30rem/16));
    }
    to {
        opacity:1;
        transform: translateY(0);
    }
}

.headline-with-vertical-line-bottom:after {
    @media screen and (min-width: 768px) {
        content: "";
        height: calc(96rem/16);
        width: 1px;
        background-color:var(--color-primary);
        display: block;
        margin:0 auto;
        margin-top: calc(30rem/16);

    }
}

.headline-with-vertical-line-top {
    text-align: center;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 767px) {
        padding-top:calc(30rem/16);
    }
}

.headline-with-vertical-line-top:before {
    @media screen and (min-width: 768px) {
        content: "";
        height: calc(96rem/16);
        width: 1px;
        background-color:var(--color-primary);
        display: block;
        margin:0 auto;
        margin-bottom: calc(30rem/16);
        margin-top:calc(20rem/16);
    }

}

.headline-with-vertical-line-top .icon {
    display:block;
    font-size:calc(50rem/16);
    margin-bottom:calc(10rem/16);
}
.portal-headline-with-vertical-line-bottom {
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);

}

