.main-footer {
    color: #706F6F;
}
.main-footer__top {
    padding: calc(30rem / 16) 0 calc(40rem / 16) 0;
}
@media screen and (min-width: 768px) {
    .main-footer__top {
        padding: calc(80rem / 16) 0;
    }
}
.main-footer__address {
    font-size: calc(18rem / 16);
}
.main-footer__address a:hover,
.main-footer__address a:focus,
.main-footer__address a:active {
    text-decoration: underline;
}

.main-footer__logo {
    width: calc(234rem/16);
}
.main-footer__logo-skiclub {
    width: auto;
    height: calc(80rem/16);
}
.main-footer__bottom {
    @media screen and (max-width: 767px) {
        padding-bottom: 63px;
    }
}
.main-footer__bottom-logo {
    height:calc(105rem/16);
    max-width:calc(150rem/16);
    padding:calc(20rem/16) calc(30rem/16);
    @media screen and (max-width: 767px) {
        height: calc(43rem/16);
        padding: 10px;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding:calc(20rem/16) calc(22rem/16);
    }
}

.main-footer__bottom-logo-audi {
    height:calc(80rem/16);
}

.main-footer__bottom-logo-hublot {
    height:calc(80rem/16);
}

.main-footer__bottom-logo-skiresort {
    height:calc(95rem/16);
}
.main-footer__bottom-logo-matterhornparadise, .main-footer__bottom-logo-matterhorn {
    height: 73px;
    max-width: inherit;
}
.main-footer__bottom-logo-family {
    padding:calc(14rem/16) calc(30rem/16);
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        padding:calc(14rem/16) calc(22rem/16);
    }
}