
.avalanche-warning__badge {
    width: calc(120rem/16);
    height: calc(120rem/16);
    cursor:pointer;
    padding: calc(10rem/16) 1rem;
    text-align:center;
    z-index: 2;
    display: block;
    @media screen and (max-width: 767px) {
        padding:calc(10rem/16);
        width: calc(100rem/16);
        height: auto;
    }
}

.avalanche-warning__badge--portal {
    background-color: rgba(255, 255, 255, 0.6);
    right: 0;
    top: 27%;
    position: fixed;
    z-index: 5;

    @media screen and (max-width: 767px) {
        position: absolute;
    }

}
.avalanche-warning__badge img {
    max-width: calc(80rem/16);
    position:relative;
    margin-left: calc(10rem/16);
    @media screen and (max-width: 767px) {
        max-width: calc(64rem/16);
        margin-left: calc(16rem/16);
    }
}

.avalanche-warning__pulse {
    background:transparent;
    position: absolute;
    margin: 0 auto;
    display: block;
    width: calc(100rem/16);
    height: calc(100rem/16);
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(124,27,31,.94);
    animation: pulse 2s infinite;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @media screen and (max-width: 767px) {
        width: calc(40rem/16);
        height: calc(40rem/16);
    }
}


.avalanche-warning__pulse-xs {
    width: calc(10rem/16);
    height: calc(10rem/16);
    animation: pulse-xs 2s infinite;
    @media screen and (max-width: 767px) {
        width: calc(5rem/16);
        height: calc(5rem/16);
    }
}
.avalanche-warning__pulse:hover {
    animation: none;
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(114, 7, 0, 0.50);
        box-shadow: 0 0 0 0 rgba(114, 7, 0, 0.50);
    }
    70% {
        -moz-box-shadow: 0 0 0 80px rgba(114, 7, 0, 0);
        box-shadow: 0 0 0 80px rgba(114, 7, 0, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(114, 7, 0, 0);
        box-shadow: 0 0 0 0 rgba(114, 7, 0, 0);
    }
}

@keyframes pulse-xs {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(114, 7, 0, 0.80);
        box-shadow: 0 0 0 0 rgba(114, 7, 0, 0.80);
    }
    70% {
        -moz-box-shadow: 0 0 0 40px rgba(114, 7, 0, 0);
        box-shadow: 0 0 0 40px rgba(114, 7, 0, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(114, 7, 0, 0);
        box-shadow: 0 0 0 0 rgba(114, 7, 0, 0);
    }
}