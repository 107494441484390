.google-map__zoom-btn {
    width: 2.8125rem;
    height: 2.8125rem;
    background-color: var(--color-primary-light);
    bottom: 1.5rem;
    right: 1.5rem;
    padding: 0;
    position:absolute;
    z-index: 9;
    font-family:var(--font-default);
    font-size: 30px;
    @media screen and (max-width: 767px) {
        bottom: 0.5rem;
        right: 0.5rem;
    }
}
.google-map__zoom-btn+.google-map__zoom-btn {
    bottom: 4.625rem;
    @media screen and (max-width: 767px) {
        bottom: 3.625rem;

    }
}