.download-teaser__description {
    font-size: calc(16rem/16);
    line-height: calc(19/16);
}
.download-teaser__category {
    text-transform:uppercase;
    font-family: var(--font-rounded-bold);
    font-weight: var(--font-weight-rounded-bold);
    font-size: calc(16rem/16);
    line-height: calc(19/16);
    color: var(--color-primary);
}
.download-teaser__title {
    font-size: calc(20rem/16);
    line-height: calc(24/20);
}
.download-teaser__type {
    background-color: var(--color-primary);
    font-family: var(--font-decorative);
    font-size: calc(16rem/16);
    line-height: calc(20/16);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    padding: calc(2rem/16) calc(25rem/16);
    position:absolute;
    right:-10px;
    color:#fff;
    bottom:calc(20rem/16);
    z-index: 2;
}
.download-teaser__icon-list {
    position:absolute;
    top: calc(20rem/16);
    left: calc(20rem/16);
    color:#fff;
    font-size: calc(20rem/16);
    z-index: 1;
    opacity:1;
    transition: opacity 0.2s ease;
}
.download-teaser__icon-list .icon-pdf-preview {
    font-size: calc(16rem/16);
}
.download-teaser__image:before {
    background: linear-gradient(0deg,transparent,rgba(0,0,0,.3));
}
.download-teaser__image-overlay {
    position:absolute;
    height:100%;
    width:100%;
    top:0;
    left:0;
    background: linear-gradient(0deg, rgba(39, 39, 39, 0.87) 0%, rgba(39, 39, 39, 0.85) 90%);
    z-index: 1;
    opacity:0;
    color:#fff;
    padding: calc(40rem/16) calc(30rem/16);
    transition: opacity 0.4s ease;
    @media screen and (max-width: 1199px) {
        padding: calc(10rem/16);
    }
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.download-teaser__image-overlay a:hover {
    color: var(--color-primary);
}
.download-teaser:hover .download-teaser__image-overlay {
    opacity:1;
    transition: opacity 0.3s ease;
}
.download-teaser:hover .download-teaser__icon-list {
    opacity:0;
}
.download-teaser__image-overlay-icon.icon-pdf-preview {
    font-size: calc(12rem/16);
}
.download-teaser__image-overlay-icon {
    width: calc(20rem/16);
}