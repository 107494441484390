.social-slider {
    margin-top:calc(100rem/16);
    @media screen and (max-width: 767px) {
        margin-top:calc(20rem/16);
    }
}
.social-slider__headlines h2 {
    font-size: calc(28rem/16);
    @media screen and (min-width: 768px) {
        font-size: calc(40rem/16);
    }
}
.social-slide__item {
    padding:calc(20rem/16);
    color:#fff;
    @media screen and (max-width: 767px) {
        padding-left:calc(10rem/16);
        padding-right:calc(10rem/16);
    }
}

.social-slider__arrow {
     position: absolute;
     display: block;
     z-index:2;
     cursor:pointer;
     font-size:calc(30rem/16);
     width:50px;
     color:#fff;
     @media screen and (max-width: 767px) {
         position:relative;
         display:block;
         font-size:calc(25rem/16);
         transform:none;
         width:calc(50rem/16);
         text-align:center;
     }
     @media screen and (min-width: 768px) {
         top: 50%;
         transform: translateY(-50%);
     }
 }
.social-slider__arrow.slick-disabled {
    color:#fff;
}
.social-slider__arrow.slick-disabled:hover {
    color:var(--color-primary);
}
.social-slider__arrow.arrow-next:not(.slick-hidden) {
    right: calc(100rem/16);
    text-align:right;
    @media screen and (max-width: 1240px)  and (min-width: 768px) {
        right: calc(20rem/16);
    }
    @media screen and (max-width: 767px) {
        right:0;
        display:inline-block;
    }
}
.social-slider__arrow.arrow-prev:not(.slick-hidden) {
    left: calc(100rem/16);
    @media screen and (max-width: 1240px) and (min-width: 768px) {
        left: calc(20rem/16);
    }
    @media screen and (max-width: 767px) {
        left:0;
        display:inline-block;
    }
}