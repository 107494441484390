.ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut {
    stroke: var(--color-primary);
}
.ct-series-b .ct-bar, .ct-series-b .ct-line, .ct-series-b .ct-point, .ct-series-b .ct-slice-donut {
    stroke: var(--color-primary);
}
.ct-point.ct-circle,  .ct-series-a .ct-point.ct-circle, .ct-series-b .ct-point.ct-circle {
    fill: var(--color-dark);
    stroke: var(--color-light);
    stroke-width: calc(1rem/16);
    box-shadow: 5px 5px 10px rgba(0,0,0,0.39);
}
.ct-line {
    stroke-width: calc(1rem/16);
}
.ct-grid {
    stroke-dasharray: none;
    stroke-width: 0.5px;
}
.ct-grid.ct-horizontal {
    display: none;
}
.ct-label.ct-horizontal.ct-end, .ct-label.ct-horizontal.ct-start {
    text-align: center;
    width: calc(30rem/16) !important;
    font-size: calc(12rem/16);
    text-transform:uppercase;
    line-height: 1.15;
    font-weight: var(--font-weight-rounded-medium);
    @media screen and (max-width: 767px) {
        width: auto !important;
        font-size: calc(10rem/16);
    }
}
.ct-chart-bar .ct-label, .ct-chart-line .ct-label {
    font-size: calc(9rem/16);
    text-transform: uppercase;
}
.ct-label.ct-vertical.ct-start {
    justify-content: flex-start;
}
.ct-grid.ct-vertical:nth-of-type(2) {
    opacity: 0.4;
}