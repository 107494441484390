:root {
    --color-primary: #00ACE1;
    --color-secondary: #FF3C3E;;
    --color-light: #f8f9fa;
    --color-dark: #343a40;
    --color-text-default: #272727;

    --color-grey:#8E8E8E;
    --color-grey-light:#E5E5E5;


    --table-highlight-primary: rgba(78, 104, 130, 0.2);
    --table-highlight-secondary: rgba(78, 103, 130, 0.32);

    --color-headline-default:#4A535C;
    --color-text-primary: #706F6F;
    --color-ghost-btn: inherit;
    --color-lightgrey: #CCCCCC;

    --color-danger: #EE2E22;
    --color-warning: #ffc107;
    --color-info: #17a2b8;
    --color-success: #83B692;

    --color-danger-1: #CBD859;
    --color-danger-2: #d7d222;
    --color-danger-3: #F49717;
    --color-danger-4: #E51C20;
    --color-danger-5: #E51C20;

    --color-white: #fff;
    --color-lightblue:#4CD8FF;
    --color-blue: #175284;
    --color-green:#23CD6F;
    --color-pastel-green:#83B692;

    --color-primary-contrast: #FFF;
    --color-secondary-contrast: #FFF;
    --color-success-contrast: #FFF;
    --color-info-contrast: var(--color-text-default);
    --color-warning-contrast: var(--color-text-default);
    --color-danger-contrast: #FFF;
    --color-light-contrast: var(--color-text-default);
    --color-dark-contrast: #FFF;
    --color-white-contrast: var(--color-text-default);
    --color-default-contrast: #FFF;

    --color-primary-dark: #0097C4;
    --color-secondary-dark: #B32B2D;
    --color-success-dark: #A2D667;
    --color-info-dark: #158C9E;
    --color-warning-dark: #E6AE07;
    --color-danger-dark: #D42920;
    --color-light-dark: #E0E0E0;
    --color-dark-dark: #1F2326;
    --color-white-dark: #E6E6E6;
    --color-default-dark: #0D0D0D;

    --color-primary-light: #57C9EC;
    --color-secondary-light: rgba(255, 60, 62, 0.82);
    --color-success-light: #C1FF7A;
    --color-info-light: #1BB9D1;
    --color-warning-light: #FFD608;
    --color-danger-light: #FF3126;
    --color-light-light: #fff;
    --color-dark-light: #495159;
    --color-white-light: #FFF;
    --color-default-light: #404040;

    --color-season-light-blue: #00ACE1;
    --color-season-middle-blue: #1D78C3;
    --color-season-blue: #192CC3;
    --color-season-dark-blue:#082463;

    --bg-darkgrey: #272727;
    --bg-dark: #111111;
    --bg-darkblue: #191E2F;
    --bg-lightgrey: #E7E7E7;
    --bg-grey: #4A535C;
    --bg-darkblue-gradient: radial-gradient(circle, rgba(25,30,47,0.87) 0%, #191E2F 100%);
    --bg-dark-accent: #efefef;

    --font-rounded-light: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
    --font-rounded-bold: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;

    --font-weight-rounded-light: 300;
    --font-weight-rounded-medium: 500;
    --font-weight-rounded-bold: 700;

    --font-decorative: "MatterhornClimb", "Arial Narrow", sans-serif;
    --font-letterspacing-decorative:1.5px;

    --font-default: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif; /* brandon grotesque */
    --font-default-bold: "Cabin-bold", sans-serif; /* Cabin-bold */
    --font-letterspacing-default-bold: 0.5px;

    --font-size-default: 16px;
    --font-size-default-mobile: 14px;

}

