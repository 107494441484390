/* https://matomo.org/faq/heatmap-session-recording/faq_24426/ */
.matomoHsr .modal,
.matomoHsr .modal-backdrop,
.matomoHsr .overlay,
.matomoHsr .dropdown.show .dropdown-menu,
.matomoHsr .slick-slide+.slick-slide{
    display:none !important;
}

.matomoHsr .img-text-slider .slide__item__img{
    height: 400px!important;
}


.matomoHsr .navbar.navbar-main{
    position: relative !important;
}

.matomoHsr .company-teaser-slider-mobile .col-12+.col-12{
    display: none!important;
}

.matomoHsr .review-slide__item{
    max-width: 1280px!important;
}