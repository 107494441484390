
.parsley-errors-list {
    font-size: 12px;
    list-style: none;
    padding-left: 0;
    color:var(--color-secondary);
}

.has-error select, .has-error input {
    border: 1px solid var(--color-secondary) !important;
}