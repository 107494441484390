.content-block + .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block,
.content-block + .pimcore_area_content > .content-block,
.pimcore_area_content + .content-block{
    margin-top: 2rem;

    @media screen and (min-width: 768px) {
        margin-top: 3rem;
    }

    @media screen and (min-width: 1200px) {
        margin-top: 4rem;
    }
}


.hero-slide + .pimcore_area_content {
    margin-top: 2rem;
}

.pimcore_area_content + .pimcore_area_image-text-slider   {
    margin-top: 2rem;

    @media screen and (min-width: 768px) {
        margin-top: 3rem;
    }

    @media screen and (min-width: 1200px) {
        margin-top: 4rem;
    }
}


.content-block--big-space {
    padding-top:calc(50rem/16);
    padding-bottom:calc(50rem/16);
    @media screen and (max-width: 767px) {
        padding-top:calc(20rem/16);
        padding-bottom:calc(20rem/16);
    }
}

.content-block + .content-block--big,
.pimcore_area_content + .pimcore_area_content > .content-block--big,
.content-block + .pimcore_area_content > .content-block--big,
.pimcore_area_content + .content-block--big{
    margin-top: calc(60rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(100rem/16);
    }

    @media screen and (min-width: 1200px) {
        margin-top: calc(120rem/16);
    }
}
