@media sceen and (max-width: 767px) {
    .scroll-info {
        display:none;
    }
}

@media screen and (min-width: 768px) {
    .scroll-info {
        font-family: var(--font-rounded-bold);
        letter-spacing: var(--font-letterspacing-default-bold);
        font-weight:var(--font-weight-rounded-medium);
        line-height: calc(20 / 14);
        font-size: calc(14rem / 16);
        color: var(--color-text-default);
        text-align: center;
        position: absolute;
        top: 94vh;
        left:50%;
        transform: translateX(-50%);
        text-transform: uppercase;
        opacity:0;
        animation-delay: 1s;
        animation-name: scrollInfoAnimation;
        animation-duration: 1.5s;
        animation-fill-mode: forwards;
        display:block;
        width:100%;
    }

    .scroll-info:after {
        content: '';
        height: calc(107rem/16);
        width: calc(1rem/16);
        background-color: #5c5c5c;
        display: block;
        position: absolute;
        text-align: center;
        top: calc(30rem/16);
        left: 50%;
        transform: translateX(-50%);
    }
}


@keyframes scrollInfoAnimation {
    from {
        opacity:0;
        transform: translateY(100px) translateX(-50%);
    }
    to {
        opacity:1;
        transform: translateY(0) translateX(-50%);
    }
}