/*Margin Helper*/



/*Padding Helper*/
.py-6 {

}

.mt--2 {
    margin-top:-2px;
}

/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-line-through {
    text-decoration: line-through;
}
.text-with-link a {
    color:var(--color-primary);
}
.text-underline {
    text-decoration: underline !important;
}

/*Text Color Helper*/
.text-color-default { color: var(--color-text-default); }
.text-headline-color { color: var(--color-headline-default); }
.text-lightblue { color: var(--color-lightblue); }
.text-color-primary { color: var(--color-primary); }
.text-danger-1 { color: var(--color-danger-1); }
.text-danger-2 { color: var(--color-danger-2); }
.text-danger-3 { color: var(--color-danger-3); }
.text-danger-4 { color: var(--color-danger-4); }
.text-danger-5 { color: var(--color-danger-5); }
.text-blue { color: var(--color-blue);}
.text-green { color: var(--color-green);}
.text-pastel-green { color: var(--color-pastel-green); }
.text-primary-hover:hover {color: var(--color-primary)}
.text-grey {color: var(--color-grey);}
/*Background Color Helper*/
.bg-dark { background-color: var(--bg-dark); }
.bg-darkgrey {
    background-color: var(--bg-darkgrey);
    color: #ffffff;
}
.bg-lightblue { background-color: var(--color-lightblue); }
.bg-lightgrey { background-color: var(--bg-lightgrey); }
.bg-light-grey-gradient {
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #f7f9fa 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ffffff 0%,#f7f9fa 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ffffff 0%,#f7f9fa 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.bg-grey {background-color: var(--bg-grey); }
.bg-darkblue-gradient {background: var(--bg-darkblue-gradient); }
.box-shadow {
    box-shadow: 5px 3px 12px 0 rgba(0,0,0,0.03), 5px 3px 20px 0 rgba(0,0,0,0.05);
}

/*Font Helper*/
.font-rounded-bold { font-family: var(--font-rounded-bold); font-weight: 700;}
.font-rounded-medium { font-family: var(--font-rounded-bold); font-weight: 500;}
.font-decorative { font-family: var(--font-decorative);font-weight: 300;  letter-spacing:var(--font-letterspacing-decorative);}
.font-default {
    font-family: var(--font-default);
    font-weight: normal;
}
.font-default-bold { font-family: var(--font-default-bold); letter-spacing: .5px;}
/*.font-new { font-family:var(--font-new);}
.font-new-mobile { font-family:var(--font-new-mobile);}*/

.overflow-hidden { overflow:hidden; }
.overflow-x-scroll { overflow-x:scroll; }
.lh-1 { line-height: 1; }
.lh-2 { line-height:2; }
.p-rel { position:relative; }
.isClickable, .isCursor {cursor: pointer;}


.border--light {
    border-color: #E7E7E7 !important;
}
.border--primary {
    border-color: var(--color-primary) !important;
}

/*font-sizes*/
.fz72 { font-size: calc(72rem / 16);
    @media screen and (max-width: 1199px) {
        font-size:calc(40rem/16);
    }
}
.fz96 { font-size: calc(96rem / 16);
    @media screen and (max-width: 767px) {
        font-size:calc(50rem/16);
    }
}
.fz36 { font-size: calc(36rem / 16); }
.fz30 { font-size: calc(30rem / 16); }
.fz24 { font-size: calc(24rem / 16); }
.fz22 { font-size: calc(22rem / 16); }
.fz20 { font-size: calc(20rem / 16); }
.fz18 { font-size: calc(18rem / 16); }
.fz16 { font-size: calc(16rem / 16); }
.fz14 { font-size: calc(14rem / 16); }
.fz12 { font-size: calc(12rem / 16); }
.fz10 { font-size: calc(10rem / 16) !important; }

.fz14--xs {
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.increased-click-area {
    padding: calc(10rem/16);
    margin: calc(-10rem/16);
}

.text-transform-none {
    text-transform: none !important;
}

.img-full-width {
    width: 100%;
    height: auto;
}

.overflow-visible {
    overflow:visible !important;
}

.column-count-2 {
    column-count:2;
}

.img-rounded, .img-rounded .embed-responsive { border-radius: 50%; }

.full-width {
    width: 100%;
}

.w-60 { width:60%; }
.w-30 { width: 30%; }
.w-10 { width: 10%; }
.w-md-auto {
    @media screen and (min-width: 767px) {
        width: auto !important;
    }
}