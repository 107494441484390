.link-img {
    position: relative;
    display: block;
}
.link-img__title {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    color:#fff;
    font-size: calc(24rem/16);
    font-family: var(--font-decorative);
    z-index: 2;
}
.link-img:before {
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 1;
    transition: 0.2s ease;
}
.link-img:hover:before {
    opacity: 0.5;
}