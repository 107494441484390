.main-nav-overlay {
    z-index: 100;
}
.main-nav-overlay {
    @media screen and (min-width: 991px) {
        width: calc(990rem/16);
    }
    @media screen and (min-width:768px) and (max-width: 990px) {
        width: 100%;
    }
}
.main-nav-overlay__container {
    padding-top: calc(100rem / 16);
    @media screen and (min-width: 768px) {
        padding-top: calc(110rem / 16);
    }
}
.main-nav-overlay .overlay__container {
    @media screen and (min-width: 768px) {
        width: 53rem;
    }
}
.main-nav-overlay__contact {
    font-size: calc(18rem / 16);
    line-height: calc(35 / 18);
    color: var(--color-text-primary);
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    margin-bottom: calc(5rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem / 16);
    }
}
.main-nav-overlay__contact .icon {
    width: calc(35rem / 16);
}
.main-nav-overlay__contact .icon-phone {
    font-size: 1.5rem;
}
.main-nav-overlay__contact .icon-email {
    font-size: 0.9rem;
}
.main-nav-overlay__nav {
    position: relative;
    text-transform: uppercase;
    font-size: calc(20rem / 16);
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    color: var(--color-headline-default);
    margin-bottom: 1.5em;
}
.main-nav-overlay__nav-list {
    position: relative;
    visibility: visible;
    background: #ffffff;

    @media screen and (min-width: 768px) {
        width: calc(216rem / 16);
        padding-right: 1rem;
    }
}
.main-nav-overlay__nav-list .main-nav-overlay__nav-list {
    font-size: .85em;

    @media screen and (max-width: 767px) {
        display: none;
        padding-left: .75rem;
        margin: .25rem 0 .5rem 0;
    }

    @media screen and (min-width: 768px) {
        position: absolute;
        top: 0;
        left: 100%;
        visibility: hidden;
        width: calc(230rem / 16);
    }
}
.main-nav-overlay__nav-list .main-nav-overlay__nav-list .main-nav-overlay__nav-list {
    font-family:var(--font-rounded-light);
    font-size:calc(14rem/16);
    text-transform: none;
}

.main-nav-overlay__nav-list .main-nav-overlay__nav-list .main-nav-overlay__nav-list :first-child a {
    font-family: var(--font-rounded-bold);
    font-weight: var(--font-weight-rounded-bold);
}

.main-nav-overlay__nav-list--two-cols {
    @media screen and (min-width: 768px) {
        column-count: 2;
    }
}

.main-nav-overlay__nav-list .main-nav-overlay__nav-list > li.is-open > .main-nav-overlay__nav-list--two-cols {
    width: 27rem;
    @media screen and (min-width:768px) and (max-width: 990px) {
        width: 20rem;

    }
}

.main-nav-overlay__nav-list > li.is-open > .main-nav-overlay__nav-list {
    @media screen and (max-width: 767px) {
        display: block;
    }
    @media screen and (min-width: 768px) {
        visibility: visible;
        border-left:1px solid hsla(225,2%,44%,.33);
        padding-left:1rem;
    }

}
.main-nav-overlay__nav-link {
    display: inline-block;
    padding-top: 1em;
    border-bottom: calc(3rem/16) solid transparent;

    @media screen and (min-width: 768px) {
        padding-top: 1.5em;
    }
}
.main-nav-overlay__item:first-child > .main-nav-overlay__nav-link {
    padding-top: 0;
}
.main-nav-overlay__nav-link:hover,
.main-nav-overlay__nav-link:hover,
.main-nav-overlay__nav-link:focus,
.main-nav-overlay__nav-link.active {
    color: var(--color-primary);
}
.main-nav-overlay__nav-item.is-open > .main-nav-overlay__nav-link {
    border-bottom-color: var(--color-primary);
}
@media screen and (max-width: 767px) {
    .main-nav-overlay__console {
        height:calc(63rem/16);
        background: linear-gradient(180deg,rgba(25,30,48,.9),rgba(0,0,0,.9));
        color:#fff;
    }
    .main-nav-overlay__console .icon-phone {
        font-size:calc(20rem/16);
    }
    .main-nav-overlay__console .icon-search {
        font-size:calc(20rem/16);
    }
    .main-nav-overlay__console .nav-item__icon-desc {
        color:#fff;
        display:block;
    }
    .main-nav-overlay__console .dropdown-menu.show {
        position:absolute;
        bottom: calc(62rem / 16);
        top:auto;
        width:100vw;
        margin:0;
        border-radius:0;
        padding-top: .75rem;
        left:0;
        padding-bottom: .75rem;
        background: linear-gradient(180deg,rgba(25,30,48,.9),rgba(0,0,0,.9));
        color:#fff;
    }
    .main-nav-overlay__console .nav-item.is-open {
        background-color:transparent;
    }
}
