
.login-box--has-errors {
    position: relative;
    display: block;
    padding: calc(50rem/16);
    @media screen and (max-width: 767px) {
        padding:calc(10rem/16);
    }
}
.login-box--has-errors:before {
    content: '';
    position: absolute;
    background-color: #e6e6e66e;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    z-index: 1;
}
.login-box__error-wrapper {
    position: relative;
    z-index: 2;
    text-align:left;
    margin-bottom: calc(40rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
    }

}
.login-box__error-list li + li {
    margin-top:calc(10rem/16);
}