.step-form.is-disabled {
    color: var(--color-dark);
}
.step-form {
    position: relative;
}
.step-form.is-disabled:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(255,255,255,0.7);
}