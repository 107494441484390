.styled-link-box {
    display: block;
    padding: calc(35rem/16) calc(10rem/16);
    box-shadow: 5px 1px 11px 0 rgba(0,0,0,0.06);
    border: calc(1rem/16) solid var(--bg-lightgrey);
    color: var(--color-headline-default);
    font-size: calc(24rem/16);
    font-family: var(--font-decorative);
    letter-spacing: calc(2rem/16);
    text-align: center;
    transition: background-color 0.2s ease, color 0.2s ease;
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        font-size: calc(20rem/16);
    }
    @media screen and (max-width: 767px) {
        font-size: calc(16rem/16);
        padding: calc(20rem/16) calc(10rem/16);
        letter-spacing: normal;
    }
}
.styled-link-box:hover {
    background-color: var(--color-primary);
    color:#fff;
}