

.percent-circle {
  transform: rotate(-90deg) translateZ(0);
}

.percent-circle circle {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 350ms ease-in-out;
  stroke-width: calc(3rem/16);
  stroke: var(--color-primary);
}

.percent-circle .bar {
  stroke: #fff;
}

.facility__percent-circle .percent-circle__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: calc(35rem/16);
    padding-bottom: calc(7rem/16);
}