.radio-filter__label {
    position:relative;
}
.radio-filter {
    position: relative;
    cursor: pointer;
}
.radio-filter__input {
    position: absolute;
    left: calc(-19999rem/16);
    top: 0;
    transition: all .3s ease;
}
.radio-filter__text {
    border-radius: 0;
    cursor:pointer;
    padding: calc(18rem / 16) calc(40rem / 16) calc(16rem / 16) calc(30rem / 16);
    line-height: 1;
    display: inline-block;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    color: var(--color-grey);
    font-size: calc(14rem/16);
    box-shadow: 5px 1px 11px 0 rgba(0,0,0,0.06);
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-medium);
    text-align: center;
    transition: color 0.2s ease, backgorund-color 0.2s ease;
}
.radio-filter__input:checked + .radio-filter__text {
    color: #fff;
    border-color:1px solid var(--color-primary);
    background-color: var(--color-primary);
    transition: all .3s ease;
}
.radio-filter__input:checked + .radio-filter__text .radio-filter__close-icon {
    transform: translateY(-50%) rotate(45deg);
}
.radio-filter--full-width .radio-filter__text,
.radio-filter--full-width .radio-filter__label{
    width: 100%;
}
.radio-filter--full-width .radio-filter__text {
    padding: calc(25rem / 16) calc(30rem / 16) calc(25rem / 16) calc(30rem / 16);
}
.radio-filter--small.radio-filter--full-width .radio-filter__text {
    text-transform: uppercase;
    padding: calc(18rem / 16) calc(20rem / 16) calc(16rem / 16) calc(20rem / 16);
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding: calc(18rem / 16) calc(10rem / 16) calc(16rem / 16) calc(10rem / 16);
    }
}
.radio-filter--active-outline .radio-filter__input:checked + .radio-filter__text {
    color: var(--color-primary);
    border:1px solid var(--color-primary);
    background-color:#fff;
    transition: all .3s ease;
}
.radio-filter--big .radio-filter__text {
    font-family: var(--font-decorative);
    font-size: calc(24rem/16);
    color: var(--color-headline-default);
    padding: calc(36rem/16) calc(40rem/16);
}
.radio-filter__text-img {
    max-height: calc(25rem/16);
    margin-right: calc(10rem/16);
}
.radio-filter__checkbox {
    position: relative;
    margin-right: 7px;
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #fff;
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
}
.radio-filter__checkbox:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: .4375rem;
    color: #fff;
    visibility: hidden;
    font-weight: 900;
}
.radio-filter__input:checked ~ .radio-filter__text .radio-filter__checkbox {
    background-color: var(--color-primary);
}
.radio-filter__input:checked ~ .radio-filter__text .radio-filter__checkbox:before {
    visibility: visible;
}