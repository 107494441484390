.loading-overlay-container {
    position: relative;
}
.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .7);
    z-index: 10;
    text-align: center;
}

.loading-overlay--dark {
    background: rgba(17, 17, 17, 0.7);
    color:#fff;
}