.checkbox-filter__close-icon {
    position: absolute;
    right: calc(5rem/16);
    top: 50%;
    padding:calc(8rem/16);
    transform: translateY(-50%);
    transition : transform 0.2s ease;
    font-size: calc(11rem / 16);
}
.checkbox-filter__label {
    position:relative;
}
.checkbox-filter {
    position: relative;
    cursor: pointer;
}
.checkbox-filter__input {
    position: absolute;
    left: calc(-19999rem/16);
    top: 0;
    transition: all .3s ease;
}
.checkbox-filter__text {
    border-radius: 0;
    cursor:pointer;
    padding: calc(18rem / 16) calc(40rem / 16) calc(16rem / 16) calc(30rem / 16);
    line-height: 1;
    display: inline-block;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    color: var(--color-grey);
    font-size: calc(14rem/16);
    box-shadow: 5px 1px 11px 0 rgba(0,0,0,0.06);
    font-family: var(--font-rounded-light);
    font-weight: var(--font-weight-rounded-medium);
    text-align: center;
    text-transform: uppercase;
    transition: color 0.2s ease, backgorund-color 0.2s ease;
}
.checkbox-filter__input:checked + .checkbox-filter__text {
    color: #fff;
    border-color:1px solid var(--color-primary);
    background-color: var(--color-primary);
    transition: all .3s ease;
}
.checkbox-filter__input:checked + .checkbox-filter__text .checkbox-filter__close-icon {
    transform: translateY(-50%) rotate(45deg);
}
.checkbox-filter--full-width .checkbox-filter__text,
.checkbox-filter--full-width .checkbox-filter__label{
    width: 100%;
}
.checkbox-filter--full-width .checkbox-filter__text {
    padding: calc(18rem / 16) calc(30rem / 16) calc(16rem / 16) calc(30rem / 16);
}
.checkbox-filter--active-outline .checkbox-filter__input:checked + .checkbox-filter__text {
    color: var(--color-primary);
    border:1px solid var(--color-primary);
    background-color:#fff;
    transition: all .3s ease;
}
.checkbox-filter--big .checkbox-filter__text {
    font-family: var(--font-decorative);
    font-size: calc(24rem/16);
    color: var(--color-headline-default);
    padding: calc(36rem/16) calc(40rem/16);

}