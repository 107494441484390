.blockquote {
    font-size: 1em;
}
.blockquote__content {
    position: relative;
    font-size: 2rem;
    line-height: 1;
    text-transform: uppercase;
    font-family: var(--font-decorative);
    letter-spacing:var(--font-letterspacing-decorative);
    color: var(--color-headline-default);

    @media screen and (min-width: 768px) {
        font-size: calc(40rem / 16);
    }
}
.blockquote__content:before,
.blockquote__content:after {
    font-size: calc(40rem / 16);
    color: var(--color-primary);
    font-family: var(--font-decorative);
    letter-spacing:var(--font-letterspacing-decorative);
    line-height: 0;

    @media screen and (min-width: 768px) {
        font-size: calc(70rem / 16);
    }
}
.blockquote__content:before {
    content: '„';
    display: inline;

    @media screen and (min-width: 768px) {
        display: block;
        position: absolute;
        top: .3rem;
        right: calc(100% + .25rem);
    }
}
.blockquote__content:after {
    display: inline;
    content: '„';
}