.adventure-slider {
    display: block;
    position: relative;
    z-index: 0;
}
.adventure-slider__embed {
    padding-bottom: 26%;
    min-height: calc(450rem/16);
}
.adventure-slider .slide__item__arrows {
    position: absolute;
    right:0;
    bottom:0;
}