
.link-box {
    display: block;
    border: 1px solid #e7e7e7;
    min-height: calc(82rem/16);
    box-shadow: calc(3rem/16) 0 calc(30rem/16) rgba(0,0,0,0.08);

    @media screen and (max-width: 767px) {
        min-height:auto;
    }
}

.link-box__item {
    display:inline-block;
    padding:0 calc(30rem/16);
    text-transform: uppercase;
    color:var(--color-headline-default);
    font-family:var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    font-size:calc(20rem/16);
    @media screen and (max-width: 767px) {
        display:block;
        padding:calc(20rem/16);
        text-align:center;
    }
}
.link-box__item--sm {
    font-size: 1rem;
}

.link-box__item__link {
    position:relative;
    padding-bottom:5px;
}

.link-box__item__link:after {
    content:'';
    display:block;
    position:absolute;
    width:100%;
    height:4px;
    background-color:var(--color-primary);
    transform:translateY(-10px);
    opacity:0;
    transition: all 0.2s ease;
    @media screen and (max-width: 767px) {
        left:0;
        right:0;
        margin:0 auto;
    }
}


.link-box__item__link:hover:after,
.link-box__item__link.active:after,
input:checked + .link-box__item__link:after {
    opacity:1;
    transform:translateY(0px);
}

.link-box__seperator {
    max-width: calc(870rem / 16);
    margin-left: auto;
    margin-right: auto;
    border-color: #979797
}