
.img-teaser:before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    content: '';
    display:block;
    position:absolute;
    width:100%;
    height:100%;
    z-index: 1;
}

