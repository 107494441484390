.badge-custom {
    width: calc(60rem/16);
    height:calc(60rem/16);
    text-align:center;
    display: flex;
    align-items: center;
    justify-content:center;
    @media screen and (max-width: 767px) {
        width: calc(50rem/16);
        height:calc(50rem/16);
    }
}

.badge-primary--light {
    background-color:var(--color-primary-light);
    color: var(--color-primary-contrast);
}

.badge-primary-border {
    border: 2px solid var(--color-primary);
}
.badge-blue {
    background: #175284;
    color: #fff;
}

.badge-custom--small {
    width: calc(40rem/16);
    height:calc(40rem/16);
    text-align:center;
    display: flex;
    align-items: center;
    justify-content:center;
    font-size:calc(12rem/16);
}

.badge--square {
    width:calc(40rem/16);
    height:calc(40rem/16);
    @media screen and (max-width: 767px) {
        width:calc(20rem/16);
        height:calc(20rem/16);
        font-size:calc(12rem/16);
    }
}

.badge--square__icon {
    font-size:calc(14rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(7rem/16);
    }
}

.badge-secondary-original {
    background-color: #6c757d;
}