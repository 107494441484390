
.number-spinner {
    border-radius: calc(4rem/16);
    overflow: hidden;
    margin-bottom:calc(10rem/16);
    display: inline-block;
    width: calc(120rem/16);
}
.number-spinner__button {
    background: var(--color-primary);
    color: #fff;
    padding:0 calc(10rem/16);
    display: flex;
    justify-content:center;
    align-items:center;
}
.number-spinner__input,
.input-group .number-spinner__input {
    min-width: 0;
    flex: 0 0 calc(50rem/16);
    height:calc(30rem/16);
    padding:0;
    text-align: center;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
}
.number-spinner__input::-webkit-inner-spin-button,
.number-spinner__input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.number-spinner .number-spinner__button {
    background: var(--color-primary);
    height:calc(30rem/16);
    width:calc(35rem/16);
}

.number-spinner .btn-icon__icon {
    font-size:calc(22rem/16);
    line-height: 1;
    color:#fff;
    font-family: var(--font-rounded-light);
    font-weight:var(--font-weight-rounded-light);
}

/* large */
.number-spinner--lg {
    width: calc(128rem / 16);
}
.number-spinner--lg .number-spinner__button {
    height: calc(38rem / 16);
    width: calc(38rem / 16);
}
.number-spinner--lg .number-spinner__input,
.number-spinner--lg .input-group .number-spinner__input {
    height:calc(38rem/16);
}

/* ticket */
.number-spinner--simple {
    margin-bottom:0;
}
.number-spinner--simple .number-spinner__button{
    height: calc(16rem / 16);
    width: calc(16rem / 16);
    border-radius: 50%;
    display: flex;
    padding: 0;
    background: #fff;
    border-color: var(--color-grey);
    color: var(--color-grey);
    @media screen and (max-width: 767px) {
        height: calc(22rem/16);
        width: calc(22rem/16);
    }
}
.number-spinner--simple .number-spinner__input {
    border-color: transparent;
    box-shadow: none;
    font-size: calc(14rem/16);
    flex: 0 0 calc(28rem/16);
}