
.accordion-icon--flip {
    transform: scale(1, -1);
    transition: all 0.2s ease;
}
.collapsed .accordion-icon--flip {
    transform: scale(1, 1);
}
.accordion-text--collapsed {
    display: none;
    visibility: hidden;
}
.accordion-text {
    display: inline-block;
    visibility: visible;
}
.accordion .collapsed .accordion-text--collapsed {
    display: inline-block;
    visibility: visible;
}

.accordion .collapsed .accordion-text {
    display: none;
    visibility: hidden;
}
