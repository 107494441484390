.nav-tabs--with-border {
    border-top:1px solid var(--color-headline-default);
}

.nav-tabs--with-border .nav-link.active, .nav-tabs--with-border .nav-link:hover, .nav-tabs--with-border .nav-link {
    border-left:1px solid var(--color-headline-default);
    border-radius:0;

    @media screen and (max-width: 767px) {
        border-right:1px solid var(--color-headline-default);
        border-bottom:1px solid var(--color-headline-default);
    }
}
.nav-tabs--with-border .nav-link:last-of-type{
    border-right:1px solid var(--color-headline-default);
}


.nav-tabs--active-filled .nav-link.active {
    background-color:var(--color-primary);
    color:#fff;
}

.nav-tabs--headline-styling {
    text-align:center;
    font-family:var(--font-default-bold);
    text-transform: uppercase;
    color:var(--color-headline-default);
}