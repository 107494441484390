.datepicker {
    position:relative;
}

.datepicker:not(.datepicker--inline) .ui-datepicker{
    width: calc(500rem/16);
}

.datepicker__input {
    position: relative;
    cursor:pointer;
}
.datepicker input {
    font-family: var(--font-rounded-light);
    color:var(--color-headline-default);
    letter-spacing: var(--font-letterspacing-default-bold);
    padding: calc(12rem/16) calc(20rem/16);
    line-height: calc(24rem/16);
}
.datepicker:not(.datepicker--inline):before {
    z-index: 1;
    font-family: "iconfont";
    content: var(--icon-calendar);
    speak: none;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: calc(22rem/16);
    padding: .4875rem 1.25rem;
    align-items: center;
    display: flex;
    pointer-events: none;

    @media screen and (min-width: 768px) {
        padding: .5175rem 1.25rem;
    }
}
.ui-datepicker {
    background: #fff;
    box-shadow: 0 0 6px rgba(0,0,0, .2);
    z-index: 9999!important; /** overwrite inline style */
    display: none;
}
.datepicker--inline {
    margin-top:calc(40rem/16);
    @media screen and (max-width: 767px) {
        margin-top:calc(10rem/16);
    }
}
@media screen and (max-width: 767px){
    .ui-datepicker{
        width: auto;
    }
}
.ui-datepicker-header {
    background: transparent;
    color: #fff;
    padding: calc(10rem/16);
    position: relative;
    height: calc(50rem/16);
    font-family:var(--font-rounded-light);
    font-weight:var(--font-weight-rounded-light);
    font-size:calc(36rem/16);
}
.datepicker--inline .ui-datepicker-header {
    height: calc(110rem/16);
    border-top:1px solid var(--color-text-default);
    border-bottom: 1px solid var(--color-text-default);
    @media screen and (max-width: 767px) {
        height:calc(60rem/16);
    }
}
.ui-datepicker .ui-state-default {
    text-align: center;
    width: calc(34rem/16);
    height: calc(34rem/16);
    padding: calc(4rem/16);
    font-size: calc(14rem/16);
}

.ui-datepicker .ui-state-hover {
    color: var(--color-primary);
}
.ui-datepicker-current-day {
    background: var(--color-primary);
}
.ui-datepicker .ui-state-disabled{
    color:var(--color-headline-default);
    background-color: #e2e2e2d4;
    opacity: 0.6;
    pointer-events: none;
}
.ui-datepicker .ui-state-active {
    color: #fff;
    background:none;
    z-index: 1;
    position: relative;
}
.ui-datepicker-today .ui-state-active {
    color: var(--color-primary);
}
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
    top: 0;
    bottom: 0;
    height: auto;
    color:var(--color-headline-default) !important;
    font-size:calc(10rem/16);
}
.ui-datepicker .ui-datepicker-next.ui-state-disabled,
.ui-datepicker .ui-datepicker-prev.ui-state-disabled {
    opacity:0.2;
}
.ui-datepicker-next.ui-state-hover,
.ui-datepicker-prev.ui-state-hover {
    background: transparent;
    color: #000;
}
.ui-datepicker .ui-datepicker-prev span {
    position: absolute;
    left: 10%;
    top: 50%;
    transform:translateY(-50%);
}
.datepicker--inline .ui-datepicker .ui-datepicker-prev span, .datepicker--inline .ui-datepicker .ui-datepicker-next span {
    color:var(--color-text-default);
}
.ui-datepicker .ui-datepicker-next span {
    position: absolute;
    right: 10%;
    top: 50%;
    transform:translateY(-50%);
}
.ui-datepicker .ui-datepicker-prev span:hover, .ui-datepicker .ui-datepicker-next span:hover {
    cursor: pointer;
}

.ui-datepicker .ui-datepicker-prev span:before {
    transform: rotate(180deg);
    display: block;
}
.ui-datepicker-calendar td {
    color: var(--color-headline-default);
    font-weight:var(--font-weight-rounded-bold);
    font-family:var(--font-rounded-bold);
    text-align:center;

}

.datepicker--inline .ui-datepicker-calendar td {
    @media screen and (min-width: 768px) {
        padding:calc(33rem/16) 0px;
    }
}

.datepicker--inline .ui-datepicker-group-first {
    border-right:1px solid var(--color-text-default);
}

.ui-datepicker-calendar th{
    font-family: var(--font-rounded-light);
    position: relative;
    text-transform: uppercase;
    padding: 0 calc(10rem/16);
    text-align:center;
    font-weight: var(--font-weight-rounded-medium);
    color: var(--color-grey);
    font-size: calc(14rem/16);

    @media screen and (max-width: 767px) {
        padding: 0 calc(7rem/16);
    }
}
table.ui-datepicker-calendar {
    margin: calc(10rem/16);
    margin-left:0;
    width: calc(100% - 1.25rem);
    @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0;
    }
}
.ui-datepicker-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:var(--color-headline-default);
    font-family: var(--font-rounded-medium);
    font-weight: var(--font-weight-rounded-medium);
    text-transform: uppercase;
    font-size:calc(16rem/16);
}
.datepicker--inline .ui-datepicker-title {
    font-size:calc(34rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(14rem/16);
    }
}
.ui-datepicker-week-end {
    height: calc(50rem/16);
    font-family:var(--font-rounded-light);
    font-weight:var(--font-weight-rounded-light);
    text-transform: uppercase;
}

.ui-datepicker-current-day, .is-datefrom, .is-dateto {
    position:relative;
}
.ui-datepicker-current-day:before, .is-datefrom:before, .is-dateto:before  {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
}
.ui-datepicker-current-day, .is-datefrom, .is-dateto {
    position: relative;
}
.ui-datepicker-today {
    background: transparent;
    color: var(--color-primary);
    position: relative;
}
.ui-datepicker-today.ui-datepicker-current-day {
    background-color: var(--color-primary);
    color:#fff;
}
.ui-datepicker-today.ui-datepicker-current-day .ui-state-active {
    color:#fff;
}
.ui-datepicker-today:after {
    content:'';
    position: absolute;
    bottom: calc(7rem/16);
    left:42%;
    border-radius: 50%;
    width: calc(5rem/16);
    height: calc(5rem/16);
    background-color: var(--color-primary-light);
    z-index: 2;
}
.is-datefrom, .is-dateto {
    background: var(--color-primary);
}

.is-datefrom, .is-dateto {
    background: var(--color-primary-light);
}
.is-datefrom, .is-dateto {
    background: var(--color-primary-light);

}
.ui-datepicker .is-in-range{
    background: rgba(87, 201, 236, 0.43);
}
.ui-datepicker .is-datefrom .ui-state-default, .ui-datepicker .is-dateto .ui-state-default  {
    position:relative;
    color:#fff;
    z-index:1;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
    width: 50%;
}
.ui-datepicker-multi .ui-datepicker-group {
    float: left;
}
.ui-datepicker.ui-datepicker-multi {
     width: auto!important;
}
.datepicker--inline.datepicker--inline-small .ui-datepicker-title {
    font-size: calc(26rem/16);
}
@media screen and (min-width: 768px) {
    .datepicker--inline.datepicker--inline-small .ui-datepicker-calendar td {
        padding: calc(19rem / 16) 0;
    }
}
.ticket-configuration__datepicker .ui-datepicker-calendar td {
    @media screen and (max-width: 767px) {
        width: calc(38rem/16);
        height: calc(38rem/16);
    }
}
.ticket-configuration__datepicker .ui-datepicker {
    @media screen and (max-width: 767px) {
        box-shadow: none;
    }
}
.ticket-configuration__datepicker .ui-state-default {
    width: calc(60rem/16);
    height: calc(60rem/16);
    display: flex;
    padding:0;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        width: calc(38rem/16);
        height: calc(38rem/16);
    }
}
.ticket-configuration__datepicker .ui-datepicker-prev span {
    left:0;
    font-size: calc(8rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.ticket-configuration__datepicker .ui-datepicker-next span {
    right:0;
    font-size: calc(8rem/16);
    @media screen and (max-width: 767px) {
        font-size: calc(14rem/16);
    }
}
.ticket-configuration__datepicker .ui-datepicker-title {
    font-size: calc(14rem/16);
    text-transform: uppercase;
    font-weight: var(--font-weight-rounded-medium);
    color: var(--color-headline-default);
}
.ticket-configuration__datepicker .ui-datepicker-calendar th {
    font-weight: var(--font-weight-rounded-medium);
    color: var(--color-grey);
    font-size: calc(14rem/16);
    @media screen and (max-width: 767px) {
        padding: 0;
        height: calc(30rem/16);
    }
}
.ticket-configuration__datepicker .is-season--lightblue,
.ticket-configuration__datepicker .is-season--middleblue,
.ticket-configuration__datepicker .is-season--blue,
.ticket-configuration__datepicker .is-season--darkblue{
    position: relative;
}
.ticket-configuration__datepicker .is-season--lightblue:before,
.ticket-configuration__datepicker .is-season--middleblue:before,
.ticket-configuration__datepicker .is-season--blue:before,
.ticket-configuration__datepicker .is-season--darkblue:before{
    content: '';
    height: 3px;
    width: calc(100% - 4px);
    position: absolute;
    bottom: 0;
    z-index: 999;
    left: calc(2rem/16);
    right: calc(2rem/16);
    display: block;
    top:initial;
}
.ticket-configuration__datepicker .is-season--lightblue:before {
     background-color: var(--color-season-light-blue);
 }
.ticket-configuration__datepicker .is-season--middleblue:before {
    background-color: var(--color-season-middle-blue);
}
.ticket-configuration__datepicker .is-season--blue:before {
    background-color: var(--color-season-blue);
}
.ticket-configuration__datepicker .is-season--darkblue:before {
     background-color: var(--color-season-dark-blue);
 }
.ticket-configuration__datepicker .ui-datepicker-group-first .ui-datepicker-title {
    left: calc(20rem/16);
    transform: translateY(-50%);
}
.ticket-configuration__datepicker .ui-datepicker-group-last .ui-datepicker-title {
    right: calc(20rem/16);
    text-align:right;
    transform: translateY(-50%);
}
.ticket-configuration__datepicker .is-price-tendency--down:after,
.ticket-configuration__datepicker .is-price-tendency--straight:after,
.ticket-configuration__datepicker .is-price-tendency--up:after {
    content: '';
    position: absolute;
    bottom: calc(5rem/16);
    right:calc(5rem/16);
    display: block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0);
    transform: translate(0);
    font-size: calc(8rem/16);
}

.ticket-configuration__datepicker .is-price-tendency--down:after {
    content: var(--icon-arrow-down);
}
.ticket-configuration__datepicker .is-price-tendency--straight:after {
    content: var(--icon-arrow-straight);
}
.ticket-configuration__datepicker .is-price-tendency--up:after {
    content: var(--icon-arrow-up);
}
