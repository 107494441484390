.btn {
    font-family: var(--font-rounded-light);
}
.btn-default {
    background-color: #FFFFFF;
    border: 1px solid #E7E7E7;
}
.btn-primary {
    border:none;
}
.btn-grey {
    color:#fff;
    background-color:#4a535c;
}
.btn-grey:hover {
    color:#fff;
    background-color: var(--color-dark);
}
.btn-facebook {
    background-color:#005AE1;
    color:#fff;
}
.btn-outline-facebook {
    border-color: #005AE1;
    background-color:#fff;
    color:#005AE1;
}
.btn-googlePlus {
    background-color:#CE2318;
    color:#fff;
}
.btn--has-box-shadow {
    box-shadow:  3px 0 12px rgba(0,0,0,0.06);
}
.btn__icon--left {
    margin-left: calc(-10rem / 16);
    margin-right: calc(10rem / 16);
    vertical-align: -0.1em;
}
.btn__icon--right {
    font-size:calc(13rem/16);
    margin-left: calc(8rem/16);
}
.btn__icon--left-lg {
    margin-left: calc(-10rem / 16);
    margin-right: calc(10rem / 16);
    vertical-align: -0.25em;
    font-size: calc(23rem / 16);
}
.btn-ghost,
.btn-ghost:hover,
.btn-ghost:focus,
.btn-ghost:active {
    color: var(--color-ghost-btn);
    background-color: transparent;
    border: 1px solid;
}
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}
.btn-download {
    position:relative;
}
.btn-download:after {
    content:var(--icon-download);
    display:block;
    position:absolute;
    font-family: iconfont;
    width:calc(30rem/16);
    font-size: 29px;
    right: calc(15rem/16);
    top: 50%;
    transform: translateY(-50%);
}
.btn--no-styling {
    background-color:transparent;
    padding:0px;
    border:none;
    box-shadow:none;
}
.btn.btn-back-to-zermatt {
    position: absolute;
    top: calc(69rem/16);
    left: calc(5rem/16);
    z-index:3;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background: rgba(255,255,255,0.2);
    opacity:0.7;
    transition: 0.2s ease;
    @media screen and (max-width: 767px) {
        top: calc(105rem/16);
        background: rgba(255,255,255,0.8);
        font-size:calc(13rem/16);
        z-index: 9;
    }
}
.btn-back-to-zermatt:hover {
    background:var(--color-primary);
    color:#fff;
    border-color:#fff;
    opacity:1;
}
.btn-outline-dark {
    text-transform:uppercase;
    font-family: var(--font-default-bold);
}
.btn-outline-dark:hover {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    background: transparent;
}
.btn-md {
    padding: calc(13rem/16) calc(37rem/16);
    line-height:1;
    letter-spacing: calc(2rem/16);
}
.btn-tooltip:hover {
    background-color: transparent;
    color: var(--color-primary);
}
.btn-outline-primary:hover {
    color:#fff;
}
.hero-slide__cta-button {
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}