.overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    transform: translateX(100%);
    transition: transform 180ms ease-out, visibility 0.001ms 180ms, opacity 180ms ease;
    background: white;
    z-index: 1100;
    overflow: hidden;
    will-change: transform;
    visibility: hidden;
    opacity: 0;
    color: var(--color-text-default);
}

.overlay.is-open {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    transition: transform 180ms ease-out, visibility 0.001ms, opacity 180ms ease;
}
.overlay__header {
    height: 60px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 500;
    display: flex;
    align-items: center;
    font-size: 17px;
    font-family: var(--font-default-bold);
    flex: none;
}
.overlay__header--filters {
    box-shadow: none;
}
.overlay__header-close {
    position: absolute;
    right:calc(10rem/16);
    top:calc(20rem/16);
}
.overlay__header-title {
    text-align:center;
    color: var(--color-primary);
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}
.overlay__footer-submit-btn {
    font-size: calc(14rem/16);
    display: flex;
    width: 100%;
    background: var(--color-secondary);
    border: none;
    color: #fff;
    text-align: center;
    justify-content: center;
    padding: calc(10rem/16);
    text-transform: uppercase;
}
/*todo*/
.overlay__content {
    flex: auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.overlay__header-item {
    padding: 20px;
    min-width: 0; /*to allow text-truncate*/
}
.overlay__header-item + .overlay__header-item--bordered {
    border-left: 1px solid #706F6F;
}

.overlay__header-submit-btn {
    color: var(--color-primary);
}
.overlay__header-submit-btn:disabled,
.overlay__header-submit-btn.disabled {
    color: #706F6F;
}
.overlay__footer {
    flex: none;
}
.overlay__container {
    width: calc(630rem / 16);
    max-width: 100%;
    margin: 0 auto;
    padding-left: calc(20rem / 16);
    padding-right: calc(20rem / 16);
}

@media screen and (min-width: 768px) {
    .overlay {
        box-shadow: 4px 4px 27px rgba(0, 0, 0, 0.05);
    }
    .overlay--left,
    .overlay--right {
        width: calc(800rem / 16);
    }
    .overlay--left {
        right: auto;
        transform: translateX(-100%);
    }
    .overlay--right {
        left: auto;
        transform: translateX(-100%);
    }
    .overlay--cart {
        width: calc(600rem/16);
        transform: translateX(-100%);
        z-index: 100;
        padding-top: calc(70rem/16);

    }
    .overlay--right {
        right:0;
        left:auto;
        transform: translateX(100%);
    }
}