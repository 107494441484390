.cta-img-slide__title {
    font-size: calc(24rem/16);
    text-shadow: 0 0 calc(12rem/16) rgba(0, 0, 0, 0.81);
    font-family: var(--font-decorative);
    font-weight: normal;
    letter-spacing:var(--font-letterspacing-decorative);
    line-height: 1.1;
}
@media screen and (min-width: 768px) {
    .cta-img-slide__title {
        line-height:1;
        font-size: calc(100rem/16);
        text-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    }
}
.cta-img-slide__subtitle {
    font-size:calc(40rem/16);
    font-family:var(--font-default-bold);
    text-transform: uppercase;
    line-height:1;
    @media screen and (max-width: 767px) {
        font-size:calc(20rem/16);
    }
}
.cta-img-slide--light:before {
    content:'';
    display:block;
    position:absolute;
    left:0;
    right:0;
    width:100%;
    height:100%;
    background-color:rgba(255,255,255,0.7);
    z-index: 1;
}
.cta-img-slide--light .img-slide__content {
    z-index: 2;
}
.cta-img-slide--light .cta-img-slide__title {
    font-size:calc(40rem/16);
    text-shadow:none;
    text-transform: uppercase;
    font-family:var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size:calc(30rem/16);
    }
}

.cta-img-slide--light .cta-img-slide__subtitle {
    color:var(--color-primary);
    font-size:calc(20rem/16);
    line-height:calc(29/20);
    @media screen and (max-width: 767px) {
        font-size:calc(18rem/16);
    }
}