.ticket-detail-intro {
    padding-top: calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(20rem/16);
    }
}
.ticket-detail-intro__title {
    font-size: calc(40rem/16);
    line-height: calc(50/40);
    font-family: var(--font-decorative);
    margin-bottom: calc(20rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom: calc(20rem/16);
        font-size: calc(28rem/16);
    }
}
.ticket-detail-intro__subtitle {
    color: var(--color-primary);
    font-size: calc(14rem/16);
    font-weight: var(--font-weight-rounded-medium);
    text-transform: uppercase;
}
.ticket-detail-intro__buttons {
    margin-top: calc(30rem / 16);
    @media screen and (max-width: 767px) {
        margin-top: calc(20rem / 16);
    }
}
.ticket-detail-intro__price {
    font-size: calc(24rem/16);
    color: var(--color-headline-default);
    font-weight: var(--font-weight-rounded-medium);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem/16);
    }
}
.ticket-detail-intro__price--discount {
    color: var(--color-grey);
    font-size: calc(16rem/16);
    margin-left: calc(5rem/16);
    text-decoration: line-through;
}
.ticket-detail-intro__price--badge {
    background: var(--color-primary);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding:0 .5rem;
    font-family:var(--font-default);
    color:var(--color-white);
    margin-left: calc(5rem/16);
    vertical-align:top;
}
.ticket-detail-intro__price--badge span {
    transform:scale(.5);
}
.ticket-detail-intro__error-text {
    color: var(--color-danger);
    font-size: calc(15rem/16);
    margin-bottom: calc(20rem/16);
    line-height: calc(30/15);
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
        line-height: 1.2;
    }
}
.ticket-detail-intro__discount {
    color: var(--color-headline-default);
    text-transform: uppercase;
    font-size: calc(14rem/16);
    display: flex;
    align-items:center;
    font-family: var(--font-rounded-bold);
    font-weight: var(--font-weight-rounded-bold);
}
.ticket-detail-intro__discount-icon {
    color: var(--color-primary);
    font-size: calc(30rem/16);
}
@media (min-width: 768px) {
    .ticket-detail-intro__sticky-add-to-cart-btn {
        display: none;
    }
}
@media (max-width: 767px) {
    .ticket-detail-intro__sticky-add-to-cart-btn {
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        transform: translateY(100%);
        transition: transform .2s;
        z-index: 1;
        height: calc(55rem/16);
    }

    .ticket-detail-intro__sticky-add-to-cart-btn.show {
        transform: translateY(0);
        z-index: 10;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    }

    .ticket-detail-intro__sticky-add-to-cart-btn .sf-toolbar {
        display: none;
    }
}
