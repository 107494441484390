@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1705396765427/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1705396765427/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1705396765427/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-3S:before { content: "\EA01" }
.icon-arrow-bold:before { content: "\EA02" }
.icon-arrow-down:before { content: "\EA03" }
.icon-arrow-straight:before { content: "\EA04" }
.icon-arrow-up:before { content: "\EA05" }
.icon-arrow:before { content: "\EA06" }
.icon-best-price:before { content: "\EA07" }
.icon-bin:before { content: "\EA08" }
.icon-calendar:before { content: "\EA09" }
.icon-check:before { content: "\EA0A" }
.icon-checkmark:before { content: "\EA0B" }
.icon-clock:before { content: "\EA0C" }
.icon-close:before { content: "\EA0D" }
.icon-complete:before { content: "\EA0E" }
.icon-corona:before { content: "\EA0F" }
.icon-data:before { content: "\EA10" }
.icon-delete:before { content: "\EA11" }
.icon-distance:before { content: "\EA12" }
.icon-download:before { content: "\EA13" }
.icon-dynamic:before { content: "\EA14" }
.icon-edit-new:before { content: "\EA15" }
.icon-edit:before { content: "\EA16" }
.icon-elements:before { content: "\EA17" }
.icon-email:before { content: "\EA18" }
.icon-event-calendar:before { content: "\EA19" }
.icon-facebook:before { content: "\EA1A" }
.icon-facility-beginner-park:before { content: "\EA1B" }
.icon-facility-board:before { content: "\EA1C" }
.icon-facility-cable-railway:before { content: "\EA1D" }
.icon-facility-chair:before { content: "\EA1E" }
.icon-facility-char-big:before { content: "\EA1F" }
.icon-facility-draglift:before { content: "\EA20" }
.icon-facility-glacier-palace:before { content: "\EA21" }
.icon-facility-gondola-big:before { content: "\EA22" }
.icon-facility-gondola:before { content: "\EA23" }
.icon-facility-gravitiy-park:before { content: "\EA24" }
.icon-facility-info-cube:before { content: "\EA25" }
.icon-facility-palace:before { content: "\EA26" }
.icon-facility-photopoint:before { content: "\EA27" }
.icon-facility-rack-railway:before { content: "\EA28" }
.icon-facility-restaurant:before { content: "\EA29" }
.icon-facility-shuttle:before { content: "\EA2A" }
.icon-facility-sleigh-way:before { content: "\EA2B" }
.icon-facility-snowtube:before { content: "\EA2C" }
.icon-facility-view:before { content: "\EA2D" }
.icon-facility-viewing-platform:before { content: "\EA2E" }
.icon-faq:before { content: "\EA2F" }
.icon-filter:before { content: "\EA30" }
.icon-fix-path:before { content: "\EA31" }
.icon-fullscreen:before { content: "\EA32" }
.icon-gift-card:before { content: "\EA33" }
.icon-gondola:before { content: "\EA34" }
.icon-google:before { content: "\EA35" }
.icon-heart-filled:before { content: "\EA36" }
.icon-heart:before { content: "\EA37" }
.icon-info:before { content: "\EA38" }
.icon-instagram:before { content: "\EA39" }
.icon-kickbike:before { content: "\EA3A" }
.icon-linkedin:before { content: "\EA3B" }
.icon-live:before { content: "\EA3C" }
.icon-material_badge:before { content: "\EA3D" }
.icon-matterhorn--white:before { content: "\EA3E" }
.icon-matterhorn:before { content: "\EA3F" }
.icon-menu:before { content: "\EA40" }
.icon-message:before { content: "\EA41" }
.icon-mute:before { content: "\EA42" }
.icon-panomap:before { content: "\EA43" }
.icon-pause:before { content: "\EA44" }
.icon-paysafe:before { content: "\EA45" }
.icon-pdf-download:before { content: "\EA46" }
.icon-pdf-preview:before { content: "\EA47" }
.icon-percent:before { content: "\EA48" }
.icon-phone:before { content: "\EA49" }
.icon-piste:before { content: "\EA4A" }
.icon-play:before { content: "\EA4B" }
.icon-plus:before { content: "\EA4C" }
.icon-preis:before { content: "\EA4D" }
.icon-resolution:before { content: "\EA4E" }
.icon-save:before { content: "\EA4F" }
.icon-search:before { content: "\EA50" }
.icon-shipping:before { content: "\EA51" }
.icon-shopping-cart:before { content: "\EA52" }
.icon-skipass:before { content: "\EA53" }
.icon-slope:before { content: "\EA54" }
.icon-snowflake:before { content: "\EA55" }
.icon-star-empty:before { content: "\EA56" }
.icon-star-full:before { content: "\EA57" }
.icon-statistic:before { content: "\EA58" }
.icon-status:before { content: "\EA59" }
.icon-temp-high:before { content: "\EA5A" }
.icon-temp-low:before { content: "\EA5B" }
.icon-time:before { content: "\EA5C" }
.icon-trip-both:before { content: "\EA5D" }
.icon-trip-single:before { content: "\EA5E" }
.icon-trophy:before { content: "\EA5F" }
.icon-twitter:before { content: "\EA60" }
.icon-uhr:before { content: "\EA61" }
.icon-unmute:before { content: "\EA62" }
.icon-user-new:before { content: "\EA63" }
.icon-user-rounded:before { content: "\EA64" }
.icon-user:before { content: "\EA65" }
.icon-warning:before { content: "\EA66" }
.icon-weather-01:before { content: "\EA67" }
.icon-weather-02:before { content: "\EA68" }
.icon-weather-03:before { content: "\EA69" }
.icon-weather-04:before { content: "\EA6A" }
.icon-weather-05:before { content: "\EA6B" }
.icon-weather-06:before { content: "\EA6C" }
.icon-weather-07:before { content: "\EA6D" }
.icon-weather-08:before { content: "\EA6E" }
.icon-weather-09:before { content: "\EA6F" }
.icon-weather-10:before { content: "\EA70" }
.icon-weather-11:before { content: "\EA71" }
.icon-weather-12:before { content: "\EA72" }
.icon-weather-13:before { content: "\EA73" }
.icon-weather-14:before { content: "\EA74" }
.icon-weather-15:before { content: "\EA75" }
.icon-weather-16:before { content: "\EA76" }
.icon-weather-17:before { content: "\EA77" }
.icon-weather-21:before { content: "\EA78" }
.icon-weather-22:before { content: "\EA79" }
.icon-weather-23:before { content: "\EA7A" }
.icon-weather-24:before { content: "\EA7B" }
.icon-weather-25:before { content: "\EA7C" }
.icon-weather-26:before { content: "\EA7D" }
.icon-weather-27:before { content: "\EA7E" }
.icon-weather-28:before { content: "\EA7F" }
.icon-weather-29:before { content: "\EA80" }
.icon-weather-30:before { content: "\EA81" }
.icon-weather-31:before { content: "\EA82" }
.icon-weather-32:before { content: "\EA83" }
.icon-weather-33:before { content: "\EA84" }
.icon-weather-34:before { content: "\EA85" }
.icon-weather-35:before { content: "\EA86" }
.icon-weather-36:before { content: "\EA87" }
.icon-weather-37:before { content: "\EA88" }
.icon-weather:before { content: "\EA89" }
.icon-webcam-image:before { content: "\EA8A" }
.icon-webcam-photo:before { content: "\EA8B" }
.icon-webcam-roundshot:before { content: "\EA8C" }
.icon-webcam-video:before { content: "\EA8D" }
.icon-webcam:before { content: "\EA8E" }
.icon-whatsapp:before { content: "\EA8F" }
.icon-wifi:before { content: "\EA90" }
.icon-wind-nord-ost:before { content: "\EA91" }
.icon-wind-nord-west:before { content: "\EA92" }
.icon-wind-nord:before { content: "\EA93" }
.icon-wind-ost:before { content: "\EA94" }
.icon-wind-sued-ost:before { content: "\EA95" }
.icon-wind-sued-west:before { content: "\EA96" }
.icon-wind-sued:before { content: "\EA97" }
.icon-wind-west:before { content: "\EA98" }
.icon-xing:before { content: "\EA99" }
.icon-youtube:before { content: "\EA9A" }


:root {
--icon-3S: "\EA01";
    --icon-arrow-bold: "\EA02";
    --icon-arrow-down: "\EA03";
    --icon-arrow-straight: "\EA04";
    --icon-arrow-up: "\EA05";
    --icon-arrow: "\EA06";
    --icon-best-price: "\EA07";
    --icon-bin: "\EA08";
    --icon-calendar: "\EA09";
    --icon-check: "\EA0A";
    --icon-checkmark: "\EA0B";
    --icon-clock: "\EA0C";
    --icon-close: "\EA0D";
    --icon-complete: "\EA0E";
    --icon-corona: "\EA0F";
    --icon-data: "\EA10";
    --icon-delete: "\EA11";
    --icon-distance: "\EA12";
    --icon-download: "\EA13";
    --icon-dynamic: "\EA14";
    --icon-edit-new: "\EA15";
    --icon-edit: "\EA16";
    --icon-elements: "\EA17";
    --icon-email: "\EA18";
    --icon-event-calendar: "\EA19";
    --icon-facebook: "\EA1A";
    --icon-facility-beginner-park: "\EA1B";
    --icon-facility-board: "\EA1C";
    --icon-facility-cable-railway: "\EA1D";
    --icon-facility-chair: "\EA1E";
    --icon-facility-char-big: "\EA1F";
    --icon-facility-draglift: "\EA20";
    --icon-facility-glacier-palace: "\EA21";
    --icon-facility-gondola-big: "\EA22";
    --icon-facility-gondola: "\EA23";
    --icon-facility-gravitiy-park: "\EA24";
    --icon-facility-info-cube: "\EA25";
    --icon-facility-palace: "\EA26";
    --icon-facility-photopoint: "\EA27";
    --icon-facility-rack-railway: "\EA28";
    --icon-facility-restaurant: "\EA29";
    --icon-facility-shuttle: "\EA2A";
    --icon-facility-sleigh-way: "\EA2B";
    --icon-facility-snowtube: "\EA2C";
    --icon-facility-view: "\EA2D";
    --icon-facility-viewing-platform: "\EA2E";
    --icon-faq: "\EA2F";
    --icon-filter: "\EA30";
    --icon-fix-path: "\EA31";
    --icon-fullscreen: "\EA32";
    --icon-gift-card: "\EA33";
    --icon-gondola: "\EA34";
    --icon-google: "\EA35";
    --icon-heart-filled: "\EA36";
    --icon-heart: "\EA37";
    --icon-info: "\EA38";
    --icon-instagram: "\EA39";
    --icon-kickbike: "\EA3A";
    --icon-linkedin: "\EA3B";
    --icon-live: "\EA3C";
    --icon-material_badge: "\EA3D";
    --icon-matterhorn--white: "\EA3E";
    --icon-matterhorn: "\EA3F";
    --icon-menu: "\EA40";
    --icon-message: "\EA41";
    --icon-mute: "\EA42";
    --icon-panomap: "\EA43";
    --icon-pause: "\EA44";
    --icon-paysafe: "\EA45";
    --icon-pdf-download: "\EA46";
    --icon-pdf-preview: "\EA47";
    --icon-percent: "\EA48";
    --icon-phone: "\EA49";
    --icon-piste: "\EA4A";
    --icon-play: "\EA4B";
    --icon-plus: "\EA4C";
    --icon-preis: "\EA4D";
    --icon-resolution: "\EA4E";
    --icon-save: "\EA4F";
    --icon-search: "\EA50";
    --icon-shipping: "\EA51";
    --icon-shopping-cart: "\EA52";
    --icon-skipass: "\EA53";
    --icon-slope: "\EA54";
    --icon-snowflake: "\EA55";
    --icon-star-empty: "\EA56";
    --icon-star-full: "\EA57";
    --icon-statistic: "\EA58";
    --icon-status: "\EA59";
    --icon-temp-high: "\EA5A";
    --icon-temp-low: "\EA5B";
    --icon-time: "\EA5C";
    --icon-trip-both: "\EA5D";
    --icon-trip-single: "\EA5E";
    --icon-trophy: "\EA5F";
    --icon-twitter: "\EA60";
    --icon-uhr: "\EA61";
    --icon-unmute: "\EA62";
    --icon-user-new: "\EA63";
    --icon-user-rounded: "\EA64";
    --icon-user: "\EA65";
    --icon-warning: "\EA66";
    --icon-weather-01: "\EA67";
    --icon-weather-02: "\EA68";
    --icon-weather-03: "\EA69";
    --icon-weather-04: "\EA6A";
    --icon-weather-05: "\EA6B";
    --icon-weather-06: "\EA6C";
    --icon-weather-07: "\EA6D";
    --icon-weather-08: "\EA6E";
    --icon-weather-09: "\EA6F";
    --icon-weather-10: "\EA70";
    --icon-weather-11: "\EA71";
    --icon-weather-12: "\EA72";
    --icon-weather-13: "\EA73";
    --icon-weather-14: "\EA74";
    --icon-weather-15: "\EA75";
    --icon-weather-16: "\EA76";
    --icon-weather-17: "\EA77";
    --icon-weather-21: "\EA78";
    --icon-weather-22: "\EA79";
    --icon-weather-23: "\EA7A";
    --icon-weather-24: "\EA7B";
    --icon-weather-25: "\EA7C";
    --icon-weather-26: "\EA7D";
    --icon-weather-27: "\EA7E";
    --icon-weather-28: "\EA7F";
    --icon-weather-29: "\EA80";
    --icon-weather-30: "\EA81";
    --icon-weather-31: "\EA82";
    --icon-weather-32: "\EA83";
    --icon-weather-33: "\EA84";
    --icon-weather-34: "\EA85";
    --icon-weather-35: "\EA86";
    --icon-weather-36: "\EA87";
    --icon-weather-37: "\EA88";
    --icon-weather: "\EA89";
    --icon-webcam-image: "\EA8A";
    --icon-webcam-photo: "\EA8B";
    --icon-webcam-roundshot: "\EA8C";
    --icon-webcam-video: "\EA8D";
    --icon-webcam: "\EA8E";
    --icon-whatsapp: "\EA8F";
    --icon-wifi: "\EA90";
    --icon-wind-nord-ost: "\EA91";
    --icon-wind-nord-west: "\EA92";
    --icon-wind-nord: "\EA93";
    --icon-wind-ost: "\EA94";
    --icon-wind-sued-ost: "\EA95";
    --icon-wind-sued-west: "\EA96";
    --icon-wind-sued: "\EA97";
    --icon-wind-west: "\EA98";
    --icon-xing: "\EA99";
    --icon-youtube: "\EA9A";
    
}