
.modal {
    z-index: 9999;
    color:var(--color-text-default);
}
.modal-open {
    @media screen and (max-width: 767px) {
        height:100%;
    }
}
.modal-lg.modal-dialog {
    max-width: 800px;
    @media screen and (max-width: 820px) {
        border:none;
        margin: 10px;
        width: calc(100% - 20px);
        max-width: 100%;
    }
}
.modal-lg-shop.modal-dialog {
    max-width: calc(1050rem/16);
    width: 100%;
    @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0;
        height: 100%;
    }
}
.modal-md-shop.modal-dialog {
    max-width: calc(750rem/16);
    width: 100%;
    @media screen and (max-width: 767px) {
        width: 100%;
        margin: 0;
    }
}
.modal-lg-shop .modal-content {
    padding: calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(5rem/16);
        height: 100%;
        overflow: hidden;
    }
}
.modal-lg-shop .modal-content__inner {
    @media screen and (max-width: 767px) {
        flex: auto;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 0 calc(15rem/16) calc(10rem/16);
    }
}
.modal-md-shop .modal-content {
    padding: calc(60rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16);
    }
}
.modal-content {
    @media screen and (max-width: 820px) {
        border:none;
    }
}
.custom-modal {
    background: linear-gradient(180deg, rgba(25, 30, 48, .7), rgba(0, 0, 0, .7))
}

.modal-xl.modal-dialog {
    max-width: calc(1080rem/16);
    @media screen and (max-width: 1120px) {
        margin: 10px;
        width: calc(100% - 20px) !important;
        border:none;
    }
}

.modal .close {
    @media screen and (max-width: 767px) {
        //padding:calc(5rem/16);
        color:var(--color-primary);
    }
}