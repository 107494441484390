.popup-window-loading{
    font-family: var(--font-decorative);
}

.popup__text{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 50%;
}

.popup__close{
    position: absolute;
    top: 0;
    right: 0;
}

.popup.modal-dialog {
    @media (min-width: 576 px){
        margin: 0 auto;
    }
}

.popup .popup__badge{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.popup .popup__badge-background{
    background-image: url("/static/img/modal/material_badge.svg");
    width: 200px;
    height: 200px;
    display: flex;
    background-repeat: no-repeat;
    background-size: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: auto;

    @media screen and (max-width: 576px){
        width: 100px;
        height: 100px;
    }
}

.popup .popup__badge-text{
    font-size: calc(40rem / 16);
    @media screen and (max-width: 576px){
        font-size: calc(20rem / 16);
    }
}

.popup-window-loading .close{
    opacity: 1;
}

.text-white.popup__badge-text{
    text-shadow: 0 0 10px #000;
}