
.blurred-box__content {
    padding:calc(20rem/16);
    overflow:hidden;
    position:relative;
    box-shadow: 0 2px 60px 0 rgba(0,0,0,0.5);
    @media screen and (max-width: 767px) {
        padding:calc(5rem/16);
    }
}

.blurred-box__bg {
    background-position: center;
    position:absolute;
    background-size: unset;
    top:0;
    left:0;
    right:0;
    width:100%;
    height:100%;
    -webkit-filter: blur(1px);
    box-shadow: 0 2px 60px 0 rgba(0,0,0,0.5);
    @media screen and (max-width: 1600px) {
        background-size: cover;

    }
}

.blurred-box__bg:before {
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    right:0;
    width:100%;
    height:100%;
    background-color:rgba(255,255,255,0.1);
}

.blurred-svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    box-shadow: 0 2px 60px 0 rgba(0, 0, 0, 0.5);
}

.blurred-svg__rect {
    width: 100%;
    height: 270px;
    transform: translateY(calc(50% - 135px));
}

.blurred-box__bottom-row .icon {
    font-size: calc(40rem / 16);
    @media screen and (max-width: 767px) {
        font-size:calc(16rem/16);
    }
    @media screen and (max-width: 1199px) {
        font-size:calc(24rem/16);
    }
}

.blurred-box__user {
    font-size:calc(50rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    line-height:1;

    @media screen and (max-width: 767px) {
        margin-top:calc(10rem/16);
        font-size:calc(20rem/16);
        text-align:center;
    }
    @media screen and (max-width: 1199px) {
        font-size:calc(30rem/16);
    }
}