.card {
    border-color:var(--bg-lightgrey);
}
.card-header {
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) 0;
    }
}
.card-icon {
    position:relative;
}
.card-icon--bg {
    height:calc(50rem/16);
    width:calc(50rem/16);
    text-align:center;
    display:flex;
    align-items:center;
    color:#fff;
    justify-content:center;
    border-radius: 50%;
    margin:0 auto;
}

.card-icon .icon {
    font-size:calc(32rem/16);
}


.card-icon--avalanche {
    z-index: 1;
    position: relative;
    height: calc(180rem/16);
    @media screen and (max-width: 767px) {
        height: calc(100rem/16);
    }
}

.card-icon--avalanche-3 {
    margin-left:calc(36rem/16);
    @media screen and (max-width: 767px) {
        margin-left: calc(20rem/16);
    }
}

.card-icon--avalanche-4 {
    margin-left:calc(68rem/16);
    @media screen and (max-width: 767px) {
        margin-left: calc(40rem/16);
    }
}

.card-icon--avalanche-5 {
    margin-left:calc(68rem/16);
    @media screen and (max-width: 767px) {
        margin-left: calc(40rem/16);
    }
}


.snowreport-card-subtitle {
    font-size:calc(40rem/16);
    line-height:1;
}

.card-body {
    @media screen and (max-width: 767px) {
        padding:calc(20rem/16) 0;
    }
}


.snowreport-card-body {
    padding:calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding:calc(15rem/16);
    }
}