
.personal-area__description {
    max-width: calc(700rem / 16);
    width: 100%;
    margin: 0 auto;
}

.personal-area__filter-wrapper {
    text-align: center;
    margin: 0 auto;
    padding: calc(60rem / 16) 0;
    @media screen and (max-width: 767px) {
        padding: calc(10rem / 16) 0;
    }
}

.personal-area__filter-text {
    @media screen and (max-width: 767px) {
        display: block;
        margin-top: calc(0rem / 16);
        margin-bottom: calc(10rem / 16);

    }
}

.select-topic {
    display: inline-block;
    margin-top: calc(10rem / 16);
}

.select-topic--hidden {
    display: none;
}
