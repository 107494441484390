.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}.custom-radio:after {
     clear: both;
 }
.custom-radio__input {
    position: absolute;
    width:calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: 1rem;
    height: 1rem;
    margin-top: .2em;
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background: var(--color-primary);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}
.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
    overflow: hidden;
}
/* Sometimes there is a problem with the element length.
*  The box element seems to be ignored during the with calculation.
*  This happens for instance inside a dropdown */
.custom-radio__text--truncate-fix {
    padding-right: 1.5em;
}

.custom-radio--btn-style {
    cursor:pointer;
    width: calc(40rem/16);
    height: calc(40rem/16);
    position:relative;
    display:inline-block;
}

.custom-radio--btn-style:before,
.custom-radio--btn-style:after {
    content: " ";
    display: table;
}.custom-radio--btn-style:after {
     clear: both;
 }
.custom-radio__input--btn-style {
    position: absolute;
    width:calc(1rem/16);
    height: calc(1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box--btn-style {
    position: relative;
    float: left;
    overflow: hidden;
    border: 1px solid #706f6f;
    background: #ffffff;
    width: calc(40rem/16);
    height: calc(40rem/16);
}
.custom-radio__box--btn-style:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    background: var(--color-primary);
    visibility: hidden;
}
.custom-radio__text--btn-style {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-radio__input--btn-style:checked ~ .custom-radio__box--btn-style:before {
    visibility: visible;
}
.custom-radio__input--btn-style:checked ~ .custom-radio__text--btn-style, .custom-radio__input--btn-style:checked ~ .custom-radio__box--btn-style > .custom-radio__text--btn-style{
    color:#fff;
}
.custom-radio__input--btn-style:focus ~ .custom-radio__box--btn-style, .custom-radio__input--btn-style:checked ~ .custom-radio__box--btn-style {
    border-color: var(--color-primary);
}
.has-error .custom-radio__box--btn-style {
    border-color: var(--color-danger)
}

.custom-radio--btn-style-w-auto {
    width: auto;
    position: relative;
}
.custom-radio--btn-style-w-auto .custom-radio__box--btn-style {
    overflow: visible;
    width: auto;
    cursor:pointer;
}
.custom-radio--btn-style-w-auto .custom-radio__text--btn-style {
    position: relative;
    overflow: visible;
    padding-left: calc(10rem/16);
    padding-right: calc(10rem/16);
}
.custom-radio--btn-style-big .custom-radio__text--btn-style {
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    font-size: calc(16rem/16);
    letter-spacing: calc(2rem/16);
    padding: calc(13rem/16) calc(37rem/16);
}
.custom-radio__text__img {
    height: calc(30rem / 16);
    width: auto;

    @media screen and (min-width: 768px) {
        height: calc(40rem / 16);
    }
}
.custom-img-radio__input {
    display: none;
}
.custom-img-radio__input {

}