.panorama__container:before {
    padding-top: 100%;

    @media screen and (max-width: 767px) and (orientation: landscape) {
        padding-top: calc(100vh - 160px);
    }

    @media screen and (min-width: 768px) {
        padding-top: calc(1090 / 2000 * 100%);
    }
}

.panorama {
    background: #0d3346;
    z-index: 0;
}
.panorama__hover-svg-path {
    transition: stroke-opacity 120ms ease;
}


/* Tooltip */
.panorama__tooltip {
    font-family: var(--font-default);
    font-size: .8rem;

    @media screen and (min-width: 768px) {
        font-size: 1rem;
    }
}
.panorama__tooltip .leaflet-popup-content-wrapper {
    border-radius: 0;
    padding: 0;
}
.panorama__tooltip .leaflet-popup-content {
    margin: 0;
}
.panorama__tooltip .leaflet-popup-close-button.leaflet-popup-close-button {
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    height: auto;
    width: auto;
    text-indent: -999rem;
    padding: 0;
    top: .75rem;
    right: .75rem;
    color: var(--color-text-default);

    @media screen and (min-width: 768px) {
        top: 1rem;
        right: 1rem;
    }

    &:before {
        content: var(--icon-close);
        display: block;
        font-family: 'iconfont';
        speak: none;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0);
        font-size: 1rem;
        line-height: 1;
        text-indent: 0;

        @media screen and (min-width: 768px) {
            font-size: 1.5rem;
        }
    }
}

.panorama__tooltip .leaflet-popup-content {
    min-width: 300px;

    @media screen and (min-width: 768px) {
        min-width: 335px;
    }
}
.panorama__tooltip-head {
    padding: .5rem 1rem;
    min-height: 2.5rem;
    padding-right: 2.5rem;
    display: flex;

    @media screen and (min-width: 768px) {
        padding: .5rem 1.5rem;
        min-height: 3.5rem;
        padding-right: 3.5rem;
    }
}
.panorama__tooltip-body {
    border-top: 1px solid lightgrey;
    padding: 1rem 1.5rem;

    @media screen and (min-width: 768px) {
        padding: 1.5rem 2rem;
    }
}
.panorama__tooltip-title {
    font-size: 1rem;
    line-height: calc(25 / 20);
    font-family: var(--font-default-bold);
    letter-spacing: var(--font-letterspacing-default-bold);
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        font-size: 1.15rem;
    }
}
.panorama__tooltip-list {
    margin-top: .5rem;

    @media screen and (min-width: 768px) {
        margin-top: 1rem;
    }
}

.panorama__temperature-text {
    color: #ffffff;
    position: absolute;
    transform: translate(-50%, -50%);
}

.panorama__time-info {
    position: absolute;
    z-index: 1;
    top: .5rem;
    right: 3.5rem;
    color: #FFFFFF;

    @media screen and (max-width: 767px) {
        top: auto;
        bottom: .5rem;
    }
}