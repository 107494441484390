.status {
    display: inline-block;
    border-radius: 50%;
    width: calc(24rem/16);
    height: calc(24rem/16);
    border: calc(3rem/16) solid #ffffff;
    box-shadow: 0 0 0 calc(1rem/16) #E7E7E7;
    vertical-align: -.4em;
}
.status--small {
    border: none;
    vertical-align: 0;
    width: calc(12rem/16);
    height: calc(12rem/16);
}
.status--open, .status--success {
    background-color: var(--color-success);
}
.status--closed, .status--danger {
    background-color: #EE2E22;
}

.status--preparation, .status--info {
    background-color: #ee9531;
}