.decorative-offer-teaser {
    position:relative;
}
.decorative-offer-teaser .teaser__image-headline {
    text-shadow: 0 2px 10px 0 #000000;
    text-align:center;
    font-size:calc(50rem/16);
    line-height:1;
    @media screen and (max-width: 767px) {
        font-size: calc(22rem/16);
    }
}
.decorative-offer-teaser__overlay {
    background-color: rgba(255,255,255,0.9);
    position:absolute;
    opacity:0;
    width:100%;
    height:100%;
    top:0;
    transition: opacity 0.4s cubic-bezier(0.7, 0, 0.3, 1);
    z-index: 3;
    display:flex;
}
.decorative-offer-teaser:hover .decorative-offer-teaser__overlay {
    opacity:1;
}
.decorative-offer-teaser__description:before {
    content:'';
    display:block;
    width: calc(70rem/16);
    height:calc(1rem/16);
    background-color:var(--color-primary);
    position:absolute;
    top: calc(40rem/16);
}
.decorative-offer-teaser__description {
    font-size:calc(16rem/16);
    padding:calc(55rem/16);
    position:relative;
    margin-top:auto;
    text-align:left;
    font-weight:300;
    @media screen and (max-width: 767px) {
        display:none;
    }
}