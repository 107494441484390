.countdown__counter-value {
    font-size:calc(160rem/16);
    font-family:var(--font-default-bold);
    line-height:1;
    text-align:center;
    color:var(--color-lightgrey);
    @media screen and (max-width: 767px) {
        font-size:calc(35rem/16);
    }
}
.countdown__counter-label {
    font-size:calc(40rem/16);
    font-family:var(--font-default-bold);
    line-height:1;
    color:var(--color-primary);
    text-align:center;
    text-transform:uppercase;
    @media screen and (max-width: 767px) {
        font-size:calc(18rem/16);
    }
}